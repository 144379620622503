import { useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Link from '@mui/material/Link'
import Paper from '@mui/material/Paper'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import GetApp from '@mui/icons-material/GetApp'

import { useBillingApi } from '../../api/billing/billing-context'
import { useFailedOperationBanner } from '../common/hooks/hook-failed-operation'
import { endOfDayUTC, endOfMonthUTC, startOfDayUTC, startOfMonthUTC } from 'common/api/v1/helpers'
import { BillingSummary } from '../common/BillingSummary'
import { FlexFiller } from '../../theme/common-styles'
import type { BillingReport, Customer } from 'common-billing-server/types'
import { formatDate } from '../../format-date'
import { DateRangePicker } from '../common/DateRangePicker'

export const InstallationDetailsPage = () => {
    const billingApi = useBillingApi()
    const urlParams = useParams()

    const customerId: string | undefined = urlParams.customerId
    const [customer, setCustomer] = useState<Customer | undefined>()

    const installationId: string | undefined = urlParams.installationId
    const [billingReport, setBillingReport] = useState<BillingReport | undefined>()

    const [isLoading, setIsLoading] = useState(false)
    const { addFailedOperation, removeFailedOperation, errorBanner } = useFailedOperationBanner()

    const [searchParams, setSearchParams] = useSearchParams()
    const startDateUrlParam = searchParams.get('startDate')
    const endDateUrlParam = searchParams.get('endDate')

    const setDate = (key: 'startDate' | 'endDate', value: Date | null) => {
        if (!value || !value.getTime()) return
        const hasBillingReport = !!billingReport
        const currentValue = searchParams.get(key)
        const newValue = value.toISOString()
        const hasSelectedSameValue =
            currentValue && formatDate(new Date(currentValue)) === formatDate(new Date(newValue))
        if (hasBillingReport && hasSelectedSameValue) {
            return
        }
        searchParams.set(key, newValue)
        setSearchParams(searchParams, { replace: true })
    }

    useEffect(() => {
        async function fetchCustomer() {
            const operationId = 'fetch-installation-customer'
            if (!customerId) return
            removeFailedOperation(operationId)
            setCustomer(undefined)
            setIsLoading(true)
            try {
                const customers = await billingApi.listCustomers({ filter: { customerId } })
                const customer = customers.items[0]
                if (!customer) {
                    throw new Error(`No customer found with id '${customerId}'`)
                }
                setCustomer(customer)

                let endDate = endDateUrlParam ? new Date(endDateUrlParam) : undefined
                const isTooEarlyEndDate = endDate && endDate.getTime() < customer.billingStartsAt.getTime()
                if (!endDate || isTooEarlyEndDate) {
                    // Don't allow end date to be earlier than billing start time
                    endDate = new Date(
                        Math.max(customer.billingStartsAt.getTime(), endOfMonthUTC(new Date()).getTime())
                    )
                    setDate('endDate', endOfDayUTC(endDate))
                }

                let startDate = startDateUrlParam ? new Date(startDateUrlParam) : undefined
                const isTooEarlyStartDate = startDate && startDate.getTime() < customer.billingStartsAt.getTime()
                const isTooLateStartDate = startDate && startDate.getTime() > endDate.getTime()
                if (!startDate || isTooEarlyStartDate || isTooLateStartDate) {
                    // Start date must be between billing start time and end date
                    startDate = new Date(
                        Math.max(customer.billingStartsAt.getTime(), startOfMonthUTC(new Date()).getTime())
                    )
                    setDate('startDate', startOfDayUTC(startDate))
                }
            } catch (error) {
                addFailedOperation({
                    id: operationId,
                    message: 'Failed fetching customer',
                    error,
                    retryFn: () => void fetchCustomer(),
                })
            }
        }
        void fetchCustomer()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        async function fetchBillingReport() {
            const operationId = 'fetch-billing-report'
            if (!customer) return
            if (!installationId) return
            if (!startDateUrlParam) return
            if (!endDateUrlParam) return

            removeFailedOperation(operationId)
            setBillingReport(undefined)
            setIsLoading(true)
            try {
                const billingReport = await billingApi.getInstallationBillingReport(installationId, {
                    startDate: new Date(startDateUrlParam).toISOString(),
                    endDate: new Date(endDateUrlParam).toISOString(),
                })
                setBillingReport(billingReport)
            } catch (error) {
                addFailedOperation({
                    id: operationId,
                    message: 'Failed fetching billing report',
                    error,
                    retryFn: () => void fetchBillingReport(),
                })
            }
            setIsLoading(false)
        }

        void fetchBillingReport()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customer, installationId, startDateUrlParam, endDateUrlParam])

    function makeBillingReportPdfUrl(installationId: string, startDate: string, endDate: string) {
        const searchParams = new URLSearchParams()
        searchParams.set('startDate', startDate)
        searchParams.set('endDate', endDate)
        searchParams.set('format', 'pdf')
        return `/api/installation/${installationId}/billing/report?${searchParams.toString()}`
    }

    return (
        <>
            <Toolbar style={{ display: 'flex' }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="h5" component="div">
                        {billingReport?.periods[0]?.host
                            ? `Installation ${billingReport.periods[0].host}`
                            : 'Installation details'}
                    </Typography>

                    {customer && (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'start',
                                alignItems: 'center',
                            }}
                        >
                            <Typography variant="h6" component="div">
                                Customer:
                            </Typography>
                            <Typography variant="body1" component="div" style={{ marginLeft: 4 }}>
                                {customer.name}
                            </Typography>

                            <Typography variant="h6" component="div" style={{ marginLeft: 20 }}>
                                Jeeves customer number:
                            </Typography>
                            <Typography variant="body1" component="div" style={{ marginLeft: 4 }}>
                                {customer.jeevesCustomerNumber}
                            </Typography>

                            <Typography variant="h6" component="div" style={{ marginLeft: 20 }}>
                                Billing type:
                            </Typography>
                            <Typography variant="body1" component="div" style={{ marginLeft: 4 }}>
                                {customer.billingType}
                            </Typography>
                        </div>
                    )}
                </div>

                <FlexFiller />

                {installationId && startDateUrlParam && endDateUrlParam && (
                    <Link
                        underline="none"
                        href={makeBillingReportPdfUrl(installationId, startDateUrlParam, endDateUrlParam)}
                    >
                        <Button variant="outlined">
                            <span>Download</span>
                            <GetApp />
                        </Button>
                    </Link>
                )}
            </Toolbar>

            {startDateUrlParam && endDateUrlParam && (
                <Paper style={{ margin: '20px' }}>
                    <div style={{ display: 'flex', alignItems: 'center', padding: '20px' }}>
                        <DateRangePicker
                            minDate={customer?.billingStartsAt}
                            startDateString={startDateUrlParam}
                            endDateString={endDateUrlParam}
                            onChange={setDate}
                        />
                        {isLoading && <CircularProgress style={{ marginLeft: 12, padding: '8px' }} />}
                    </div>
                    {billingReport && <BillingSummary billing={billingReport} />}
                </Paper>
            )}

            {errorBanner}
        </>
    )
}

import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router'

import { BillingApiProvider } from './api/billing/billing-context'
import { AuthProvider } from './components/login/Auth'
import { routes } from './routes/routes'
import { LocalStorageProvider } from './local-storage'
import { ConfirmDialogProvider } from './components/common/ConfirmationDialog'
import { ThemeSwitcher } from './theme/ThemeSwitcher'

const App = () => (
    <LocalStorageProvider>
        <BillingApiProvider>
            <ThemeSwitcher>
                <ConfirmDialogProvider>
                    <AuthProvider>
                        <BrowserRouter>{routes}</BrowserRouter>
                    </AuthProvider>
                </ConfirmDialogProvider>
            </ThemeSwitcher>
        </BillingApiProvider>
    </LocalStorageProvider>
)

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(
    <StrictMode>
        <App />
    </StrictMode>
)

import { SyntheticEvent, useEffect, useState } from 'react'
import Grid from '@mui/material/Grid2'
import Paper from '@mui/material/Paper'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

import { useBillingApi } from '../../api/billing/billing-context'
import { useFailedOperationBanner } from '../common/hooks/hook-failed-operation'
import { ButtonWithLoadingIndicator } from '../common/ButtonWithLoadingIndicator'
import { AutoComplete } from '../common/AutoComplete'
import { LogLevel } from 'common/api/v1/types'
import { BuildInfo } from 'common-billing-server/types'
import { listResult } from '../../list-result'
import Button from '@mui/material/Button'

enum SettingsTab {
    settings = 'settings',
    keys = 'keys',
}

export const EditGlobalSettingsPage = () => {
    const billingApi = useBillingApi()
    const [logLevel, setLogLevel] = useState<LogLevel | null>(null)
    const [isLoading, setIsLoading] = useState(false)
    const formContainsInvalidInput = logLevel === null

    const [settingsTab, setSettingsTab] = useState(SettingsTab.settings)
    const [buildInfo, setBuildInfo] = useState<BuildInfo | undefined>(undefined)
    const { addFailedOperation, removeFailedOperation, errorBanner } = useFailedOperationBanner()

    useEffect(() => {
        const operationId = 'fetch-global-settings'

        async function fetchGlobalSettings() {
            removeFailedOperation(operationId)
            setIsLoading(true)
            try {
                const globalSettings = await billingApi.getGlobalsettings()
                const { logLevel } = globalSettings
                setLogLevel(logLevel)
            } catch (error) {
                addFailedOperation({
                    id: operationId,
                    message: 'Failed fetching global settings',
                    error,
                })
            }
            setIsLoading(false)
        }
        void fetchGlobalSettings()
    }, [billingApi])

    useEffect(() => {
        const operationId = 'fetch-build-info'
        void billingApi
            .getBuildInfo()
            .then((info) => {
                setBuildInfo(info)
            })
            .catch((error) => {
                addFailedOperation({
                    id: operationId,
                    message: 'Failed fetching build info',
                    error,
                })
            })
    }, [billingApi])

    const saveGlobalSettings = async (event: SyntheticEvent) => {
        event.preventDefault()

        const operationId = 'update-global-settings'
        removeFailedOperation(operationId)
        if (formContainsInvalidInput) {
            return
        }
        try {
            setIsLoading(true)

            await billingApi.setGlobalsettings({
                logLevel: logLevel,
            })
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            addFailedOperation({
                id: operationId,
                message: `Failed updating global settings`,
                error,
            })
        }
    }

    const logLevelValue = logLevel ? { id: logLevel.toString(), displayName: LogLevel[logLevel] } : null
    const options = Object.keys(LogLevel)
        .filter((l) => parseInt(l) === parseInt(l))
        .map((id: any) => ({ id: id.toString(), displayName: LogLevel[id] }))

    return (
        <>
            <Toolbar>
                <Typography variant="h6" component="div">
                    Edit global settings
                </Typography>
            </Toolbar>

            <Paper style={{ margin: '20px' }}>
                <Tabs value={settingsTab} variant="fullWidth" onChange={(_event, newValue) => setSettingsTab(newValue)}>
                    <Tab label="settings" value={SettingsTab.settings} />
                    <Tab label="keys" value={SettingsTab.keys} />
                </Tabs>
                {settingsTab === SettingsTab.settings && (
                    <form onSubmit={(e) => void saveGlobalSettings(e)}>
                        <Grid
                            container
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={2}
                            style={{ padding: '16px' }}
                        >
                            <Grid size={{ xs: 4 }}>
                                <AutoComplete<{ id: string; displayName: string }>
                                    dataTestId={'log-level'}
                                    placeholder={`Log level`}
                                    value={logLevelValue}
                                    isRequired
                                    onValueSelected={(ll) => {
                                        if (ll?.id && ll.id in LogLevel) {
                                            setLogLevel(parseInt(ll.id) as LogLevel)
                                        }
                                    }}
                                    formatSelectedValue={(ll) => ll.displayName}
                                    isClearable
                                    api={(_query) => Promise.resolve(listResult(options))}
                                />
                            </Grid>

                            <Grid size={{ xs: 12 }}>
                                <ButtonWithLoadingIndicator
                                    title={'Update'}
                                    type="submit"
                                    isLoading={isLoading}
                                    disabled={formContainsInvalidInput || isLoading}
                                    style={{ width: '140px', margin: '12px 0px 20px 0px' }}
                                />
                            </Grid>
                            {errorBanner}
                        </Grid>
                    </form>
                )}
                {settingsTab === SettingsTab.keys && (
                    <Grid
                        container
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={2}
                        style={{ padding: '16px' }}
                    >
                        <Grid size={{ xs: 6 }}>
                            <Typography variant="h6" component="div">
                                Entitlements public key hash:
                            </Typography>
                            <Typography variant="body2" component="div">
                                {buildInfo?.entitlementsPublicKeyHash ?? ''}
                            </Typography>
                            <Button
                                onClick={() => {
                                    navigator.clipboard.writeText(buildInfo?.entitlementsPublicKey || '')
                                }}
                            >
                                Copy full key
                            </Button>
                        </Grid>
                    </Grid>
                )}
            </Paper>
        </>
    )
}

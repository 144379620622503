import { NavLink, NavLinkProps } from 'react-router'
import { ReactNode } from 'react'
import { useAuth } from '../login/Auth'
import { Role } from 'common-billing-server/types'
import { isRoleAuthorized } from 'common-billing-server/role'

interface Props {
    minimumAllowedRole: Role
}

/// Renders the received children only if the role is authorized, else renders null.
export const RoleBasedComponent = ({ minimumAllowedRole, children }: Props & { children: ReactNode }) => {
    const { user } = useAuth()
    if (!user || !isRoleAuthorized({ role: user.role, minimumAllowedRole })) {
        return null
    }
    return <>{children}</>
}

/// Wraps the received children in a NavLink only if the role is authorized, else simply renders the children.
export const RoleBasedLink = ({ minimumAllowedRole, children, ...props }: Props & NavLinkProps) => {
    const { user } = useAuth()
    if (!user || !isRoleAuthorized({ role: user.role, minimumAllowedRole })) {
        return <>{children}</>
    }
    return <NavLink {...props}>{children}</NavLink>
}

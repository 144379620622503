import { LoginPage } from '../components/login/LoginPage'
import { AppLayout } from '../components/AppLayout'
import { RequireAuth, RequireRole } from '../components/login/Auth'
import { Navigate, Route, Routes } from 'react-router'
import { INITIAL_PATH, Path } from './path'
import { LogoutPage } from '../components/login/LogoutPage'
// import { StartPage } from '../components/start/StartPage'
import { ListCustomersPage } from '../components/customer/ListCustomersPage'
import { ListUsersPage } from '../components/user/ListUsersPage'
import { CreateEditUserPage } from '../components/user/CreateEditUserPage'
import { CreateEditCustomerPage } from '../components/customer/CreateEditCustomerPage'
import { ListLicensesPage } from '../components/license/ListLicensesPage'
import { CreateEditLicensePage } from '../components/license/CreateEditLicensePage'
import { ListInstallationsPage } from '../components/installation/ListInstallationsPage'
import { InstallationDetailsPage } from '../components/installation/InstallationDetailsPage'
import { BillingPage } from '../components/billing/BillingPage'
import { BillingDetailsPage } from '../components/billing/BillingDetailsPage'
import { Role } from 'common-billing-server/types'
import { OauthLoginSuccessPage } from '../components/login/OauthLoginSuccessPage'
import { OauthLoginErrorPage } from '../components/login/OauthLoginErrorPage'
import { EditGlobalSettingsPage } from '../components/settings/SettingsPage'

export const routes = (
    <Routes>
        <Route path={Path.login} element={<LoginPage />} />
        <Route path={Path.oauthLoginSuccess} element={<OauthLoginSuccessPage />} />
        <Route path={Path.oauthLoginError} element={<OauthLoginErrorPage />} />

        <Route
            path={Path.root}
            element={
                <RequireAuth>
                    <AppLayout />
                </RequireAuth>
            }
        >
            {/* For dev purposes only: <Route path={Path.start} element={<StartPage />} /> */}

            <Route path={Path.root} element={<Navigate to={INITIAL_PATH} replace />} />
            <Route path={Path.installations} element={<ListInstallationsPage />} />
            <Route path={Path.installationDetails} element={<InstallationDetailsPage />} />
            <Route path={Path.billing} element={<BillingPage />} />
            <Route path={Path.billingDetails} element={<BillingDetailsPage />} />

            <Route
                path={Path.users}
                element={
                    <RequireRole minimumAllowedRole={Role.licenseAdmin}>
                        <ListUsersPage />
                    </RequireRole>
                }
            />
            <Route
                path={Path.createUser}
                element={
                    <RequireRole minimumAllowedRole={Role.licenseAdmin}>
                        <CreateEditUserPage />
                    </RequireRole>
                }
            />
            <Route
                path={Path.editUser}
                element={
                    <RequireRole minimumAllowedRole={Role.licenseAdmin}>
                        <CreateEditUserPage />
                    </RequireRole>
                }
            />

            <Route
                path={Path.customers}
                element={
                    <RequireRole minimumAllowedRole={Role.licenseAdmin}>
                        <ListCustomersPage />
                    </RequireRole>
                }
            />
            <Route
                path={Path.createCustomer}
                element={
                    <RequireRole minimumAllowedRole={Role.admin}>
                        <CreateEditCustomerPage />
                    </RequireRole>
                }
            />
            <Route
                path={Path.editCustomer}
                element={
                    <RequireRole minimumAllowedRole={Role.admin}>
                        <CreateEditCustomerPage />
                    </RequireRole>
                }
            />

            <Route
                path={Path.licenses}
                element={
                    <RequireRole minimumAllowedRole={Role.licenseAdmin}>
                        <ListLicensesPage />
                    </RequireRole>
                }
            />
            <Route
                path={Path.createLicense}
                element={
                    <RequireRole minimumAllowedRole={Role.licenseAdmin}>
                        <CreateEditLicensePage />
                    </RequireRole>
                }
            />
            <Route
                path={Path.editLicense}
                element={
                    <RequireRole minimumAllowedRole={Role.licenseAdmin}>
                        <CreateEditLicensePage />
                    </RequireRole>
                }
            />
            <Route
                path={Path.settings}
                element={
                    <RequireRole minimumAllowedRole={Role.super}>
                        <EditGlobalSettingsPage />
                    </RequireRole>
                }
            />
        </Route>

        <Route path={Path.logout} element={<LogoutPage />} />

        {/* Match all non-existing routes to the initial path */}
        <Route path="*" element={<Navigate to={INITIAL_PATH} replace />} />
    </Routes>
)

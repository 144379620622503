import { DelayMode } from './rist'
import type { Product } from './api/v1/types'

export const DEFAULT_BUFFER_DURATION = 6000
export const DEFAULT_DELAY_MODE = parseInt(process.env.DELAY_MODE || '2') as DelayMode.basedOnOriginTime
export const USE_ORIGIN_TIMING = true
export const SRT_STATS_INTERVAL = 10
export const defaultRegionId = '69920e49-31c4-476a-b6bb-023305262007'
export const defaultRegionName = process.env.EDGE_DEFAULT_REGION_NAME || 'default'
export const EDGE_OPERATOR_API_TOKEN_NAME = 'edge-operator-api-token'
export const EDGE_SUPER_API_TOKEN_SECRET_NAME = 'edge-operator-api-token'
export const TS_ANALYSIS_INTERVAL_SECONDS = 10

export const HEADER_EDGE_DATA_CONTAINER_ID = 'edge-data-container-id'

// EDGE-4072: Sticky ingress during UI proxy:
// ensure that all communicating parties are routed
// through the same edge-api instance by setting this cookie.
export const COOKIE_APPLIANCE_PROXY = 'applianceproxy'

export const PRODUCTS = {
    nimbraEdge: {
        id: '705be4c2-0e82-4356-9ce2-6484c116796f',
        name: 'Nimbra Edge',
    },
    connectIt: {
        id: 'd0b70d6d-8db6-4524-8d4f-7ee716af241a',
        name: 'Connect iT',
    },
} as const
export function findProductById(productId: Product['id']): Product | undefined {
    return Object.values(PRODUCTS).find((p) => p.id === productId)
}

export const TOPIC_AD_AUDIT_LOG = 'ad_audit_log'
export const TOPIC_AD_PLAYLISTS = 'ad_playlists'
export const TOPIC_AD_EVENTS = 'ad_events'
export const TOPIC_AD_VAST_REQUESTS = 'ad_vast_requests'
export const TOPIC_ADS_TO_TRANSCODE = 'ads_to_transcode'

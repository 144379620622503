import { useState } from 'react'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Collapse from '@mui/material/Collapse'
import Grid from '@mui/material/Grid2'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import type { Theme } from '@mui/material/styles'
import Info from '@mui/icons-material/Info'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import { styled } from '@mui/material/styles'

import { dateToYearMonthDayMaxNowString, dateToYearMonthDayString } from 'common/api/v1/helpers'
import { BillingDetail, EgressBillingDetail } from 'common/api/v1/types'
import type { BillingPeriod, BillingReport } from 'common-billing-server/types'

const MaxConcurrencyDate = styled('span')(({ theme }) => ({
    color: theme.palette.grey[600],
    marginLeft: '5px',
    fontWeight: 'normal',
}))

const classes = {
    container: {
        flex: 1,
    },
    noCreditsConsumed: {
        color: (theme: Theme) => theme.palette.grey[600],
    },
    idData: {
        color: (theme: Theme) => theme.palette.grey[600],
        maxWidth: '200px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
}

const rowClasses = {
    root: {
        cursor: 'pointer',
        '& > *': {
            borderBottom: 'unset',
        },
    },
}

interface Props {
    billing: BillingReport
}
export const BillingSummary = ({ billing }: Props) => {
    if (!billing) {
        return null
    }
    const periods = billing.periods.slice()
    return (
        <Paper sx={classes.container}>
            <TableContainer component={Paper}>
                <Table id="billingperiods" aria-label="collapsible table" size="medium">
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            <TableCell>Host</TableCell>
                            <TableCell>From</TableCell>
                            <TableCell>To</TableCell>
                            <TableCell align="right">Contracted stream credits</TableCell>
                            <TableCell align="right">Ingress credits</TableCell>
                            <TableCell align="right">Egress credits</TableCell>
                            <TableCell align="right">Total credits</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {periods.map((p) => (
                            <BillingPeriod key={p.startDate.toISOString()} period={p} />
                        ))}
                        <TableRow>
                            <TableCell align="right" colSpan={2}>
                                <Typography variant={'h4'}></Typography>
                            </TableCell>
                            <TableCell align="right" colSpan={3}>
                                <Typography variant={'h4'}>Total</Typography>
                            </TableCell>
                            <TableCell align="right">
                                <Typography variant={'h4'}>{billing.summary.ingressCredits}</Typography>
                            </TableCell>
                            <TableCell align="right">
                                <Typography variant={'h4'}>{billing.summary.egressCredits}</Typography>
                            </TableCell>
                            <TableCell align="right">
                                <Typography variant={'h4'}>{billing.summary.totalCredits}</Typography>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    )
}

const BillingPeriod = ({ period }: { period: BillingPeriod }) => {
    const [open, setOpen] = useState(false)

    const {
        host,
        startDate,
        endDate,
        license,
        ingressCredits,
        egressCredits,
        totalCredits,
        maxConcurrencyDate,
        details,
    } = period
    const creditsConsumed = ingressCredits > 0 || egressCredits > 0
    return (
        <>
            <TableRow hover sx={rowClasses.root} onClick={() => setOpen(!open)}>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" data-test-id={'expand-btn'}>
                        {open ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
                    </IconButton>
                </TableCell>
                <TableCell scope="row">{host}</TableCell>
                <TableCell scope="row" data-testid="billingperiod-from">
                    {dateToYearMonthDayString(startDate)}
                </TableCell>
                <TableCell scope="row" data-testid="billingperiod-to">
                    {dateToYearMonthDayMaxNowString(endDate)}
                </TableCell>
                <TableCell align="right">{license.creditType}</TableCell>
                <TableCell align="right">{ingressCredits}</TableCell>
                <TableCell align="right">{egressCredits}</TableCell>
                <TableCell align="right">{totalCredits}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell sx={{ paddingY: 0 }} colSpan={8}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        {!creditsConsumed ? (
                            <Box margin={1} marginBottom={4} data-test-id={'detailed-report-data'}>
                                <Typography variant="h6" component="div">
                                    Detailed billing report for the period is not available
                                </Typography>
                            </Box>
                        ) : (
                            <>
                                <Box margin={1} marginBottom={4} data-test-id={'detailed-report-data'}>
                                    <Typography variant="h6" gutterBottom component="div">
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                flexWrap: 'wrap',
                                                columnGap: '8px',
                                            }}
                                        >
                                            Credit Usage{' '}
                                            <MaxConcurrencyDate data-testid="billingperiod-maxdate">
                                                {formatDateTime(maxConcurrencyDate)}
                                            </MaxConcurrencyDate>
                                            <Tooltip
                                                title={
                                                    'Point in time when the max number of concurrent streams were sampled during the selected period'
                                                }
                                                placement="top"
                                            >
                                                <Info />
                                            </Tooltip>
                                        </div>
                                    </Typography>
                                    {!creditsConsumed ? (
                                        <Typography sx={classes.noCreditsConsumed}>
                                            No credits consumed during this period
                                        </Typography>
                                    ) : (
                                        <StreamCreditUsageTable data={details} />
                                    )}
                                </Box>
                            </>
                        )}
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    )
}

const StreamCreditUsageTable = (props: { data: BillingDetail[] }) => {
    const { data } = props
    const rows = data.slice() // sort((a, b) => a.streamId.localeCompare(b.streamId))
    return (
        <Table size="small" aria-label="purchases">
            <TableHead>
                <TableRow>
                    <TableCell>Type</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>ID</TableCell>
                    <TableCell align="right">Credits</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {rows.map((row, i) => (
                    <StreamCreditUsageRow row={row} key={i} />
                ))}
            </TableBody>
        </Table>
    )
}

function isEgressBillingDetail(d: BillingDetail): d is EgressBillingDetail {
    return 'outputId' in d
}

const StreamCreditUsageRow = (props: { row: BillingDetail }) => {
    const { row } = props
    const isEgress = isEgressBillingDetail(row)
    const id = isEgress ? row.outputId : row.inputId
    const redundant = (isEgress && row.outputRedundancy) || (!isEgress && row.inputRedundancy)
    const merge = !isEgress && row.inputMerge
    const protection = (isEgress && row.outputPortCount > 1) || (!isEgress && row.inputPortCount > 1 && !merge)
    return (
        <TableRow hover>
            <TableCell>
                <Grid container spacing={1}>
                    <Grid>
                        <Chip label={isEgress ? 'egress' : 'ingress'} size="small" />
                    </Grid>
                    {redundant && (
                        <Grid>
                            <Chip label={'redundant'} size="small" />
                        </Grid>
                    )}
                    {merge && (
                        <Grid>
                            <Chip label={'merge'} size="small" />
                        </Grid>
                    )}
                    {protection && (
                        <Grid>
                            <Chip label={'protected'} size="small" />
                        </Grid>
                    )}
                </Grid>
            </TableCell>
            <TableCell scope="row">{isEgress ? row.outputName : row.inputName}</TableCell>
            <TableCell title={id} sx={classes.idData}>
                {id}
            </TableCell>
            <TableCell align="right">{row.credits} </TableCell>
        </TableRow>
    )
}

function formatDateTime(d: Date): string {
    return d.toISOString().substring(0, 16).replace('T', ' ')
}

import { Pid as TPid, TransportStream } from '../../tr101Types'
import { ApplianceConfiguration as TApplianceConfiguration } from '../../messages'
import {
    ChannelState,
    DelayMode,
    RistInputMultipathState,
    RistOutputMultipathState,
    UdpInputStatusCode,
    UdpOutputStatusCode,
} from '../../rist'
import { RtmpOutputState } from '../../rtmp'
import { SrtConnectionStatus } from '../../srt'
import { K8sService } from '../../k8s/services'
import { KubernetesRoles } from '../../k8s/labels'
import {
    type ConnectitProductId,
    type NimbraEdgeProductId,
    PortDirection,
    type Product,
    TcpdumpCommand,
    TranscodeAcceleratorInfo,
} from '../../types'
import { IncomingMessage, ServerResponse } from 'http'
import { ApplianceCapabilities } from '../model/applianceRegistration'

export { RistInputMultipathState, UdpOutputStatusCode }
export { DelayMode } from '../../rist'
export type { Product, ConnectitProductId, NimbraEdgeProductId }
export type Pid = TPid
export type ApplianceConfiguration = TApplianceConfiguration
export type * from './internal/region'

export interface SortOrder<T extends string = string> {
    field: T
    descending: boolean
}

export enum LogLevel {
    fatal = 0,
    error = 1,
    warn = 2,
    info = 3,
    debug = 4,
    trace = 5,
}

export enum EdgeProduct {
    edge = 'edge',
    loci = 'loci',
}

// Key is what we call it in NM to be consistent with LogLevel, and the value is what the ristserver accepts
export enum RistserverLogLevel {
    fatal = 'critical',
    error = 'error',
    warn = 'warning',
    info = 'info',
    debug = 'debug',
    trace = 'trace',
}

export enum RistProfile {
    simple = 'simple',
    main = 'main',
}

export enum SrtMode {
    listener = 'listener',
    caller = 'caller',
    rendezvous = 'rendezvous',
}

export enum ZixiMode {
    pull = 'pull',
    push = 'push',
}

export enum SrtKeylen {
    aes128 = '16',
    aes192 = '24',
    aes256 = '32',
    none = '-1',
}

export enum SrtRateLimiting {
    notEnforced = 'Not enforced',
    absolute = 'Absolute',
    relativeToInput = 'Relative to input',
}

export interface ListResultError {
    message: string
}
export interface ListResult<T> {
    items: T[]
    total: number
    sortOrder?: SortOrder
    skip?: number
    limit?: number
    errors?: ListResultError[]
}

export enum AuditLogOperationResult {
    error = 'error',
    success = 'success',
}

/**
 * @OAS_DESCRIPTION Parameters on which a list of audit-log entries can be filtered
 */
export interface AuditFilter {
    method?: string
    pathname?: string
    operation?: string
    result?: AuditLogOperationResult
    username?: string
    entity?: string
    entityId?: string
    entityName?: string
    fromDate?: Date
    toDate?: Date
}

export interface AdAuditFilter {
    filter?: string
    fromDate?: Date
    toDate?: Date
    entityType?: string
    method?: string
    result?: string
    message?: string
    status?: string
    eventId?: number
    outputId?: string
    streamId?: number
    channelId?: number
    component?: string
    pod?: string
    entity?: string
    entityId?: string
    entityName?: string
    source?: string
    target?: string
    traceId?: string
}

export enum AuditOperation {
    create = 'create',
    delete = 'delete',
    update = 'update',
    login = 'login',
    logout = 'logout',

    switchInput = 'switchInput',
    tcpdumpInput = 'tcpdumpInput',

    updateInputRecipients = 'updateInputRecipients',
    sendToOutputs = 'sendToOutputs',

    updateApplianceVersion = 'updateApplianceVersion',
    registerAppliance = 'registerAppliance',
    reregisterAppliance = 'reregisterAppliance',
    updateApplianceBasicInfo = 'updateApplianceBasicInfo',
    updateApplianceIpConfig = 'updateApplianceIpConfig',
    restartAppliance = 'restartAppliance',

    startCloudApplianceInstance = 'startCloudApplianceInstance',
    stopCloudApplianceInstance = 'stopCloudApplianceInstance',

    toggle = 'toggle',
}

export interface AuditRefData {
    inputName?: string
    action?: 'enable' | 'disable' | undefined
    hasDataSoftwareVersion?: boolean
    cloudApplianceInstance?: {
        id: string
        name?: string
        region?: string
    }
}

/**
 * @OAS_DESCRIPTION Contains information about actions performed in the system, e.g. who did what and when, etc.
 */
export interface AuditLog {
    id: string
    operation: AuditOperation
    entity: string
    entityId?: string
    entityName: string
    // pathname: string
    // query: string
    userId: string
    username: string
    clientIp?: string // Only visible for super user
    // request?: string
    // response?: string
    error?: string
    executionTimeMs?: number
    createdAt: Date
    updatedAt?: Date
    authtoken?: string
    impersonatorId?: string
    impersonatorUsername?: string

    // Allows returning arbitraty reference data used for generating
    // a textual description of the operation in the audit log
    refData: AuditRefData
}

export type CloudApplianceAuditLogEntry = Pick<AuditLog, 'operation' | 'entity' | 'entityId' | 'entityName' | 'refData'>

export interface Entity {
    id: string
}

export enum AlarmCause {
    INTERFACE_REMOVED_BUT_IN_USE = 'Interface removed but still in use',
    CORE_APPLIANCE_PUBLIC_IP_MISSING = 'Appliance needs public ip mapping', // Core appliance or external thumb
    CORE_INPUT_INVALID_ADDRESS = 'Core input configured on an address that is not available',
    CORE_OUTPUT_INVALID_ADDRESS = 'Core output configured on an address that is not available',
    FIREWALL_NFT_ERROR = 'Failed to apply firewall settings using nftables',
    MPTS_SUPPORT_ERROR = 'Failed to run mpts demux binary',
    MPTS_STARTUP_ERROR = 'Unknown error when starting the mpts demux binary',
    MPTS_UNEXPECTED_EXIT = 'The mpts demux binary unexpectedly exited',
    MPTS_CALL_TIMEOUT = 'The mpts demux binary did not give a timely response to an api call',
    BACKUP_JOB_FAILED = 'Backup job failed',
    RTMP_RECEIVER_EXITED = 'RTMP receiver exited unexpectedly',
    RTMP_SENDER_EXITED = 'RTMP sender exited unexpectedly',
    TRANSCODE_EXITED = 'Transcode process exited unexpectedly',
    TRANSCODE_MISSING_PID = 'Audio reshuffling failed due to missing PID',
    TRANSCODE_MISALIGNED_STREAMS = 'Stream timestamps are misaligned',
    INPUT_MAX_BITRATE_EXCEEDED = 'Ingested stream traffic exceeds the maximum bitrate',
    INPUT_UNHEALTHY = 'Input is unhealthy',
    OUTPUT_UNHEALTHY = 'Output is unhealthy',
    APPLIANCE_OFFLINE = 'Appliance is offline',
    APPLIANCE_HIGH_CPU_USAGE = 'High CPU usage detected',
    TRANSCODE_ACCELERATOR_HIGH_USAGE = 'High transcode accelerator usage detected',

    // Nimbra 400 alarms
    VA_LOSS_OF_SIGNAL = 'Loss of signal',
    VA_CALL_ESTABLISHMENT_ERROR = 'Call establishment error',
    VA_RESOURCE_CAPACITY = 'Resource at or nearing capacity',

    // Edge-data alarms
    RISTSERVER_ADD_CHANNEL = 'Failed to add channel',
    RISTSERVER_ADD_INPUT_STREAM_RIST = 'Failed to add input stream rist',
    RISTSERVER_ADD_INPUT_STREAM_RTP = 'Failed to add input stream rtp',
    RISTSERVER_ADD_INPUT_STREAM_UDP = 'Failed to add input stream udp',
    RISTSERVER_ADD_INPUT_STREAM_ADDRESS_ALREADY_IN_USE = 'Address already in use',
    RISTSERVER_ADD_OUTPUT_STREAM_RIST = 'Failed to add output stream rist',
    RISTSERVER_ADD_OUTPUT_STREAM_RTP = 'Failed to add output stream rtp',
    RISTSERVER_ADD_OUTPUT_STREAM_UDP = 'Failed to add output stream udp',
    RISTSERVER_ADD_TUNNEL_CLIENT = 'Failed to add tunnel client',
    RISTSERVER_ADD_TUNNEL_SERVER = 'Failed to add tunnel server',
    RISTSERVER_GET_DELTA_STATISTICS = 'Failed to get delta statistics',
    RISTSERVER_REMOVE_CHANNEL = 'Failed to remove channel',
    RISTSERVER_REMOVE_INPUT_STREAM = 'Failed to remove input stream',
    RISTSERVER_REMOVE_OUTPUT_STREAM = 'Failed to remove output stream',
    RISTSERVER_REMOVE_TUNNEL = 'Failed to remove tunnel',
    RISTSERVER_SET_INPUT_MULTIPATH_PARAMS = 'Failed to set input multipath params',
    RISTSERVER_SET_LOGGING_OPTIONS = 'Failed to set logging options',
    RISTSERVER_SET_OUTPUT_STREAM_MAXBITRATE = 'Failed to set output stream max bitrate',
    RISTSERVER_GET_STATISTICS = 'Failed to get statistics',
    RISTSERVER_GET_STATUS = 'Failed to get status',
    RISTSERVER_SET_OUTPUT_STREAM_NEW_AD_PLAYLIST = 'Failed to set output stream new ad playlist',
    RISTSERVER_SET_OUTPUT_STREAM_NOTIFY_SCTE = 'Failed to set output stream notify scte',
    RISTSERVER_SET_OUTPUT_STREAM_AD_INSERTION = 'Failed to set output stream ad insertion',
    RISTSERVER_SET_OUTPUT_STREAM_AD_SCTE_35_SOURCE = 'Failed to set output stream ad insertion SCTE source filter',
    RISTSERVER_SET_AD_FILE_AVAILABILITY = 'Failed to set ad file availability',

    HANDOVER_PROCESS_ADD_INPUT = 'Failed to add input',
    HANDOVER_PROCESS_ADD_OUTPUT = 'Failed to add output',

    MATROX_CONFIG_MISMATCH = `Configuration error - unable to set up appliance`,
    MATROX_FETCH_STATE = `Failed to fetch state`,
    MATROX_MISCONFIGURED_GENLOCK = `Misconfigured genlock`,
    MATROX_UPDATE_GENLOCK = `Failed to update genlock`,
    MATROX_MISCONFIGURED_IO_CONFIG = `Misconfigured I/O config`,
    MATROX_UPDATE_IO_CONFIG = `Failed to update I/O config`,
    MATROX_MISCONFIGURED_SDI_PORTS = `Misconfigured SDI ports`,
    MATROX_UPDATE_SDI_PORT = `Failed to update SDI port`,
    MATROX_MISCONFIGURED_PIPELINE = `Misconfigured pipeline`,
    MATROX_ADD_MISSING_PIPELINE = `Failed to add missing pipeline`,
    MATROX_DELETE_OBSOLETE_MPEG2TS_INPUT = `Failed to delete obsolete MPEG2TS input`,
    MATROX_DELETE_OBSOLETE_PROCESSOR = `Failed to delete obsolete processor`,
    MATROX_DELETE_OBSOLETE_ENCODER = `Failed to delete obsolete encoder`,
    MATROX_DELETE_OBSOLETE_MPEG2TS_OUTPUT = `Failed to delete obsolete MPEG2TS output`,
    COMPRIMATO_UNHEALTHY_PIPELINE = 'Unhealthy Comprimato pipeline',
    STORAGE_THRESHOLD_ALERT = 'Storage threshold alert',
    UNHEALTHY_INSTANCES_ALERT = 'Unhealthy instances alert',
    KAFKA_UNAVAILABLE = 'Redpanda unavailable',
    K8S_SYSTEM_ALERT = 'Kubernetes system alert',

    DOWNLOAD_AD_FILE = 'Failed to download ad file',
    PARSE_VAST_RESPONSE = 'Failed to parse VAST response',
}

/**
 * @OAS_DESCRIPTION Alarm severity levels
 *
 * * critical - Critical alarms are service-affecting meaning several streams are not being delivered correctly.
 *   Critical alarms require immediate attention even if they occur outside working hours. For example if a video-node
 *   is overloaded.
 *
 * * major - Some functionallity is missing or degraded. This could be the control-plane is not working correctly or a
 *   stream cannot be delivered correctly. For example metrics being unavailable.
 *
 * * minor - A fault has occurred but it is currently not service-affecting. For example a database is operating without
 *   adequate redundancy.
 *
 * * warning - Warnings does not affect the service. They indicate potential future problems if not addressed. For
 *   example high disk usage.
 */
export enum AlarmSeverityLevels {
    critical = 'critical',
    major = 'major',
    minor = 'minor',
    warning = 'warning',
}

export enum VaAlarmOwnSeverityLevels {
    cleared = 'cleared',
}

export enum AlarmType {
    'va' = 'va',
    'edge' = 'edge',
    'backend' = 'backend',
    'backupMonitor' = 'backup-monitor',
    'prometheus' = 'prometheus',
}

interface AlarmBase {
    alarmCause: AlarmCause
    alarmSeverity: AlarmSeverityLevels | VaAlarmOwnSeverityLevels
    applianceId?: string
    applianceName?: string
    channelId?: number
    groupId?: string
    inputId?: string
    object: string
    objectName: string
    objectPurpose?: string
    outputId?: string
    physicalPortId?: string
    streamId?: number
    text?: string
    time: string
    type: AlarmType
    clearExplicitly?: boolean
    region?: string
}

/**
 * @OAS_DESCRIPTION An alarm report is used to report alarm events, raising or clearing, to the system.
 */
export interface AlarmReport extends AlarmBase {
    id: string
    active: boolean
}

/**
 * AlarmReports are sent from appliances through the public API/OAS. Backend then translates these reports
 * to InternalAlarmReports before storing them to the database.
 */
export interface InternalAlarmReport extends AlarmBase {
    id: string
    active: boolean
    inputName?: string
    outputName?: string
}

/**
 * @OAS_DESCRIPTION An alarm event that has been registered by the system.
 */
export interface Alarm extends AlarmBase {
    id: bigint
    alarmId: string
    inputName?: string
    outputName?: string
    raisedAt?: string
    clearedAt?: string
    repeatCount: number
}

export type AlarmWithImpact = Alarm & {
    affectedInput?: Input['id']
    affectedOutput?: Output['id']
    affectedAppliance?: Appliance['id']
}

export type AlarmUpdate = {
    status: 'CLEAR'
}

/**
 * @OAS_DESCRIPTION A historical alarm event that has been registered by the system.
 */
export type AlarmLog = Alarm

export interface BackupMonitorAlarm extends AlarmReport {
    type: AlarmType.backupMonitor
    alarmCause: AlarmCause.BACKUP_JOB_FAILED
}

export enum PrometheusAlerts {
    // These values MUST match the rule names in the prometheus.values file
    LowRedpandaDiskSpace = 'LowRedpandaDiskSpace',
    HighNodeDiskUsage = 'HighNodeDiskUsage',
    ReducedCloudNativePGAvailability = 'ReducedCloudNativePGAvailability',
    CloudNativePGReplicationLag = 'CloudNativePGReplicationLag',
    CloudNativePGReplicationFailure = 'CloudNativePGReplicationFailure',
    ReducedCloudNativePGReplication = 'ReducedCloudNativePGReplication',
    ReducedRedisAvailability = 'ReducedRedisAvailability',
    KafkaSendErrors = 'KafkaSendErrors',
    ReducedRedpandaAvailability = 'ReducedRedpandaAvailability',
    RedpandaConsumerGroupLag = 'RedpandaConsumerGroupLag',
    ReducedRedpandaToClickHouseAvailability = 'ReducedRedpandaToClickHouseAvailability',
    ReducedClickHouseAvailability = 'ReducedClickHouseAvailability',
    ClickHouseHighLatency = 'ClickHouseHighLatency',
    KubeClientCertificateExpiration = 'KubeClientCertificateExpiration',
}

/**
 * @OAS_DESCRIPTION Parameters on which a list of alarm-log entries can be filtered
 */
export interface AlarmLogFilter extends SearchableFilter {
    fromDate?: Date
    toDate?: Date
    severity?: AlarmSeverityLevels
}

export interface SupportedAudioCodec {
    name: AudioCodec
    bitratesKbps: number[]
    qualityOptions?: { name: string; value: string }[]
    bitDepth?: number[]
}

export enum MatroxEncoderAACQualityLevel {
    low = 'Level0',
    medium = 'Level1',
    high = 'Level2',
    unknown = 'LevelUnknown',
}

export interface AudioFeatures {
    layout: AudioLayout[]
    maxAudioStreams: number
    codecs: SupportedAudioCodec[]
}

export type VideoFlags = Nimbra400VideoFlags | VideonVideoFlags | MatroxVideoFlags

export interface VideoFeatures {
    codecs: SupportedVideoCodec[]
    latencyModes?: { name: string; value: string }[]
    scalingModes?: { name: string; value: string }[]
    /**
     * @OAS_NAME VideoFeatureFlags
     */
    flags?: { name: string; value: VideoFlags; disabledForCodecs: VideoCodec[] }[]
}

export interface IntegerRangeSpec {
    min: number
    max: number
}

export type ArrayKeys<T> = Exclude<
    {
        [k in keyof T]: Required<T>[k] extends Array<any> ? k : never
    }[keyof T],
    undefined
>

/**
 * Restrict the range of valid values for a property
 * by declaring a dependency on another property
 * along with a mapping from values of this property
 * to valid values.
 */
export interface Restriction<T> {
    determinedBy: keyof T
    mapping: {
        [value: string]: (string | number)[]
    }
}

export type Restrictions<T> = {
    [property in Exclude<keyof T, 'restrictions'>]?: Restriction<T>
}

export interface Option<Name extends string = string, Value extends string = string> {
    name: Name
    value: Value
}

export type VideoBitDepth = 8 | 10 | 12

export interface SupportedVideoCodec {
    name: VideoCodec | RawVideo
    profile?: { name: string; value: string }[]
    pixelFormat?: { name: string; value: string }[]
    colorSampling?: { name: string; value: string }[]
    bitrate: IntegerRangeSpec

    bitDepth?: VideoBitDepth[]
    resolution?: { name: string; value: string }[]
    scanRate?: VideoFrameRate[]

    scan?: { name: string; value: string }[]
    restrictions?: Restrictions<SupportedVideoCodec>
}

export type VideoFrameRate =
    | '23.976'
    | '24'
    | '25'
    | '29.97'
    | '30'
    | '47.952'
    | '48'
    | '50'
    | '59.94'
    | '60'
    | '100'
    | '119.88'
    | '120'

export interface EncoderFeatures {
    audio: AudioFeatures
    video: VideoFeatures
}
export interface DecoderFeatures {
    audio: AudioFeatures
    video: VideoFeatures
}

export interface FecLimits {
    maxSize: number
    colRange: IntegerRangeSpec
    rowRange: IntegerRangeSpec
}

interface SupportedInputMode {
    mode: PortMode
    prettyName?: string
    subModes?: string[]
    encoder?: EncoderFeatures
}

interface SupportedOutputMode {
    mode: PortMode
    prettyName?: string
    subModes?: string[]
    decoder?: DecoderFeatures
}
export interface ApplianceFeatures {
    coaxPorts?: number
    input?: {
        modes: SupportedInputMode[]
    }
    output?: {
        modes: SupportedOutputMode[]
    }
    fec?: FecLimits
    proxy?: ApplianceProxySettings
}

export enum ApplianceType {
    nimbraVAdocker = 'nimbraVAdocker',
    nimbra410 = 'nimbra410',
    nimbra412 = 'nimbra412',
    nimbra414 = 'nimbra414',
    nimbra414b = 'nimbra414b',
    nimbraVA225 = 'nimbraVA225',
    nimbraVA220 = 'nimbraVA220',
    edgeConnect = 'edgeConnect',
    core = 'core',
    thumb = 'thumb',
    videon = 'videon',
    matroxMonarchEdgeE4_8Bit = 'matroxMonarchEdgeE4_8Bit',
    matroxMonarchEdgeE4_10Bit = 'matroxMonarchEdgeE4_10Bit',
    matroxMonarchEdgeD4 = 'matroxMonarchEdgeD4',
    matroxMonarchEdgeS1 = 'matroxMonarchEdgeS1',
    comprimato = 'comprimato',
    mediakindRx1 = 'mediakindRx1',
    mediakindCe1 = 'mediakindCe1',
    riversilica = 'riversilica',
}

export type Latitude = number
export type Longitude = number
export type Coordinates = [Latitude, Longitude]
export type CoordinatesMapbox = [Longitude, Latitude]

export interface GeoLocation {
    location: string
    coordinates: Coordinates
}

export interface NdiInfo {
    sources: NdiSource[]
}

export interface NdiSource {
    name: string
}

export interface TcpdumpResponse {
    stdout: string
    stderr: string
    error?: { code: string | number | undefined | null; killed?: boolean | undefined; signal: string | undefined }
    startAt: Date
    stopAt: Date
}
export interface Pcap {
    id: string
    name: string
    result?: TcpdumpResponse
}

export interface InputPcapRequest {
    applianceId: string
}

export interface InputPcapResponse {
    applianceId: string
    tcpdumpCommand: TcpdumpCommand
    id: string // pcap id (scoped by appliance) available at /api/appliance/:applianceId/pcap/:id
}

export interface Overlay {
    id?: string
    name: string
    previewResolution: '720x576' | '1280x720' | '1920x1080' | '3840x2160'
    components: OverlayComponent[]
}

export type OverlayComponent = OverlayComponentImage | OverlayComponentText

export interface OverlayCoordinate {
    value: number
    baseline: 'start' | 'end'
}

export interface OverlayComponentImage {
    type: 'image'
    data: string
    x: OverlayCoordinate
    y: OverlayCoordinate
    maxHeight: number
    maxWidth: number
    opacity: number
}

// Background and border box
export interface OverlayBox {
    borderWidth: number
    opacity: number
    color: string
}

export interface OverlayComponentText {
    type: 'text'
    text: string
    x: OverlayCoordinate
    y: OverlayCoordinate
    fontSize: number
    color: string
    box?: OverlayBox
    preset?: 'timecode' | 'datetime' | 'codec' | 'encoder'
}

export type DeletedOverlay = Entity

export type RegionReference = Pick<Region, 'id' | 'name'>
export type ApplianceReference = Pick<BaseAppliance, 'id' | 'name' | 'type' | 'version'>
export interface BaseAppliance {
    id: string
    type: ApplianceType
    name: string
    hostname: string
    serial: string
    contact: string
    version: ApplianceVersion
    owner: string | Group
    physicalPorts: PhysicalPort[]
    alarms: Alarm[]
    features: ApplianceFeatures
    logLevel: LogLevel
    ristserverLogLevel: RistserverLogLevel
    collectHostMetrics: boolean
    unhealthyAlarm?: 'off' // enabled by default
    cordon?: boolean
    lastMessageAt?: Date
    lastRegisteredAt?: Date
    health?: ApplianceStatus
    region: RegionReference
    secondaryRegion?: RegionReference
    geoLocation?: GeoLocation
    settings: CommonApplianceSettings
    ndiSources?: NdiSource[]
    tags?: MetadataTag['name'][]
    transcodeAccelerator?: TranscodeAcceleratorInfo
}

export interface NimbraVADockerAppliance extends BaseAppliance {
    type: ApplianceType.nimbraVAdocker
}
export interface Nimbra410Appliance extends BaseAppliance {
    type: ApplianceType.nimbra410
}
export interface Nimbra412Appliance extends BaseAppliance {
    type: ApplianceType.nimbra412
}
export interface Nimbra414Appliance extends BaseAppliance {
    type: ApplianceType.nimbra414
}
export interface Nimbra414bAppliance extends BaseAppliance {
    type: ApplianceType.nimbra414b
}
export interface NimbraVA220Appliance extends BaseAppliance {
    type: ApplianceType.nimbraVA220
}
export interface NimbraVA225Appliance extends BaseAppliance {
    type: ApplianceType.nimbraVA225
}
export interface EdgeConnectAppliance extends BaseAppliance {
    type: ApplianceType.edgeConnect
}
export interface CoreAppliance extends BaseAppliance {
    type: ApplianceType.core
}
export interface ThumbAppliance extends BaseAppliance {
    type: ApplianceType.thumb
}
export interface VideonAppliance extends BaseAppliance {
    type: ApplianceType.videon
}
export interface ComprimatoAppliance extends BaseAppliance {
    type: ApplianceType.comprimato
}

export interface RiversilicaAppliance extends BaseAppliance {
    type: ApplianceType.riversilica
}

export interface MediakindRx1Appliance extends BaseAppliance {
    type: ApplianceType.mediakindRx1
}
export interface MediakindCe1Appliance extends BaseAppliance {
    type: ApplianceType.mediakindCe1
}

export interface MatroxE4Appliance extends BaseAppliance {
    /**
     * @OAS_NAME MatroxE4ApplianceType
     */
    type: ApplianceType.matroxMonarchEdgeE4_8Bit | ApplianceType.matroxMonarchEdgeE4_10Bit
    settings: MatroxE4ApplianceSettings
}
export interface MatroxD4Appliance extends BaseAppliance {
    type: ApplianceType.matroxMonarchEdgeD4
    settings: MatroxD4ApplianceSettings
}
export interface MatroxS1Appliance extends BaseAppliance {
    type: ApplianceType.matroxMonarchEdgeS1
    settings: MatroxS1ApplianceSettings
}

/**
 * @OAS_DESCRIPTION An appliance, e.g. EdgeConnect, is a piece of software that runs on a physical or virtual machine (multiple appliances can run on the same machine).
 * An appliance registers itself with the Edge backend.
 * After registration it is possible to create inputs and outputs on the ports (network interfaces) on the appliance.
 */
export type Appliance =
    | CoreAppliance
    | EdgeConnectAppliance
    | NimbraVADockerAppliance
    | Nimbra410Appliance
    | Nimbra412Appliance
    | Nimbra414Appliance
    | Nimbra414bAppliance
    | NimbraVA220Appliance
    | NimbraVA225Appliance
    | MatroxE4Appliance
    | MatroxD4Appliance
    | MatroxS1Appliance
    | ThumbAppliance
    | VideonAppliance
    | ComprimatoAppliance
    | MediakindRx1Appliance
    | MediakindCe1Appliance
    | RiversilicaAppliance

export interface ApplianceInputInfo {
    applianceId: string
    applianceName: string
    inputId: string
    inputName: string
    inputGroup: string
    inputAdminStatus: InputAdminStatus
}

export interface ApplianceOutputInfo {
    applianceId: string
    applianceName: string
    inputId?: string
    inputName?: string
    inputAdminStatus?: InputAdminStatus
    outputId: string
    outputName: string
    outputGroup: string
    outputAdminStatus: OutputAdminStatus
}

export type ApplianceSummary = Pick<Appliance, 'id' | 'name' | 'hostname' | 'type' | 'tags'> & {
    isActive: boolean
}

export interface TunnelOutputInfo {
    outputId: string
    outputName: string
    inputId?: string

    // The last downstream tunnel from the requested tunnel.
    finalTunnelId: number
}

export interface ApplianceProxySettings {
    // Setting enabled to true/false overrides the default proxy enabled setting based on the appliance type.
    enabled?: boolean
    // Setting this will override the whitelist of allowed cookies to forward to the appliance from the client when using the UI proxy.
    forwardedCookies?: string[]
    forwardedHeaders?: string[]
}

export interface CommonApplianceSettings {
    useDynamicTunnelClientSourceAddress?: boolean
    proxy?: ApplianceProxySettings

    capabilities?: ApplianceCapabilities
}

export interface MatroxDecoderGenlock {
    source: MatroxDecoderGenlockSource
    framerateFamily: MatroxDecoderFramerateFamily
}

export interface MatroxE4ApplianceSettings extends CommonApplianceSettings {
    /**
     * @OAS_NAME MatroxE4ApplianceGenlockSettings
     * @OAS_DESCRIPTION Applicable for encoder
     */
    genlock: {
        isEnabled: boolean
        resolution: MatroxEncoderGenlockResolution
    }

    // Matrox v2. undefined means 'v1.4' as older versions do not report apiVersion.
    apiVersion?: 'v1.4' | 'v2'

    // Matrox v2. Indices of input SDI ports with PCM mode enabled, index 0-3.
    sdiPortsWithPcmModeEnabled?: number[]

    /**
     * @OAS_NAME MatroxE410BitApplianceDecoderGenlockSettings
     * @OAS_DESCRIPTION Only applicable for the 10-bit E4 appliance with repurposed SDI output/decoder ports (I/O configuration).
     */
    decoderGenlock?: MatroxDecoderGenlock // Matrox v2. Only applicable for the 10-bit E4.

    // Matrox v2. Only applicable for the 10-bit E4 (i.e. not 8-bit). Defaults to 4:0
    ioConfig?: MatroxEncoderIOConfig
}

export enum MatroxEncoderIOConfig {
    fourZero = '4:0',
    threeOne = '3:1',
    twoTwo = '2:2',
}

export enum MatroxEncoderGenlockResolution {
    ntsc = 'NTSC',
    pal = 'PAL',
}

export interface MatroxD4ApplianceSettings extends CommonApplianceSettings {
    /**
     * @OAS_NAME MatroxD4ApplianceGenlockSettings
     */
    genlock: MatroxDecoderGenlock

    // Matrox v2. undefined means 'v1.4' as older versions do not report apiVersion.
    apiVersion?: 'v1.4' | 'v2'
}

export interface MatroxS1ApplianceSettings extends CommonApplianceSettings {
    /**
     * @OAS_NAME MatroxS1ApplianceGenlockSettings
     */
    genlock: MatroxDecoderGenlock

    // Matrox v2. undefined means 'v1.4' as older versions do not report apiVersion.
    apiVersion?: 'v1.4' | 'v2'

    // Matrox v2. Indices of input SDI ports with PCM mode enabled
    sdiPortsWithPcmModeEnabled?: number[]
}

export enum MatroxDecoderGenlockSource {
    internal = 'internal',
    external = 'external',
}
export enum MatroxDecoderFramerateFamily {
    familyOne = '25/50',
    familyTwo = '29.97/59.94',
    familyThree = '30/60',
}

export type ApplianceSettings = Appliance['settings']

export interface ApplianceStatus {
    title: string
    state: ApplianceConnectionState
}

export enum ApplianceConnectionState {
    connected = 'connected',
    missing = 'missing',
    neverConnected = 'neverConnected',
}

export interface ApplianceVersion {
    vaVersion?: string
    controlImageVersion?: string
    controlSoftwareVersion?: string
    dataImageVersion?: string
    dataSoftwareVersion?: string
}

/**
 * @OAS_DESCRIPTION Parameters on which a list of groups can be filtered.
 */
export interface GroupFilter extends SearchableFilter {
    id?: string
    ids?: string[]
    name?: string
    excludeIds?: string[]

    /// Return the groups with access to ANY (i.e. not all) of the received input ids
    withAccessToAnyInputs?: string[]

    /// Return the groups with no access to any one of the received input ids
    withoutAccessToAnyInputs?: string[]
}

export enum PortType {
    'ip' = 'ip',
    'coax' = 'coax',
    'videon' = 'videon',
    'ndi' = 'ndi',
}

export interface PhysicalPortListItem extends PhysicalPort {
    logicalPorts: Array<{ id: string; direction: 'input' | 'output' }>
}

export interface Address {
    /**
     * @DATA_FORMAT IPV4_OR_EMPTY_STRING
     */
    interRegionPublicAddress?: string

    /**
     * @DATA_FORMAT IPV4_OR_EMPTY_STRING
     */
    publicAddress?: string

    /**
     * @DATA_FORMAT IPV4_OR_EMPTY_STRING
     */
    internalAddress?: string

    /**
     * @DATA_FORMAT IPV4_OR_EMPTY_STRING
     */
    address: string
    netmask?: string
}

/**
 * @OAS_DESCRIPTION A PhysicalPort represents a NIC (network interface card) on an appliance.
 */
export interface PhysicalPort extends Entity {
    addresses: Array<Address>
    appliance: ApplianceReference
    // Only used for addressing SDI ports (even though ethernet ports are also given an index)
    index?: string
    // Empty string for SDI ports
    mac: string
    inputs?: Array<Input['id']>
    name: string
    outputs?: Array<Output['id']>
    owner: Group['id']
    portType: PortType
    networks: Array<Network>
    // If the port can only be used in a specific direction
    direction?: PortDirection
}

export type PhysicalPortInfo = Omit<PhysicalPort, 'appliance'> & { occupiedPorts: OccupiedPort[]; isDisabled: boolean }
export interface AppliancePhysicalPortInfo {
    appliance: ApplianceReference & Pick<Appliance, 'settings' | 'features'>
    physicalPorts: PhysicalPortInfo[]
}

export interface SharedPort {
    // refers to physicalPort.id
    port: string
    // refers to affiliate.id
    owner: string
    // refers to network.id
    networks?: string[]
}

export interface IngestTransformBase {
    type: string
}

export enum PIDRuleAction {
    Map = 'map',
    Delete = 'delete',
    SetNull = 'setNull',
}

export type PIDRule = PIDRuleMap | PIDRuleDelete | PIDRuleSetNull

export interface PIDRuleMap {
    action: PIDRuleAction.Map
    pid: number
    destPid: number
}

export interface PIDRuleDelete {
    action: PIDRuleAction.Delete
    pid: number
}

export interface PIDRuleSetNull {
    action: PIDRuleAction.SetNull
    pid: number
}

/**
 * @OAS_EXCLUDED
 *
 * This field is excluded because the functionallity is not yet entierly ready. The version of mpts_demux bundled with
 * this version has issues supporting multiple outputs with pidmans as the pidmaps gets mingled between outputs.
 */
export interface PIDMap {
    rules: PIDRule[]
}

// TODO: Rename? this is not a 'mtps' demux when it can support SPTS
export interface MptsDemuxTransform extends IngestTransformBase {
    type: 'mpts-demux'
    services: number[]
    pidMap?: PIDMap
}

export interface FmpegBaseParams {
    type: string
}

export type FfmpegAudioCodec = 'aac' | 'mp2' | 'ac3'

export interface FfmpegTranscodeParams extends FmpegBaseParams {
    type: 'transcode'
    pixelFormat?: 'yuv420p' | 'yuv422p' | 'yuv444p'
    codec?: 'h264' | 'h265' | 'av1'
    bitrate?: number
    bitrateMode?: 'CBR' | 'VBR'
    framerate?: '25' | '30000/1001' | '50' | '60000/1001'
    overlayId?: string
    resolution?: '3840:2160' | '1920:1080' | '1280:720'
    audioCodec?: FfmpegAudioCodec
    audioBitrate?: number
    audioAacProfile?: 'LC' | 'HE' | 'HEv2'
    audioAacQuality?: 'low' | 'medium' | 'high'
    deinterlace?: 'yadif'
}

export interface TranscodeTransform extends IngestTransformBase {
    type: 'transcode'
    ffmpegParams: FfmpegTranscodeParams
}

export interface AudioReshufflingStream {
    pid: number
    channels: number
}

export interface AudioReshufflingPassthrough {
    inputPid: number
    outputPid: number
}

export interface AudioReshufflingChannel {
    pid: number
    channel: number
}

export interface AudioReshufflingMapping {
    input: AudioReshufflingChannel
    output: AudioReshufflingChannel
}

export interface AudioReshufflingParams {
    inputs: AudioReshufflingStream[]
    outputs: AudioReshufflingStream[]
    mappings: AudioReshufflingMapping[]
    passthrough: AudioReshufflingPassthrough[]
}

export interface FfmpegAudioReshufflingParams extends FmpegBaseParams {
    type: 'audio-reshuffling'
    audioReshuffling: AudioReshufflingParams
    audioCodec: FfmpegAudioCodec
}

export interface AudioReshufflingTransform extends IngestTransformBase {
    type: 'audio-reshuffling'
    ffmpegParams: FfmpegAudioReshufflingParams
}

export type IngestTransform = MptsDemuxTransform | TranscodeTransform | AudioReshufflingTransform

/**
 * @OAS_DESCRIPTION Contains data used to create a new Input.
 */
export interface InputInit {
    name: string
    adminStatus: InputAdminStatus
    bufferSize?: number
    ports?: InputPort[]
    maxBitrate?: number | null
    thumbnailMode: ThumbnailMode
    videoPreviewMode?: VideoPreviewMode
    // Non-present/omitted value is interpreted as "true" for backward compatibility.
    tr101290Enabled?: boolean
    broadcastStandard?: BroadcastStandard
    handoverMethod?: HandoverMethod

    /**
     * @OAS_NAME DerivableInputSource
     */
    deriveFrom?: {
        parentInput: string
        delay: number
        ingestTransform?: IngestTransform
    }

    unhealthyAlarm?: AlarmSeverityLevels | keyof typeof AlarmSeverityLevels | null // default is null

    tags?: MetadataTag['name'][]
}

/**
 * @OAS_DESCRIPTION Contains data used to update an existing Input.
 */
export type InputUpdate = Pick<
    Input,
    | 'name'
    | 'tr101290Enabled'
    | 'broadcastStandard'
    | 'handoverMethod'
    | 'adminStatus'
    | 'videoPreviewMode'
    | 'ports'
    | 'thumbnailMode'
    | 'maxBitrate'
    | 'bufferSize'
    | 'deriveFrom'
    | 'unhealthyAlarm'
    | 'tags'
>

export type InputAdminStatusUpdate = Pick<Input, 'id' | 'adminStatus'>
export type OutputAdminStatusUpdate = Pick<Output, 'id' | 'adminStatus'>

/**
 * @OAS_DESCRIPTION Contains data used to switch input of an existing Output.
 */
export interface OutputSwitchInput {
    input: Output['input'] | null
}

export enum InputAdminStatus {
    off = 0,
    on = 1,
}

export enum ThumbnailMode {
    none = 0,
    edge = 1,
    core = 2,
}

/* This is just for debugging, we should probably remove it*/
export type InputStreamGraphDebug = {
    id: string
    graph: string
}

/**
 * @OAS_DESCRIPTION Parameters on which a list of alarms can be filtered.
 */
export interface AlarmFilter extends SearchableFilter {
    inputIds?: string[]
    includeCleared?: boolean
    applianceId?: string
}

/**
 * @OAS_DESCRIPTION Parameters on which a list of inputs can be filtered.
 */
export interface InputFilter extends SearchableFilter {
    id?: string
    ids?: string[]
    inputName?: string
    group?: string
    parentId?: string
    applianceId?: string
    applianceIds?: string[]
    canSubscribe?: boolean
    tr101290Window?: MetricWindow
    derived?: boolean
    adminStatus?: InputAdminStatus
    healthStatuses?: InputOperStatus[]

    inputApplianceNames?: string[]
    inputApplianceRegions?: string[]
    ownerGroupName?: string
    tags?: MetadataTag['name'][]

    /**
     * @OAS_DESCRIPTION Performance optimisation. Set this property to true to allow the backend to omit input metrics, health and tsInfo.
     */
    omitMetrics?: boolean
}

export interface OccupiedPort {
    physicalPortId: string

    inputId?: string
    outputId?: string
    logicalPortId: string
    portMode: PortMode
    localPortNumbers: number[]
}

export enum VideoPreviewMode {
    off = 'off',
    ondemand = 'on demand',
    alwayson = 'always on',
}

export enum HandoverMethod {
    udp = 'udp',
    unix = 'unix',
}

/**
 * @OAS_DESCRIPTION Represents a stream (or multiple streams for redundancy) ingested into the Edge cluster.
 * An Input can be assigned to one or multiple Outputs for distribution.
 */
export interface Input extends InputInit, Entity {
    channelGroup: number
    // The channelId property will be removed in the future. Use channelIds instead.
    channelId: number
    channelIds: number[]
    canSubscribe: boolean
    previewUrl?: string // hls preview url
    owner?: string
    alarms?: AlarmWithImpact[]
    metrics?: InputMetrics
    tsInfo?: TransportStream[]
    health?: InputStatus
    numOutputs?: number
    numSharedGroups?: number

    /// Source/ingress appliances only
    appliances?: LimitedAppliance[]

    // This is the ingress core codes for inputs on edge appliances,
    // meaning that for core inputs it will be empty.
    downstreamAppliances: DownstreamAppliance[] // Sorted: [primary, secondary]
    createdAt: Date
    updatedAt: Date
    /// true if this input is misconfigured, e.g. 'localIp' does not exist on the associated appliance
    misconfigured?: boolean
    previewSettings?: {
        mode: VideoPreviewMode
    }

    derivedInputs?: LimitedInput[]
}

export interface LimitedInput extends Pick<Input, 'id' | 'name'> {}

export interface DeletedInput {
    id: Input['id']
    name: Input['name']
}

export type ApiInput = Input

export enum OutputRedundancyMode {
    none = 0,
    failover = 1,
    active = 2,
}

export type OutputVideoCodec = VideoCodec | RawVideo

type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>
// Currently only applicable for Comprimato which has output transcoding capabilities
export type OutputEncoderSettings = PartialBy<GeneralEncoderSettings<OutputVideoCodec>, 'totalBitrate'>

/**
 * @OAS_DESCRIPTION Contains data used to create a new Output.
 */
export interface OutputInit {
    adminStatus: OutputAdminStatus
    redundancyMode?: OutputRedundancyMode
    group?: string
    object?: 'output'
    /**
     * @DATA_FORMAT UUID
     */
    input?: string
    // delay in milliseconds
    delay?: number
    delayMode?: DelayMode
    name: string
    ports: OutputPort[]
    unhealthyAlarm?: AlarmSeverityLevels | keyof typeof AlarmSeverityLevels | null
    tags?: MetadataTag['name'][]
    adInsertionEnabled?: boolean
    adServer?: string
    adUrl?: string
    scteEventIdFilter?: number[]
    sctePidFilter?: number[]
}

/**
 * @OAS_DESCRIPTION Output enabled/disabled status.
 * * Disabled: 0
 * * Enabled: 1
 */
export enum OutputAdminStatus {
    off = 0,
    on = 1,
}

/**
 * @OAS_DESCRIPTION Represents a stream egressed by the associated appliance and sent to a specified destination.
 * An Output can receive data from one Input.
 */
export interface Output extends Entity, OutputInit {
    createdAt: Date
    updatedAt: Date
    group: string
    alarms?: AlarmWithImpact[]
    metrics?: OutputMetrics
    tsInfo?: TransportStream[]
    health?: OutputStatus
    channelIds?: number[]
    appliances: LimitedAppliance[]
    /// Includes the source appliance
    upstreamAppliances: LimitedAppliance[]
    /// true if this output is misconfigured, e.g. 'localIp' does not exist on the associated appliance
    misconfigured?: boolean
}

/**
 * @OAS_DESCRIPTION Contains data used to update an existing Output.
 */
export type OutputUpdate = Pick<
    Output,
    | 'name'
    | 'input'
    | 'ports'
    | 'delay'
    | 'delayMode'
    | 'adminStatus'
    | 'redundancyMode'
    | 'unhealthyAlarm'
    | 'tags'
    | 'adInsertionEnabled'
    | 'adServer'
    | 'adUrl'
    | 'scteEventIdFilter'
    | 'sctePidFilter'
>

/**
 * @OAS_DESCRIPTION Parameters on which a list of outputs can be filtered.
 */
export interface OutputFilter extends SearchableFilter {
    id?: string
    ids?: string[]
    // input id
    input?: string
    inputName?: string
    // group id
    group?: string
    groupName?: string
    // appliance id
    appliance?: string
    // appliance ids
    appliances?: string[]
    applianceNames?: string[]
    adminStatus?: OutputAdminStatus
    healthStatuses?: OutputOperStatus[]

    /// hasInput: If true: only fetch outputs that have an input. If false: only fetch outputs that have no inputs.
    hasInput?: boolean
    /// belongingToInputIds: If populated: only fetch outputs belonging to the specified input ids
    belongingToInputIds?: string[]
    /// notBelongingToInputIds: If populated: only fetch outputs NOT belonging to the specified input ids
    notBelongingToInputIds?: string[]

    /// The desired tr101290-metric window to fetch
    inputTr101290Window?: MetricWindow

    regionNames?: string[]
    tags?: MetadataTag['name'][]

    /**
     * @OAS_DESCRIPTION Performance optimisation. Set this property to true to allow the backend to omit output metrics, health and tsInfo.
     */
    omitMetrics?: boolean
}

export interface SearchableFilter {
    searchName?: string
}

/**
 * @OAS_DESCRIPTION Parameters on which the appliance summary list of appliances can be filtered.
 */
export interface ApplianceSummaryFilter {
    tags?: MetadataTag['name'][]
    types?: ApplianceType[]
}

export interface ApplianceCapabilitiesFilter {
    scteInserter?: boolean
}

/**
 * @OAS_DESCRIPTION Parameters on which a list of appliances can be filtered.
 */
export interface ApplianceFilter extends SearchableFilter {
    id?: string
    ids?: string[]
    core?: boolean
    type?: ApplianceType
    types?: ApplianceType[]
    group?: string
    groupName?: string
    serials?: string[]
    serial?: string
    region?: Region['id']
    regionName?: Region['name']
    secondaryRegionName?: Region['name']
    regions?: Region['id'][]
    regionNames?: Region['name'][]
    tags?: MetadataTag['name'][]

    /**
     * @OAS_EXCLUDED
     */
    capabilities?: ApplianceCapabilitiesFilter
}

/**
 * @OAS_DESCRIPTION Parameters on which a list of address mappings can be filtered.
 */
export interface IpMappingFilter extends SearchableFilter {
    privateIp?: string
    region?: string
}

/**
 * @OAS_DESCRIPTION Parameters on which a list of tunnels can be filtered.
 */
export interface TunnelFilter {
    ids?: string[]
    client?: string
    clientType?: ApplianceType
    clients?: string[]
    appliance?: string
    server?: string
    serverType?: ApplianceType
    servers?: string[]
    type?: TunnelType
    input?: string
}

/**
 * @OAS_DESCRIPTION Parameters on which a list of ports can be filtered.
 */
export interface PortFilter extends SearchableFilter {
    id?: string
    core?: boolean
    appliance?: string
    applianceType?: ApplianceType
    owner?: Group['id']
    portType?: PortType
}

/**
 * @OAS_DESCRIPTION Parameters used when querying occupied ports
 */
export interface ListOccupiedPortFilter {
    physicalPortIds: string[]
}

/**
 * @OAS_DESCRIPTION Parameters on which a list of output recipient lists can be filtered.
 */
export interface OutputRecipientListFilter extends SearchableFilter {
    id?: string
    name?: string
    group?: string
}

/**
 * @OAS_DESCRIPTION Parameters on which a list of group recipient lists can be filtered.
 */
export interface GroupRecipientListFilter extends SearchableFilter {
    id?: string
    name?: string
    group?: string
    withoutAccessToAnyInputs?: string[]
}

const SDI_AUDIO_PAIRS = [1, 2, 3, 4, 5, 6, 7, 8] as const
export const sdiAudioPairs = [...SDI_AUDIO_PAIRS] // get the not ReadOnly array
export type SdiAudioPair = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8

export enum AudioLayout {
    mono = 'mono',
    stereo = 'stereo',
}

export enum AudioCodec {
    mpeg2 = 'mpeg-2',
    aacLc = 'aac-lc',
    heAac = 'he-aac',
    heAacV2 = 'he-aac v2',
    aes3 = 'aes3',
    ac3pt = 'ac3pt',
    ac3 = 'ac3',
    aacLatm = 'aac latm',
    aacAdts = 'aac adts',
    raw = 'raw',
}

export enum AudioSystemStandard {
    systemA = 'System A',
    systemB = 'System B',
}

export type AudioStream = GenericAudioStream<Exclude<AudioCodec, AudioCodec.ac3pt>> | Ac3AudioStream

/**
 * @OAS_NAME GenericAudioStream
 */
export interface GenericAudioStream<Codec extends AudioCodec> {
    type: AudioLayout
    /**
     * @OAS_NAME GenericAudioStreamCodec
     */
    codec: Codec
    quality?: string
    pair: SdiAudioPair
    bitrate: number
    bitDepth?: number
}

export interface Ac3AudioStream extends GenericAudioStream<AudioCodec.ac3pt> {
    codec: AudioCodec.ac3pt
    systemStandard: AudioSystemStandard
}

export interface AncSettings {
    forward: boolean
    supervise: boolean
}

export interface Smpte2038Configuration {
    '1': AncSettings
    '2': AncSettings
    '3': AncSettings
    '4': AncSettings
    '5': AncSettings
    '6': AncSettings
    '7': AncSettings
    '8': AncSettings
    '9': AncSettings
    '10': AncSettings
    '11': AncSettings
    '12': AncSettings
    '13': AncSettings
    '14': AncSettings
    '15': AncSettings
    '16': AncSettings
    '17': AncSettings
    '18': AncSettings
    '19': AncSettings
    '20': AncSettings
    '21': AncSettings
    '22': AncSettings
    '23': AncSettings
}

export enum VideoCodec {
    h264 = 'h.264',
    h265 = 'h.265',
}

export type RawVideo = 'RAW'
export const RawVideo: RawVideo = 'RAW'

export enum MatroxEncoderScalingOption {
    // Unscaled from top left
    noScalingAtTopLeft = 'noScalingAtTopLeft',
    // Unscaled centered
    noScalingAtCenter = 'noScalingAtCenter',
    // Stretched to all edges
    scaleToFitDestination = 'scaleToFitDestination',
    // Scaled to all edges
    scaleToFillButKeepContentAspectRatio = 'scaleToFillButKeepContentAspectRatio',
    // Scaling to the nearest edge
    scaleToFitAllContent = 'scaleToFitAllContent',
}
export enum MatroxEncoderPivotOption {
    none = 'none',
    by90Degrees = 'by90Degrees',
    by180Degrees = 'by180Degrees',
    by270Degrees = 'by270Degrees',
}
export enum MatroxEncoderFlipOption {
    none = 'none',
    horizontally = 'horizontally',
    vertically = 'vertically',
    bothAxis = 'bothAxis',
}

export enum MatroxEncoderPixelFormat {
    YUV420_8bits = 'YUV420_8bits',
    YUV422_8bits = 'YUV422_8bits',
    YUV422_10bits = 'YUV422_10bits',
    YUV420_10bits = 'YUV420_10bits',
}

export enum MatroxEncoderVideoProfile {
    Baseline = 'Baseline',
    Main = 'Main',
    High = 'High',
    High10Bit = 'High 10-bit',
    High422 = 'High YUV 4:2:2',
}

export enum MatroxEncoderProcessorFrameRate {
    twentyFive = 25,
    twentyNineNinetySeven = 29.97,
    thirty = 30,
    fifty = 50,
    fiftyNineNinetyFour = 59.94,
    sixty = 60,
}

export interface MatroxEncoderProcessorSettings {
    audioSourceSettings: MatroxEncoderProcessorAudioSourceSettings

    // Continue streaming on signal loss?
    inputFailSafe: boolean

    // Follow the input video source and apply the same video settings.
    // Can only be 'true' if there is a single video source (i.e. if layout is 'single') and enableTestMode is disabled.
    // Setting it to 'false' will apply Progressive encoding,
    // but then you need to configure the scaling options on the video input source, which is currently not possible via the API.
    followVideoSourceInput: boolean
    videoLayout: 'single' | 'quad'
    videoSourceSettings: MatroxEncoderProcessorVideoSourceSettings[] // One entry for each layout/video source

    /**
     * @OAS_NAME MatroxEncoderProcessorEnforcedCanvasSettings
     * @OAS_DESCRIPTION Settings applicable when not following the input video source settings.
     */
    overriddenCanvasSettings: {
        frameSize: {
            // Must be between 64 - 4096 and evenly divisible by 16
            width: number
            // Must be between 64 - 4096 and evenly divisible by 2
            height: number
        }
        frameRate: MatroxEncoderProcessorFrameRate

        // Useful if you e.g. don't stretch the video, i.e. the background is visible behind the video.
        backgroundColor: { red: number; green: number; blue: number; alpha: number }

        pixelFormat: MatroxEncoderPixelFormat
    }

    // Matrox API V2:
    enableTestMode?: boolean
}

export interface MatroxEncoderProcessorAudioSourceSettings {
    // TODO: Add support for non-sdi "Analog audio input".
    // The port index of the SDI port to receive from
    portIndex: number
}

export interface MatroxEncoderProcessorVideoSourceSettings {
    // The port index of the SDI port to receive from
    portIndex: number

    // 1 == Use all frames. Defaults to 1.
    frameCaptureRate: number

    scaling: MatroxEncoderScalingOption

    // default 0 degrees
    pivot: MatroxEncoderPivotOption

    // default 'none'
    flip: MatroxEncoderFlipOption

    // 0 - 100, default 100
    opacityPercentage: number

    // 0 - 1000, default 500
    brightness: number

    // 0 - 1000, default 500
    contrast: number

    // 0 - 360, default 0
    hue: number

    // 0 - 1000, default 500
    saturation: number
}

export enum MatroxEncoderIncludeOption {
    none = 'none',
    audioOnly = 'audioOnly',
    videoOnly = 'videoOnly',
    audioAndVideo = 'audioAndVideo',
}
export enum MatroxEncoderEncodingMode {
    lowLatency = 'lowLatency',
    highQuality = 'highQuality',
}

export enum MatroxEncoderBitrateControlMode {
    constant = 'constant',
    variable = 'variable',
}

export enum MatroxEncoderAACEncoder {
    lc = 'lc',
    hev1 = 'hev1',
    hev2 = 'hev2',
}

export enum MatroxEncoderAACFormat {
    noContainer = 'noContainer',
    adts = 'adts',
    adif = 'adif',
    unknown = 'unknown',
}

export interface MatroxEncoderSettings {
    include: MatroxEncoderIncludeOption

    /**
     * @OAS_NAME MatroxEncoderVideoSettings
     */
    videoSettings: {
        forceEncodingSize: boolean

        /**
         * @OAS_NAME MatroxEncoderEnforcedVideoSettings
         * @OAS_DESCRIPTION Settings applied when 'forceEncodingSize' is true
         */
        forcedEncodingSettings: {
            frameSize: {
                // Must be between 64 - 4096 and evenly divisible by 16
                width: number
                // Must be between 64 - 4096 and evenly divisible by 16
                height: number
            }
            scaling: MatroxEncoderScalingOption
            pivot: MatroxEncoderPivotOption
            flip: MatroxEncoderFlipOption
        }

        forcePixelFormat: boolean
        // Applied when 'forcePixelFormat' is true
        forcedPixelFormat: MatroxEncoderPixelFormat

        // Must be compatible with the 'enforcedPixelFormat'.
        encodingProfile: MatroxEncoderVideoProfile

        // Must be between 0.05 - 120 Mb/s
        targetBitrateMbps: number

        // Not visible in Matrox UI
        useLosslessEncoding: false

        /**
         * @OAS_NAME MatroxEncoderVideoGopSettings
         */
        gop: {
            // Must be greater than the pFrameInterval
            iFrameInterval: number
            // Insert a P-frame every <pFrameInterval> frame
            pFrameInterval: number
            // Not in matrox ui
            temporalIframeInterval: 0
        }

        /**
         * @OAS_NAME MatroxEncoderVideoRateControlSettings
         */
        rateControl: {
            mode: MatroxEncoderEncodingMode
            // Must be between 0-51
            quantizationMin: number
            // Must be between 0-51
            quantizationMax: number
            bitrateControl: MatroxEncoderBitrateControlMode
            // Not in matrox ui
            allowFrameSkipping: false
            // Must be between 0.1 - 120 Mb/s, and greater than or equal to the target bitrate
            maxBitrateMbps: number
        }

        forceCAVLCEntropyEncoding: boolean
    }

    /**
     * @OAS_NAME MatroxEncoderAudioSettings
     */
    audioSettings: {
        aacEncoder: MatroxEncoderAACEncoder
        // lc: 32 - 576 Kb/s
        // hev1: 32-288 Kb/s
        // hev2: 32-144 Kb/s
        bitrateKbps: number
        aacQuality: MatroxEncoderAACQualityLevel
        useTemporalNoiseShaping: boolean
        aacFormat: MatroxEncoderAACFormat

        // Available in MatroxV2
        // Only applicable if receiving from an SDI port with PCM mode enabled.
        numAudioChannels?: 2 | 4 | 6 | 8 | 16
    }
}

export interface MatroxEncoderConfig {
    type: 'matroxEncoder'
    processorSettings: MatroxEncoderProcessorSettings
    encoderSettings: MatroxEncoderSettings
    tsOutputSettings: MatroxEncoderTSOutputSettings
}

export interface MatroxEncoderTSOutputSettings {
    isAncEnabled: boolean
    // Only relevant if sdiPort.pcmMode is disabled (pcmMode available in Matrox V2)
    selectedAudioPairs: MatroxAudioChannelPair[]

    // Matrox v2.
    enable104To35?: boolean
}

export interface GeneralEncoderSettings<TVideoCodec extends string = VideoCodec> {
    videoCodec: TVideoCodec
    totalBitrate: number
    gopSizeFrames: number
    audioStreams: AudioStream[]
    enableScte35?: boolean
    enableSmpte2038?: boolean
    smpte2038?: Smpte2038Configuration

    latencyMode?: string
    scalingMode?: string
    videoFlags?: { [key: string]: boolean }
    profile?: string
    pixelFormat?: string

    colorSampling?: string

    bitDepth?: number
    resolution?: string
    scanRate?: string

    scan?: string
}

export type EncoderSettings = GeneralEncoderSettings | MatroxEncoderConfig

export enum Nimbra400VideoFlags {
    smpte12mTimecode = 'smpte12mTimecode',
}

export enum VideonVideoFlags {
    limitTo30Fps = 'limitTo30Fps',
    klvTimeCodeInsertion = 'klvTimeCodeInsertion',
    scte35Insertion = 'scte35Insertion',
    cea708_608Captions = 'cea708_608Captions',
    smpte2038Insertion = 'smpte2038Insertion',
}

export enum MatroxVideoFlags {
    ForceCAVLCEntropyEncoding = 'ForceCAVLCEntropyEncoding',
}

export type PortMode = IpPortMode | CoaxPortMode | IpcPortMode | VideonPortMode | MatroxPortMode | ComprimatoPortMode

/**
 * @OAS_DESCRIPTION Supported transport protocols over IPC.
 */
export enum IpcPortMode {
    unix = 'unix', // Unix sockets
}

/**
 * @OAS_DESCRIPTION Supported transport protocols over IP.
 */
export enum IpPortMode {
    'rist' = 'rist',
    'udp' = 'udp',
    'rtp' = 'rtp',
    'srt' = 'srt',
    'zixi' = 'zixi',
    'rtmp' = 'rtmp',
    'generator' = 'generator',
}

/**
 * @OAS_DESCRIPTION Supported video ports
 */
export enum CoaxPortMode {
    // 'hdmi' = 'hdmi',
    'sdi' = 'sdi',
    'asi' = 'asi',
}

export enum MatroxPortMode {
    'matroxSdi' = 'matroxSdi',
}

export enum VideonPortMode {
    'videonSdi' = 'videonSdi',
    'videonHdmi' = 'videonHdmi',
    'videonAuto' = 'videonAuto',
}

export enum ComprimatoPortMode {
    'comprimatoSdi' = 'comprimatoSdi',
    'comprimatoNdi' = 'comprimatoNdi',
}

export enum Output3gLevel {
    'A' = 'A',
    'B' = 'B',
}

export enum OutputPortFec {
    '1D' = '1D',
    '2D' = '2D',
}

export interface IpPortAddress {
    /**
     * @DATA_FORMAT IPV4_OR_EMPTY_STRING
     */
    address: string
    /**
     * @DATA_FORMAT PORT
     */
    port: number
}

export interface IpPortListenAddress extends IpPortAddress {
    /**
     * @DATA_FORMAT PORT_LISTENING
     */
    port: number

    /**
     * @DATA_FORMAT IPV4_CIDR_BLOCK
     */
    whitelistCidrBlock?: string | string[]
}

export interface IpPortSourceAddress {
    /**
     * @DATA_FORMAT IPV4_OR_EMPTY_STRING
     */
    sourceAddress?: string
    localPort?: number
}

export interface IpMulticastAddress {
    /**
     * @DATA_FORMAT IPV4_OR_EMPTY_STRING
     */
    multicastAddress?: string

    /**
     * @DATA_FORMAT IPV4_OR_EMPTY_STRING
     */
    multicastSource?: string
}

export interface UnixOutputPort extends OutputPortBase {
    mode: IpcPortMode.unix
    remotePath: string
}
export interface UnixInputPort extends PortBase, IngestTransformMixin {
    mode: IpcPortMode.unix
    localPath: string
}

export type IpcInputPort = UnixInputPort

export interface UdpOutputPortCommon
    extends OutputPortBase,
        IpPortAddress,
        IpMulticastAddress,
        IpPortSourceAddress,
        RegionalPort {
    ttl?: number
}

export interface UdpInputPortCommon extends PortBase, IpPortListenAddress, IpMulticastAddress, RegionalPort {}

export interface RistSimpleProfileInputPort extends PortBase, IpPortAddress, RegionalPort {
    mode: IpPortMode.rist
    profile: RistProfile.simple
    /**
     * @DATA_FORMAT PORT_LISTENING_EVEN
     */
    port: number

    /**
     * @DATA_FORMAT IPV4_CIDR_BLOCK
     */
    whitelistCidrBlock?: string | string[]
}

export interface RistSimpleProfileOutputPort extends OutputPortBase, IpPortAddress, IpPortSourceAddress, RegionalPort {
    mode: IpPortMode.rist
    profile: RistProfile.simple
    /**
     * @DATA_FORMAT PORT_EVEN
     */
    port: number
}

export interface UdpInputPort extends UdpInputPortCommon, IngestTransformMixin {
    mode: IpPortMode.udp
}

export type GeneratorTimestampResolution = 'seconds' | 'milliseconds'
export type GeneratorResolutionPreset =
    | '1280x720'
    | '1920x1080'
    | '720p' // legacy resolution
    | '1080p' // legacy resolution
/**
 * @OAS_EXCLUDED
 *
 * This field is excluded from the API because sometimes ffmpeg as it is running in edge-data does not start when
 * generating an interlaced stream. Remove this exclution once interlaced video generation works well
 */
export type GeneratorScanMode =
    | 'progressive'
    | 'interlaced_tff' // top frame first
    | 'interlaced_bff' // bottom frame first
export type GeneratorFrameRate = '30' | '60' | '25' | '50' | '29.97' | '59.94'

/**
 * @OAS_DESCRIPTION Variable bitrate – VBR. The bitrate will be determined only by what is encoded and can change over time.
 */
export interface GeneratorBitrateVBR {
    type: 'vbr'
}

/**
 * @OAS_DESCRIPTION Constant bitrate – CBR. The generator will try to keep a constant bitrate. The bitrate is in bits/s.
 */
export interface GeneratorBitrateCBR {
    type: 'cbr'
    /**
     * @DATA_FORMAT POSITIVE_INTEGER
     */
    bitrate: number // bitrate in bits/s
}

/**
 * @OAS_DESCRIPTION Encoding bitrate of the stream. Either VBR or CBR.
 */
export type GeneratorBitrate = GeneratorBitrateCBR | GeneratorBitrateVBR

export interface GeneratorInputPort extends PortBase {
    mode: IpPortMode.generator
    audioOnly?: boolean
    timestampResolution?: GeneratorTimestampResolution
    resolution?: GeneratorResolutionPreset
    scanMode?: GeneratorScanMode
    frameRate?: GeneratorFrameRate
    /**
     * @DATA_FORMAT PORT_LISTENING
     */
    port?: number
    /**
     * @DATA_FORMAT IPV4_OR_EMPTY_STRING
     */
    address?: string

    bitrate?: GeneratorBitrate

    programs?: Array<{
        title: string
        audio: {
            pid: number
        }
        video: {
            pid: number
        }
    }>
}

export interface UdpOutputPort extends UdpOutputPortCommon {
    mode: IpPortMode.udp
}

export interface RtpInputPort extends UdpInputPortCommon {
    mode: IpPortMode.rtp
    fec?: boolean

    // TODO: EDGE-4263: Make configurable when support for RTP-RTCP is added (currently only available for RIST).
    /**
     * @OAS_EXCLUDED
     */
    rtcp?: boolean

    // Used to distinguish priority between non-binary-equal RTP streams.
    // If omitted the streams must be binary-equal and will be used for SMPTE 2022-7 redundancy
    failoverPriority?: number
}

export interface RtmpInputPort extends PortBase, IpPortListenAddress, RegionalPort {
    mode: IpPortMode.rtmp
}

export interface RtmpOutputPort extends OutputPortBase, RegionalPort {
    mode: IpPortMode.rtmp
    rtmpDestinationAddress: string
}

export interface RtpOutputPort extends UdpOutputPortCommon {
    mode: IpPortMode.rtp
    fec?: OutputPortFec
    fecRows?: number
    fecCols?: number
}

export interface SrtPortBase extends OutputPortBase, RegionalPort {
    mode: IpPortMode.srt
    rtp?: boolean // RTP over SRT
    /**
     * @DATA_FORMAT SRT_PASSPHRASE
     */
    passphrase?: string // Used when pbkeylen is not 'none'
    latency: number
    ipttl?: number
    mss?: number
}

export interface SrtInputPortBase extends SrtPortBase {
    reducedBitrateDetection: boolean
    reducedBitrateThreshold?: number // Used when reducedBitrateDetection is true
    unrecoveredPacketsDetection: boolean
    unrecoveredPacketsThreshold?: number // Used when unrecoveredPacketsDetection is true
}

export interface SrtCallerPort {
    srtMode: SrtMode.caller

    // Only applicable for E-C appliances (i.e. not VA)
    streamId?: string

    // IPV4_OR_HOSTNAME
    remoteIp: string
    /**
     * @DATA_FORMAT PORT
     */
    remotePort: number
    /**
     * @DATA_FORMAT PORT
     */
    localPort?: number // Used to enforce the local outgoing port. Incoming traffic will also be received on this local port.
}

export interface SrtListenerPort {
    srtMode: SrtMode.listener

    /**
     * @DATA_FORMAT IPV4_OR_EMPTY_STRING
     */
    localIp: string
    /**
     * @DATA_FORMAT PORT_LISTENING
     */
    localPort: number

    /**
     * @DATA_FORMAT IPV4_CIDR_BLOCK
     */
    whitelistCidrBlock?: string | string[]
}

export interface SrtRendezvousPort {
    srtMode: SrtMode.rendezvous
    /**
     * @DATA_FORMAT IPV4_OR_EMPTY_STRING
     */
    localIp: string

    // IPV4_OR_HOSTNAME
    remoteIp: string
    /**
     * @DATA_FORMAT PORT_LISTENING
     */
    remotePort: number // Specifies both local and remote port. Note that the local port is this way both listening port and outgoing port.

    /**
     * @DATA_FORMAT IPV4_CIDR_BLOCK
     */
    whitelistCidrBlock?: string | string[]
}

export interface SrtBondingPort {
    // `failoverPriority` is used to determine if multiple srt ports on the same appliance should be setup
    // with bonding or use failover in the ristserver.
    // Unset failoverPriority means bonding disabled, i.e. separate srt-live-transmit processes and separate channels in the ristserver.
    // Srt outputs with multiple interfaces are only allowed when failoverPriority is set (bonding is enabled).
    // Equal failoverPriority means srt broadcast bonding.
    // Different failoverPriorities means srt main/backup bonding.
    failoverPriority?: number
}

export interface SrtCallerInputPort extends SrtCallerPort, SrtInputPortBase, SrtBondingPort {}

export interface SrtListenerInputPort extends SrtListenerPort, SrtInputPortBase, SrtBondingPort {}

export interface SrtRendezvousInputPort extends SrtRendezvousPort, SrtInputPortBase {}

export interface SrtCallerOutputPort extends SrtCallerPort, SrtOutputPortBase, SrtBondingPort {}

export interface SrtListenerOutputPort extends SrtListenerPort, SrtOutputPortBase, SrtBondingPort {}

export interface SrtRendezvousOutputPort extends SrtRendezvousPort, SrtOutputPortBase {}

export type SrtInputPort = SrtCallerInputPort | SrtListenerInputPort | SrtRendezvousInputPort
export type SrtOutputPort = SrtCallerOutputPort | SrtListenerOutputPort | SrtRendezvousOutputPort

export interface SrtOutputPortBase extends SrtPortBase {
    pbkeylen: SrtKeylen
    rateLimiting: SrtRateLimiting
    maxBw?: number // Used when rateLimiting is absolute
    inputBw?: number // Used when rateLimiting is not relativeToInput
    oheadBw?: number // Used when rate limiting is relativeToInput
}

export interface ZixiPortBase extends OutputPortBase, RegionalPort {
    mode: IpPortMode.zixi
    zixiMode: ZixiMode
    streamId: string
    password?: string
    unrecoveredPacketsDetection: boolean
    unrecoveredPacketsThreshold?: number // Used when unrecoveredPacketsDetection is true
}

export interface ZixiInputPortBase extends ZixiPortBase {
    decryptKey?: string
    decryptType: ZixiDecryptType
    reducedBitrateDetection: boolean // Only applicable for VA appliances
    reducedBitrateThreshold?: number // Used when reducedBitrateDetection is true
}

export enum ZixiDecryptType {
    none = 'none',
    aes128 = 'aes128',
    aes192 = 'aes192',
    aes256 = 'aes256',
}

export enum ZixiLinkMode {
    single = 'single',
    standby = 'standby',
    bonded = 'bonded',
}

export interface ZixiLink {
    rateLimit?: number

    /**
     * @DATA_FORMAT IPV4_OR_EMPTY_STRING
     */
    localIp: string

    /**
     * @DATA_FORMAT IPV4_OR_EMPTY_STRING
     */
    remoteIp: string
    /**
     * @DATA_FORMAT PORT
     */
    remotePort: number
}

export type ZixiLinkSet = [ZixiLink] | [ZixiLink, ZixiLink] | [ZixiLink, ZixiLink, ZixiLink]

/// Mandatory settings for VA
interface ZixiFecSettings {
    fecLatency?: number
    optimizeFec?: boolean
    adaptiveFec?: boolean
    maxFecOverhead?: number
}

export interface ZixiPullInputPort extends ZixiInputPortBase, ZixiFecSettings {
    zixiMode: ZixiMode.pull
    retransmitBuf: number
    // IPV4_OR_HOSTNAME
    remotePrimaryIp: string
    // IPV4_OR_HOSTNAME
    remoteSecondaryIp?: string

    /**
     * @DATA_FORMAT IPV4_OR_EMPTY_STRING
     */
    localIp?: string

    /**
     * @DATA_FORMAT PORT
     */
    pullPort: number
}

export interface ZixiPushInputPort extends ZixiInputPortBase {
    zixiMode: ZixiMode.push
}

export interface ZixiPullOutputPort extends ZixiPortBase {
    zixiMode: ZixiMode.pull
}

export interface ZixiPushOutputPort extends ZixiPortBase, ZixiFecSettings {
    zixiMode: ZixiMode.push
    linkMode: ZixiLinkMode
    linkSet1: ZixiLinkSet
    linkSet2?: ZixiLinkSet
    retransmitBuf: number
    // maxBitrateMbps is only applicable for zixi-feeder-push outputs, which can currently only be set up on core appliances
    maxBitrateMbps?: number
}

export type ZixiInputPort = ZixiPullInputPort | ZixiPushInputPort
export type ZixiOutputPort = ZixiPullOutputPort | ZixiPushOutputPort

export type ApplianceAllocationPurpose = 'input' | 'output'

export interface AllocateApplianceInRegionRequest {
    regionId: string
    purpose?: ApplianceAllocationPurpose // The purpose affects the balancing decisions
    // If populated: will allocate the same appliance as the input is located on, if possible.
    // Should be populated if allocating an appliance for an output.
    inputId?: string
    // If populated: will not allocate any of the excluded appliances.
    excludedApplianceIds?: string[]
}
export interface AllocateSpecificApplianceRequest {
    applianceId: string
    purpose?: ApplianceAllocationPurpose
    // Will not re-use any existing allocations with the received ids, i.e. use to create a new allocation.
    excludedAllocationIds?: string[]
}

export type AllocateApplianceRequest = AllocateApplianceInRegionRequest | AllocateSpecificApplianceRequest

export interface CoreVideoApplianceAllocation {
    id: string
    createdAt: Date
    expiresAt: Date
    appliance: ApplianceReference & { region: string }
}

/**
 * @OAS_DESCRIPTION A subset of a PhysicalPort.
 */
export interface LimitedPhysicalPort {
    id: string
    name: string
    addresses: Address[]
    appliance: ApplianceReference
    portType: PortType
}

export interface PortBase {
    id?: string
    mode: string
    /**
     * @DATA_FORMAT UUID
     */
    physicalPort: string
    copies?: number
    /**
     * @OAS_EXCLUDED
     */
    priority?: number // This carries the priority of the channel that this ports feeds into, readonly

    /**
     * @OAS_EXCLUDED
     */
    internalStreamId?: number

    /**
     * @OAS_EXCLUDED
     */
    appliance?: string // Read only property used for determining health of multi appliance inputs

    /**
     * @OAS_EXCLUDED
     */
    index?: number // Internal property that indicates the index in the ports array for sorting, it is not returned from the API
}

export interface OutputPortBase extends PortBase {}

export interface RegionalPort extends PortBase {
    // applianceAllocationId is only present when creating/updating a regional input/output (not when fetching)
    applianceAllocationId?: string

    /**
     * @OAS_NAME AllocatedApplianceRegion
     */
    region?: {
        id: string
        name: string
        // Present when fetching a regional port
        allocatedAppliance?: ApplianceReference
    }
}

export interface SdiInputPort extends PortBase {
    mode: CoaxPortMode.sdi
    encoderSettings: GeneralEncoderSettings
}

export interface MatroxSdiInputPort extends PortBase {
    mode: MatroxPortMode.matroxSdi
    encoderSettings: MatroxEncoderConfig
}

export interface VideonInputPortBase extends PortBase {
    encoderSettings: GeneralEncoderSettings
}
export interface VideonSdiInputPort extends VideonInputPortBase {
    mode: VideonPortMode.videonSdi
}

export interface VideonHdmiInputPort extends VideonInputPortBase {
    mode: VideonPortMode.videonHdmi
}

export interface VideonAutoInputPort extends VideonInputPortBase {
    mode: VideonPortMode.videonAuto
}

export interface ComprimatoSdiInput extends PortBase {
    mode: ComprimatoPortMode.comprimatoSdi
    encoderSettings: GeneralEncoderSettings
}

export interface ComprimatoNdiInput extends PortBase {
    mode: ComprimatoPortMode.comprimatoNdi
    encoderSettings: GeneralEncoderSettings
    name: string
}

export interface ComprimatoNdiOutput extends OutputPortBase {
    mode: ComprimatoPortMode.comprimatoNdi
    encoderSettings: OutputEncoderSettings
    name: string
}

export interface ComprimatoSdiOutput extends OutputPortBase {
    mode: ComprimatoPortMode.comprimatoSdi
    encoderSettings: OutputEncoderSettings
}

export interface SdiOutputPort extends OutputPortBase {
    mode: CoaxPortMode.sdi
    output3gLevel?: Output3gLevel
}

export enum MatroxAudioChannelPair {
    channel1And2 = 0b0000_0000_0000_0011,
    channel3And4 = 0b0000_0000_0000_1100,
    channel5And6 = 0b0000_0000_0011_0000,
    channel7And8 = 0b0000_0000_1100_0000,
    channel9And10 = 0b0000_0011_0000_0000,
    channel11And12 = 0b0000_1100_0000_0000,
    channel13And14 = 0b0011_0000_0000_0000,
    channel15And16 = 0b1100_0000_0000_0000,
}

// Applicable when device "Genlock Framrate family" is set to "25/50"
export enum MatroxDecoderOutputResolutionFamilyOne {
    '720p50' = '720p50',
    '1080p25' = '1080p25',
    '1080p50' = '1080p50',
    '1080i25' = '1080i25',
    '2160p50' = '2160p50', // 4K. Only available on the SDI port 4 (index 3). Will disable the other ports.
}
// Applicable when device "Genlock Framrate family" is set to "29.97/59.94"
export enum MatroxDecoderOutputResolutionFamilyTwo {
    '720p59.94' = '720p59.94',
    '1080p23.98' = '1080p23.98',
    '1080p29.97' = '1080p29.97',
    '1080p59.94' = '1080p59.94',
    '1080i29.97' = '1080i29.97',
    '2160p59.94' = '2160p59.94', // 4K. Only available on the SDI port 4 (index 3). Will disable the other ports.
}

// Applicable when device "Genlock Framrate family" is set to "30/60"
export enum MatroxDecoderOutputResolutionFamilyThree {
    '720p60' = '720p60',
    '1080p24' = '1080p24',
    '1080p30' = '1080p30',
    '1080p60' = '1080p60',
    '1080i30' = '1080i30',
    '2160p60' = '2160p60', // 4K. Only available on the SDI port 4 (index 3). Will disable the other ports.
}

export type MatroxDecoderOutputResolution =
    | MatroxDecoderOutputResolutionFamilyOne
    | MatroxDecoderOutputResolutionFamilyTwo
    | MatroxDecoderOutputResolutionFamilyThree
export interface MatroxDecoderConfig {
    outputSettings: {
        isAncEnabled: boolean
        resolution: MatroxDecoderOutputResolution
    }

    // Matrox v2.
    followInputStreamResolution?: boolean
}
export interface MatroxSdiOutputPort extends OutputPortBase {
    mode: MatroxPortMode.matroxSdi
    decoderSettings: MatroxDecoderConfig
}

export interface AsiInputPort extends PortBase {
    mode: CoaxPortMode.asi
}

export interface AsiOutputPort extends OutputPortBase {
    mode: CoaxPortMode.asi
}

interface GroupBase {
    name: string
    applianceSecret: string
}

/**
 * @OAS_DESCRIPTION Data required when creating a new group.
 */
export interface GroupInit extends GroupBase {
    adminUsername?: string
    adminPassword?: string
}

export interface GroupUpdate extends GroupBase {}

/**
 * @OAS_DESCRIPTION A group is a grouping of related items, such as users, appliances, inputs and outputs, etc.
 */
export interface Group extends Entity, Omit<GroupBase, 'applianceSecret'> {
    applianceSecret?: string
    parent?: string

    userCount?: number
    applianceCount?: number
    interfaceCount?: number
}

export type IpInputPort =
    | UdpInputPort
    | RtpInputPort
    | RtmpInputPort
    | RistSimpleProfileInputPort
    | SrtInputPort
    | ZixiInputPort
    | GeneratorInputPort

export type CoaxInputPort = SdiInputPort | AsiInputPort

export type VideonInputPort = VideonSdiInputPort | VideonHdmiInputPort | VideonAutoInputPort

export type MatroxInputPort = MatroxSdiInputPort

export type ComprimatoInputPort = ComprimatoSdiInput | ComprimatoNdiInput
export type ComprimatoOutputPort = ComprimatoNdiOutput | ComprimatoSdiOutput

export type InputPort =
    | IpInputPort
    | CoaxInputPort
    | VideonInputPort
    | MatroxInputPort
    | ComprimatoInputPort
    | IpcInputPort

export type IpOutputPort =
    | UdpOutputPort
    | RtpOutputPort
    | RistSimpleProfileOutputPort
    | SrtOutputPort
    | ZixiOutputPort
    | RtmpOutputPort

export type IpcOutputPort = UnixOutputPort

export type CoaxOutputPort = SdiOutputPort | AsiOutputPort

export type MatroxOutputPort = MatroxSdiOutputPort

export interface IngestTransformMixin {
    ingestTransform?: IngestTransform
}

export type OutputPort = IpOutputPort | CoaxOutputPort | MatroxOutputPort | ComprimatoOutputPort | IpcOutputPort

export enum Role {
    basic = 'basic',
    admin = 'admin',
    super = 'super',
}

export interface LoginRequest {
    username: string
    password: string
    otp?: string
}

export type LoginResult = {
    /**
     * @OAS_NAME LoggedInUser
     */
    user: {
        id: string
        object: 'user'
        username: string
        role: Role
        group: string
        mfa?: User['mfa']
        impersonatedBy?: { id: string; username: string }
        external: boolean
    }
}

export enum SsoStrategy {
    saml = 'saml',
    oidc = 'oidc',
}

export type LoginMethod = {
    strategy: 'local' | SsoStrategy
    displayName?: string
    displayOrder?: number
}

export type SsoLoginRequest = {
    username: string
}

export type SsoUser = {
    username: string
    effectiveRole: Role
}

export type SamlMetadata = {
    loginUrl: string
    certificate: string
}

export type OidcMetadata = {
    issuer: string
    authorizationUrl: string
    tokenUrl: string
    userInfoUrl: string
}

export type Middleware = (req: IncomingMessage, res: ServerResponse, next: () => void) => void

export interface NewUser {
    username: string
    password: string
    role: Role
    group?: Group['id']
}

/**
 * @OAS_DESCRIPTION Parameters on which a list of users can be filtered.
 */
export interface UserFilter extends SearchableFilter {
    id?: string
    username?: string
    group?: string
}

export interface User extends Entity, Omit<NewUser, 'password' | 'group'> {
    group: Group['id']
    mfa?: {
        totp?: {
            enabled: boolean
        }
    }
    impersonatedBy?: { id: string; username: string }
    external: boolean
}

// Settings configurable per user
export interface UserSettings {
    alarm: {
        notificationsEnabled: boolean
    }
}

export interface UserUpdate extends Partial<NewUser> {
    role: Role
}

export interface ApiTokenInit {
    name: string
    role: Role
    expiresAt: Date
    scopes: ACCESS_SCOPE[]
}

export interface ApiToken extends Entity, ApiTokenInit {
    lastUsedAt: Date | null
}

export interface ApiTokenCreated extends ApiToken {
    token: string // Only returned once on creation
}

export interface BuildInfo {
    /**
     * @OAS_NAME ProductId
     */
    product: Product['id']
    buildTime: Date
    release: string // Release version, i.e. R3.19.0
    commit: string // Git commit hash
    pipeline: string // Pipeline id
    entitlementsPublicKey: string // Public key for verifying entitlements
    entitlementsPublicKeyHash: string // slice of the SHA256 hash of the public key
}

export type VaInputPipe =
    | VaUdpInputPipe
    | VaSdiInputPipe
    | VaAsiInputPipe
    | VaRistInputPipe
    | VaPullInputPipe
    | VaPushInputPipe
    | VaSrtInputPipe

export type VaOutputPipe =
    | VaUdpOutputPipe
    | VaSdiOutputPipe
    | VaAsiOutputPipe
    | VaRistOutputPipe
    | VaPullOutputPipe
    | VaPushOutputPipe
    | VaSrtOutputPipe

/** ############### VA OBJECT TYPES BEGIN ############### */

export type VaObject =
    | VaEdgeSinkObject
    | VaEdgeSourceObject
    | VaUdpSinkObject
    | VaUdpSourceObject
    | VaRistSinkObject
    | VaRistSourceObject
    | VaSdiInputObject
    | VaSdiOutputObject
    | VaAsiInputObject
    | VaAsiOutputObject
    | VaEncoderObject
    | VaDecoderObject
    | VaSrtSinkObject
    | VaSrtSourceObject
    | VaPullSinkObject
    | VaPushSinkObject
    | VaPullSourceObject
    | VaPushSourceObject

export interface VaBaseObject {
    inputFrom: string
    type: VaObjectInputType | VaObjectOutputType
    name: string
    logicalPort: string
}

export interface VaSdiObject extends VaBaseObject {
    type: VaObjectInputType.sdiInput | VaObjectOutputType.sdiOutput
    /**
     * @OAS_NAME VaSdiObjectStats
     */
    statistics: {
        general: GeneralStats
        netRecv: NetRecv
        netSend: NetSend
    }
}

export interface VaSdiInputObject extends VaSdiObject {
    type: VaObjectInputType.sdiInput
}

export interface VaSdiOutputObject extends VaSdiObject {
    type: VaObjectOutputType.sdiOutput
}

export interface VaAsiObject extends VaBaseObject {
    type: VaObjectInputType.asiInput | VaObjectOutputType.asiOutput
    /**
     * @OAS_NAME VaAsiObjectStats
     */
    statistics: {
        general: GeneralStats
        netRecv: NetRecv
        netSend: NetSend
    }
}

export interface VaAsiInputObject extends VaAsiObject {
    type: VaObjectInputType.asiInput
    /**
     * @OAS_NAME VaAsiInputObjectStats
     */
    statistics: VaAsiObject['statistics'] & {
        tr101: Tr101290
    }
}

export interface VaAsiOutputObject extends VaAsiObject {
    type: VaObjectOutputType.asiOutput
}

export interface VaEncoderObject extends VaBaseObject {
    type: VaObjectInputType.encoder
    /**
     * @OAS_NAME VaEncoderObjectStats
     */
    statistics: {
        curTsBitrate: number
        encFrames: number
        tr101: Tr101290
    }
}

export interface VaDecoderObject extends VaBaseObject {
    type: VaObjectOutputType.decoder
    /**
     * @OAS_NAME VaDecoderObjectStats
     */
    statistics: {
        curTsBitrate: number
        decFrames: number
        droppedFrames: number
        repeatedFrames: number
    }
}

export interface VaEdgeSinkObject extends VaBaseObject {
    type: VaObjectOutputType.edgeSink
    /**
     * @OAS_NAME VaEdgeSinkObjectStats
     */
    statistics: {
        fecRecv: FecRecv
        udpStats: UdpStats
        tr101: Tr101290
    }
}

export interface VaEdgeSourceObject extends VaBaseObject {
    type: VaObjectInputType.edgeSource
    /**
     * @OAS_NAME VaEdgeSourceObjectStats
     */
    statistics: {
        fecSend: FecSend
        udpStats: UdpStats
    }
}

export interface VaUdpSinkObject extends VaBaseObject {
    type: VaObjectInputType.udpSink
    /**
     * @OAS_NAME VaUdpSinkObjectStats
     */
    statistics: {
        fecRecv: FecRecv
        udpStats: UdpStats
        tr101: Tr101290
    }
}

export interface VaUdpSourceObject extends VaBaseObject {
    type: VaObjectOutputType.udpSource
    /**
     * @OAS_NAME VaUdpSourceObjectStats
     */
    statistics: {
        fecSend: FecSend
        udpStats: UdpStats
    }
}

/// TODO: To be removed. Rist-objects are deprecated and are scheduled for removal from Nimbra 400/VA in September 2020
export interface VaRistSinkObject extends VaBaseObject {
    type: VaObjectInputType.ristSink
    /**
     * @OAS_NAME VaRistSinkObjectStats
     */
    statistics: {
        ristRecv: RistRecv
        udpStats: UdpStats
        tr101: Tr101290
    }
}

/// TODO: To be removed. Rist-objects are deprecated and are scheduled for removal from Nimbra 400/VA in September 2020
export interface VaRistSourceObject extends VaBaseObject {
    type: VaObjectOutputType.ristSource
    /**
     * @OAS_NAME VaRistSourceObjectStats
     */
    statistics: {
        ristSend: RistSend
        udpStats: UdpStats
    }
}

export interface VaSrtSinkObject extends VaBaseObject {
    type: VaObjectInputType.srtSink
    /**
     * @OAS_NAME VaSrtSinkObjectStats
     */
    statistics: {
        srtRecv: SrtRecv
        udpStats: UdpStats
        tr101: Tr101290
    }
}

export interface VaSrtSourceObject extends VaBaseObject {
    type: VaObjectOutputType.srtSource
    /**
     * @OAS_NAME VaSrtSourceObjectStats
     */
    statistics: {
        srtSend: SrtSend
        udpStats: UdpStats
    }
}

export interface VaPullSinkObject extends VaBaseObject {
    type: VaObjectInputType.pullSink
    /**
     * @OAS_NAME VaPullSinkObjectStats
     */
    statistics: {
        general: GeneralStats
        arqRecv: ArqRecv
        arqSend: ArqSend
        fecRecv: FecRecv
        fecSend: FecSend
        netRecv: NetRecv
        netSend: NetSend
        tr101: Tr101290
    }
}

export interface VaPushSinkObject extends VaBaseObject {
    type: VaObjectInputType.pushSink
    /**
     * @OAS_NAME VaPushSinkObjectStats
     */
    statistics: {
        general: GeneralStats
        arqRecv: ArqRecv
        arqSend: ArqSend
        fecRecv: FecRecv
        fecSend: FecSend
        netRecv: NetRecv
        netSend: NetSend
        tr101: Tr101290
    }
}

export interface VaPullSourceObject extends VaBaseObject {
    type: VaObjectOutputType.pullSource
    /**
     * @OAS_NAME VaPullSourceObjectStats
     */
    statistics: {
        general: GeneralStats
        arqRecv: ArqRecv
        arqSend: ArqSend
        fecRecv: FecRecv
        fecSend: FecSend
        netRecv: NetRecv
        netSend: NetSend
    }
}

export interface VaPushSourceObject extends VaBaseObject {
    type: VaObjectOutputType.pushSource
    /**
     * @OAS_NAME VaPushSourceObjectStats
     */
    statistics: {
        general: GeneralStats
        arqRecv: ArqRecv
        arqSend: ArqSend
        fecRecv: FecRecv
        fecSend: FecSend
        netRecv: NetRecv
        netSend: NetSend
    }
}

/** ############### VA OBJECT TYPES END ############### */

/** ############### VA STATS TYPES BEGIN ############### */

export interface GeneralStats {
    /// "Reconnections" in VA GUI
    numConnects: number
    numDisconnects: number
}

export interface UdpStats {
    bitrate: number
}

export interface ArqRecv {
    recovered: number
    retransmits: number
    dropped: number
    overflow: number
    erroredSeconds: number
    retransBitrate: number
    almostDropped: number
    retransReqs: number
    duplicates: number
}

export interface ArqSend {
    retransRate: number
    retransBitrate: number
    missedReqs: number
    ignoredReqs: number
    retransPackets: number
}

export interface FecRecv {
    recovered: number
    fecBitrate: number
    fecPackets: number
    fecRate: number
}

export interface FecSend {
    fecBitrate: number
    fecPackets: number
    fecRate: number
}

export interface NetRecv {
    latency: number
    inputBitrate: number
    burstLoss: number
    receivedBytes: number
    dropped: number
    jitter: number
    jitterRatio: number
    outOfOrder: number
    overflow: number
    numPackets: number
    packetRate: number
    packetLoss: number
    erroredSeconds: number
    unavailableSeconds: number
}

export interface NetSend {
    sendBitrate: number
    sentBytes: number
    sendLimit: number
    sentPackets: number
    rtt: number
    sendErrors: number
}

/// TODO: To be removed. Rist-objects are deprecated and are scheduled for removal from Nimbra 400/VA in September 2020
export interface RistRecv {
    recovered: number
    memData: number
    memText: number
    memResident: number
    memSize: number
    dropped: number
    rtt: number
    received: number
    retransReqs: number
    duplicates: number
    unrecovered: number
}

/// TODO: To be removed. Rist-objects are deprecated and are scheduled for removal from Nimbra 400/VA in September 2020
export interface RistSend {
    resent: number
    memData: number
    memText: number
    memResident: number
    memSize: number
    rtt: number
    sent: number
}

export interface SrtRecv {
    memData: number
    memText: number
    memResident: number
    memSize: number
    dropped: number
    rtt: number
    belated: number
    received: number
    bytesLost: number
    bytesDropped: number
    bandwidth: number
    bytes: number
    lost: number
    retransmitted: number
    bitRate: number
}

export interface SrtSend {
    memData: number
    memText: number
    memResident: number
    memSize: number
    dropped: number
    rtt: number
    bytesDropped: number
    bandwidth: number
    bytes: number
    lost: number
    sent: number
    retransmitted: number
    bitRate: number
}

export interface Tr101290 {
    prio1: Tr101290Prio1
    prio2: Tr101290Prio2
}

export type Tr101290Prio1 = {
    TS_sync_loss: number
    Sync_byte_error: number
    PAT_error: number
    Continuity_count_error: number
    PMT_error: number
    PID_error: number
}
export type Tr101290Prio2 = {
    Transport_error: number
    CRC_error: number
    PCR_error: number
    PCR_accuracy_error: number
    PTS_error: number
    CAT_error: number
}

export type VaUdpInputPipe = [VaUdpSinkObject, VaEdgeSourceObject]
export type VaUdpOutputPipe = [VaEdgeSinkObject, VaUdpSourceObject]

export type VaSdiInputPipe = [VaSdiInputObject, VaEncoderObject, VaEdgeSourceObject]
export type VaSdiOutputPipe = [VaEdgeSinkObject, VaDecoderObject, VaSdiOutputObject]

export type VaAsiInputPipe = [VaAsiInputObject, VaEdgeSourceObject]
export type VaAsiOutputPipe = [VaEdgeSinkObject, VaAsiOutputObject]

export type VaPullInputPipe = [VaPullSinkObject, VaEdgeSourceObject]
export type VaPullOutputPipe = [VaEdgeSinkObject, VaPullSourceObject]

export type VaPushInputPipe = [VaPushSinkObject, VaEdgeSourceObject]
export type VaPushOutputPipe = [VaEdgeSinkObject, VaPushSourceObject]

export type VaSrtInputPipe = [VaSrtSinkObject, VaEdgeSourceObject]
export type VaSrtOutputPipe = [VaEdgeSinkObject, VaSrtSourceObject]

/// TODO: To be removed. Rist-objects are deprecated and are scheduled for removal from Nimbra 400/VA in September 2020
export type VaRistInputPipe = [VaRistSinkObject, VaEdgeSourceObject]
export type VaRistOutputPipe = [VaEdgeSinkObject, VaRistSourceObject]

export enum VaObjectInputType {
    sdiInput = 'sdi-input',
    encoder = 'encoder',
    udpSink = 'udp-sink',
    ristSink = 'rist-sink',
    srtSink = 'srt-sink',
    edgeSource = 'edge-source',
    asiInput = 'asi-input',
    pullSink = 'pull-sink',
    pushSink = 'push-sink',
}

export enum VaObjectOutputType {
    sdiOutput = 'sdi-output',
    decoder = 'decoder',
    udpSource = 'udp-source',
    ristSource = 'rist-source',
    srtSource = 'srt-source',
    edgeSink = 'edge-sink',
    asiOutput = 'asi-output',
    pullSource = 'pull-source',
    pushSource = 'push-source',
}

/** ############### VA STATS TYPES END ############### */

export type StringPropsAsNumbers<T> = {
    [k in keyof T]: k extends string ? number : never
}

export interface Tr101290Metrics {
    prio1: StringPropsAsNumbers<Tr101290Prio1>
    prio2: StringPropsAsNumbers<Tr101290Prio2>
    time: Date
    window: MetricWindow
    applianceId: string
    applianceName: string
    applianceType: string
    inputId: string
    channelId: number
    inputName: string
    type: string
}

/**
 * @OAS_DESCRIPTION All metrics related to the input until
 * it reaches the first core video nodes. Note that the
 * vaObjects property is likely to change in upcoming revisions
 * to the API.
 */
export interface InputMetrics {
    vaObjects?: VaObject[]
    ristMetrics: StreamMetrics[]
    tr101290Metrics: Tr101290Metrics[]
}

/**
 * RistMetricType: the possible types of metric objects emitted from the ristServer
 */
export enum RistMetricType {
    ristInput = 'ristInput',
    ristOutput = 'ristOutput',
    udpInput = 'udpInput',
    udpOutput = 'udpOutput',
    unixInput = 'unixInput',
    unixOutput = 'unixOutput',
    rtpInput = 'rtpInput',
    rtpOutput = 'rtpOutput',
    channel = 'channel',
}

export enum ZixiMetricType {
    zixiOutput = 'zixiOutput',
    zixiInput = 'zixiInput',
}

export enum SrtMetricType {
    srtOutput = 'srtOutput',
    srtInput = 'srtInput',
}

export enum RtmpMetricType {
    rtmpInput = 'rtmpInput',
    rtmpOutput = 'rtmpOutput',
}

export enum TrancodeStreamMetricType {
    transcodeStream = 'transcodeStream',
}

export enum MptsMetricType {
    mptsDemux = 'mptsDemux',
    // mptsInput = 'mptsInput',
    // mptsOutput = 'mptsOutput',
}

export enum RistSimpleProfileMetricType {
    ristSimpleInput = 'ristSimpleInput',
    ristSimpleOutput = 'ristSimpleOutput',
}

export enum VideonMetricType {
    'videonAutoInput' = 'videonAutoInput',
    'videonHdmiInput' = 'videonHdmiInput',
    'videonSdiInput' = 'videonSdiInput',
}

export enum SdiMetricType {
    'sdiInput' = 'sdiInput',
    'sdiOutput' = 'sdiOutput',
}

export enum MatroxMetricType {
    'matroxSdiInput' = 'matroxSdiInput',
    'matroxSdiOutput' = 'matroxSdiOutput',
}

export enum ComprimatoMetricType {
    'comprimatoSdiInput' = 'comprimatoSdiInput',
    'comprimatoNdiInput' = 'comprimatoNdiInput',
    'comprimatoNdiOutput' = 'comprimatoNdiOutput',
    'comprimatoSdiOutput' = 'comprimatoSdiOutput',
}

export type StreamMetricType =
    | RistMetricType
    | RistSimpleProfileMetricType
    | ZixiMetricType
    | SrtMetricType
    | RtmpMetricType
    | MptsMetricType
    | TrancodeStreamMetricType

export type RistOutputMetricType =
    | RistMetricType.ristInput
    | RistMetricType.ristOutput
    | RistMetricType.udpOutput // type 'udpOutput' also covers SRT and Zixi outputs, since they are fetched from the ristserver as udp outputs streams and then fed to zixi/srt processes
    | RistMetricType.rtpOutput
    | RistSimpleProfileMetricType.ristSimpleOutput

export interface ChannelMetrics extends StreamMeasurement<RistMetricType.channel> {
    retransmitBudget: number
    retransmitRoundtripMs: number
    resetCount: number
    maxReorderTimeMs: number
    state: ChannelState | null // Introduced in R3.15.0. Will always be 'null' for appliances running older versions. Null could be removed in R3.16.0
}

export interface RistInputWindow1mMetrics extends StreamMeasurement<RistMetricType.ristInput, MetricWindow.m1> {
    packetsLost: number
}

export interface RistSimpleProfileInputMetrics extends StreamMeasurement<RistSimpleProfileMetricType.ristSimpleInput> {
    packetsLost: number
    bytesReceived: number
    packetsReceived: number
    receiveBitrate: number
    receivePacketrate: number
    rtpPacketsReceived: number
    retransmitReceivePacketrate: number
    retransmitReceiveBitrate: number
    packetSendErrors: number
    packetsDiscarded: number
    roundtripMs: number
    propagationDelayMs: number
    longestBurstLoss: number
    unsupportedRtcpPacketsReceived: number
    malformedRtcpPacketsReceived: number
}

export interface RistSimpleInputWindow1mMetrics
    extends StreamMeasurement<RistSimpleProfileMetricType.ristSimpleInput, MetricWindow.m1> {
    packetsLost: number
}

export interface RistInputMetrics extends StreamMeasurement<RistMetricType.ristInput> {
    packetSendErrors: number
    packetsDiscarded: number
    unsupportedRtcpPacketsReceived: number
    packetsLost: number
    interarrivalJitter?: number
    bytesReceived: number
    packetsReceived: number
    receiveBitrate: number
    receivePacketrate: number
    rtpPacketsReceived: number
    retransmitReceivePacketrate: number
    retransmitReceiveBitrate: number
    roundtripMs: number
    propagationDelayMs: number
    longestBurstLoss: number
    malformedRtcpPacketsReceived: number
    multipathState?: RistInputMultipathState
}

export interface RtpInputMetrics extends StreamMeasurement<RistMetricType.rtpInput> {
    packetsDiscarded: number
    receiveBitrate: number
    receivePacketrate: number
    fecReceivePacketrate?: number
    packetsRecovered?: number
    unrecoveredPacketrate?: number
    packetsLost?: number
    lostPacketrate?: number
    timestampViolations: number
    ssrc?: number
}

export interface RtpOutputMetrics extends StreamMeasurement<RistMetricType.rtpOutput> {
    packetsLost: number
    sendBitrate: number
    sendPacketrate: number
    lostPacketrate: number
    isEgress: true
    packetsDroppedBecauseOfBitrateLimit?: number
}

export interface UdpInputMetrics extends StreamMeasurement<RistMetricType.udpInput> {
    // Number of malformed *RTP* packets. Not included in `packetsReceived`.
    packetsDiscarded: number
    // Number of packets discarded because the input wasn't active.
    packetsWhileInactive: number
    receiveBitrate: number
    receivePacketrate: number
    status?: UdpInputStatusCode
}

export interface UdpOutputMetrics extends StreamMeasurement<RistMetricType.udpOutput> {
    packetsLost: number
    packetsDropped: number
    sendBitrate: number
    sendPacketrate: number
    lostPacketrate: number
    playoutMarginMs: number
    status?: UdpOutputStatusCode
    packetsDroppedBecauseOfBitrateLimit?: number
}

export interface UnixInputMetrics extends StreamMeasurement<RistMetricType.unixInput> {
    // Number of malformed *RTP* packets. Not included in `packetsReceived`.
    packetsDiscarded: number
    // Number of packets discarded because the input wasn't active.
    packetsWhileInactive: number
    receiveBitrate: number
    receivePacketrate: number
    status?: UdpInputStatusCode
}

export interface UnixOutputMetrics extends StreamMeasurement<RistMetricType.unixOutput> {
    packetsLost: number
    sendBitrate: number
    sendPacketrate: number
    lostPacketrate: number
    playoutMarginMs: number
    status?: UdpOutputStatusCode
    packetsDroppedBecauseOfBitrateLimit?: number
}

export interface UdpOutput1mMetrics extends StreamMeasurement<RistMetricType.udpOutput, MetricWindow.m1> {
    packetsLost: number
}

export interface UnixOutput1mMetrics extends StreamMeasurement<RistMetricType.unixOutput, MetricWindow.m1> {
    packetsLost: number
}

export interface RistOutputMetrics extends StreamMeasurement<RistMetricType.ristOutput> {
    bytesSent: number
    packetSendErrors: number
    packetsSent: number
    sendBitrate: number
    sendPacketrate: number
    retransmitSendPacketrate: number
    retransmitSendBitrate: number
    rtpPacketsSent: number
    malformedRtcpPacketsReceived: number
    unsupportedRtcpPacketsReceived: number
    reportedPacketLossPercent: number
    rtpPacketsDroppedBecauseOfBitrateLimit: number
    roundtripMs: number

    multipathState?: RistOutputMultipathState
}

export interface RistSimpleProfileOutputMetrics
    extends StreamMeasurement<RistSimpleProfileMetricType.ristSimpleOutput> {
    bytesSent: number
    packetSendErrors: number
    packetsSent: number
    sendBitrate: number
    sendPacketrate: number
    retransmitSendBitrate: number
    retransmitSendPacketrate: number
    roundtripMs: number
    rtpPacketsSent: number
    rtcpReceivePacketrate: number
    malformedRtcpPacketsReceived: number
    unsupportedRtcpPacketsReceived: number
    reportedPacketLossPercent: number
    rtpPacketsDroppedBecauseOfBitrateLimit: number

    isEgress: true
}

export interface ZixiFeederSinkStats {
    inBitrate: number
    outBitrate: number
    rtt: number
    jitter: number
    totalPackets: number
    packetRate: number
    droppedPackets: number
    recoveredPackets: number
    notRecoveredPackets: number
    failedSends: number
    fecPackets: number
    fecRecovered: number
    arqRequests: number
    arqRecovered: number
    arqDuplicates: number
    overflows: number
}

export interface ZixiOutputMetrics extends StreamMeasurement<ZixiMetricType.zixiOutput, MetricWindow.s10> {
    bitrate: number
    connectionStatus: string
    sinkStats?: ZixiFeederSinkStats // Set only for Zixi feeder and receiver, not broadcaster. This field can be removed once the migration to zixi_broadcaster has been completed (R3.24)
    error?: string

    // The following fields are only set when using the zixi broadcaster
    zixiBitrate?: number
    droppedPackets?: number
    duplicatePackets?: number
    sendPacketRate?: number
    jitter?: number
    packetRate?: number
    packets?: number
    rtt?: number

    arqLostPackets?: number
    arqDuplicatePackets?: number
    arqRecoveredPackets?: number
    arqOverflowedPackets?: number

    fecSentPackets?: number
    fecRecoveredPackets?: number
    fecReceivedPackets?: number
}

export interface ZixiInputMetrics extends StreamMeasurement<ZixiMetricType.zixiInput, MetricWindow.s10> {
    bitrate: number
    rtt: number
    connectionStatus: string
}

export interface SrtInputMetrics extends StreamMeasurement<SrtMetricType.srtInput, MetricWindow.s10> {
    bitrate: number
    rtt: number
    remoteAddress?: string
    packetsDropped: number
    packetsLost: number
    packetsRetransmitted: number
    msRecvBuffer: number
    connectionStatus?: SrtConnectionStatus
}

export interface SrtOutputMetrics extends StreamMeasurement<SrtMetricType.srtOutput, MetricWindow.s10> {
    bitrate: number
    rtt: number
    remoteAddress?: string
    packetsDropped: number
    packetsRetransmitted: number
    msSendBuffer: number
    connectionStatus?: SrtConnectionStatus
}

export interface RtmpInputMetrics extends StreamMeasurement<RtmpMetricType.rtmpInput, MetricWindow.s10> {
    receiveBitrateKbps: number
}

export interface RtmpOutputMetrics extends StreamMeasurement<RtmpMetricType.rtmpOutput, MetricWindow.s10> {
    state: RtmpOutputState
    restartCount: number
    sendBitrateKbps: number
}

export interface MptsDemuxMetrics extends StreamMeasurement<MptsMetricType.mptsDemux, MetricWindow.s10> {
    parentInputId: string
    parentStreamId: number

    parentUdpPackets: number
    parentUdpPacketsRate: number
    parentTruncatedBytes: number
    parentTruncatedBytesRate: number
    parentTsPackets: number
    parentTsPacketsRate: number

    udpPackets: number
    udpPacketsRate: number
    tsPackets: number
    tsPacketsRate: number
}

export interface TranscodeStreamMetrics
    extends StreamMeasurement<TrancodeStreamMetricType.transcodeStream, MetricWindow.s10> {
    sendBitrateKbps: number
}

export enum MetricWindow {
    s10 = '10 seconds',
    m1 = '1 minute',
    m15 = '15 minutes',
    h1 = '1 hour',
    h24 = '24 hours',
}

export interface StreamMeasurement<T extends StreamMetricType, TWindow extends MetricWindow = MetricWindow.s10> {
    sampledAt: Date
    applianceId: string
    applianceName: string
    applianceType: string
    streamId?: number
    type: T
    window: TWindow
    inputId: string
    channelId: number
    /// Note 13/11/2020: 'isEgress' is only applicable for 'ristServer' types (i.e. other egress-nodes such as the zixi-feeder are not tagged with this)
    // i.e. isEgress means the last node leaving the ristServer.
    isEgress?: boolean
    outputId?: string
    serverApplianceId?: string
    clientApplianceId?: string
}

export interface RistMeasurement<
    T extends RistMetricType | RistSimpleProfileMetricType,
    TWindow extends MetricWindow = MetricWindow.s10
> extends StreamMeasurement<T, TWindow> {}

export type AnyRistServerInputMetric = RistInputMetrics | RistServerIngressMetric
export type AnyRistServerOutputMetric = RistOutputMetrics | RistServerEgressMetric

export type AnyRistServerPortMode = IpPortMode.udp | IpPortMode.rtp | IpPortMode.rist

export type NonRistServerOutputMetrics = RtmpOutputMetrics | ZixiOutputMetrics | SrtOutputMetrics

export type StreamOutputMetrics =
    | NonRistServerOutputMetrics
    | AnyRistServerOutputMetric
    | UdpOutput1mMetrics
    | UnixOutput1mMetrics

export type StreamMetrics =
    | AnyRistServerInputMetric
    | RtmpInputMetrics
    | ChannelMetrics
    | SrtInputMetrics
    | ZixiInputMetrics
    | RistInputWindow1mMetrics
    | RistSimpleInputWindow1mMetrics
    | StreamOutputMetrics
    | MptsDemuxMetrics
    | TranscodeStreamMetrics

export type RistServerIngressMetric =
    | RistSimpleProfileInputMetrics
    | RtpInputMetrics
    | UdpInputMetrics
    | UnixInputMetrics

export type RistServerEgressMetric =
    | RistSimpleProfileOutputMetrics
    | RtpOutputMetrics
    | UdpOutputMetrics
    | UnixOutputMetrics

/**
 * @OAS_DESCRIPTION All metrics related to the output from the
 * point where it leaves the first core video node. Note that the
 * vaObjects property is likely to change in upcoming revisions
 * to the API.
 */
export interface OutputMetrics {
    vaObjects?: VaObject[]
    ristMetrics: StreamMetrics[]
    tr101290Metrics: Tr101290Metrics[]
}

export type Timestamp = string
export type Bitrate = number
export type BitrateSample = [Timestamp, Bitrate]

export interface MaxBitrates {
    inputMaxBitrates: BitrateSample[]
    outputMaxBitrates: BitrateSample[]
}

export interface GroupInputPermission {
    type?: 'group'
    groupId: Group['id']
    accessType: InputAccessType
}

export interface GroupListInputPermission {
    type: 'groupList'
    groupListId: GroupRecipientList['id']
    accessType: InputAccessType
}

/**
 * @OAS_DESCRIPTION Describes the kind of access a group has to an input.
 * * View: 1
 * * Pull: 2
 */
export enum InputAccessType {
    view = 1,
    pull = 2,
}

export enum ExpFeatures {
    // These currently need to be  abc = 'abc' and not abc = 'friendly name for abc'
    OASResponseValidation = 'OASResponseValidation',
    ExtDerivedInput = 'ExtDerivedInput',
    ExtSelectableDelayMode = 'ExtSelectableDelayMode',
    ExtHealthAlarms = 'ExtHealthAlarms',
    AdInsertion = 'AdInsertion',

    // TODO: remove this when all customers have upgraded to >=R3.14.0
    AllowLegacyExternalRegions = 'AllowLegacyExternalRegions',
}
export type ExperimentalFeatures = Partial<Record<ExpFeatures, boolean>>

export type SsoStrategySettings = {
    enabled?: boolean
    displayName?: string
    displayOrder?: number
    emailClaim?: string
    groupsClaim?: string
    /**
     * Maps Edge Role to SSO Group
     */
    groupMappings?: { [key: string]: string }

    configSource?: SsoConfigSource
    metadataUrl?: string
}

export type SsoSettingsSaml = SsoStrategySettings & {
    loginUrl?: string
    providerId?: string
    certificate?: string

    forceAuthentication?: boolean
    acceptedClockSkewMs?: number

    // Signing settings
    signedAuthnRequests?: boolean
    signedAssertions?: boolean
    publicCertificate?: string
    privateKey?: string
    signatureAlgorithm?: string
    digestAlgorithm?: string
}
export enum OidcPrompt {
    none = 'none',
    login = 'login',
    select_account = 'select_account',
}

export type SsoSettingsOidc = SsoStrategySettings & {
    issuer?: string
    userInfoUrl?: string
    authorizationUrl?: string
    tokenUrl?: string
    clientId?: string
    clientSecret?: string
    scope?: string

    acrValues?: string
    prompt?: OidcPrompt
}

export enum SsoConfigSource {
    metadata = 'metadata',
    manual = 'manual',
}

export type Sso = {
    saml?: SsoSettingsSaml
    oidc?: SsoSettingsOidc
}

export enum KubernetesProvider {
    metal = 'metal',
    alibaba = 'alibaba', // eks
    aws = 'aws', // eks
    google = 'google',
    azure = 'azure', // aks
    oracle = 'oracle', //oks
}

export enum TranscodeAcceleratorType {
    nvidia = 'nvidia',
    netint = 'netint',
    xilinx_u30 = 'xilinx_u30',
    videotoolbox = 'videotoolbox',
    cpu = 'cpu',
}

export enum BroadcastStandard {
    dvb = 'dvb',
    atsc = 'atsc',
}

export interface ConnectitEntitlements {
    product: ConnectitProductId
    maxTokens: number
    expiresAt?: Date
}
export interface NimbraEdgeEntitlements {
    product: NimbraEdgeProductId
}
export type LicenseEntitlements = ConnectitEntitlements | NimbraEdgeEntitlements

export enum Nimbra400AlarmsFilter {
    edgeManagedServices = 'edgeManagedServices',
    all = 'all',
    none = 'none',
}

export interface GlobalSettings {
    kubernetesProvider?: KubernetesProvider
    vaUpdateUrl: string
    logLevel: LogLevel

    /**
     * @OAS_EXCLUDED
     *
     * ristTunnelBasePort is excluded until port conflict validation takes this property into account rather than the hard coded 20_000 range.
     */
    ristTunnelBasePort: number
    defaultDelay: number
    defaultBroadcastStandard: BroadcastStandard
    defaultHandoverMethod: HandoverMethod
    zixiFeederKey: string
    zixiReceiverKey: string
    zixiZecKey: string
    ntpServer: string
    ntpEnabled: boolean
    entitlements: LicenseEntitlements
    /**
     * @deprecated Deprecated since R3.21.0 and not used. True corresponds to nimbra400Alarms: 'all' and false to nimbra400Alarms: 'edgeManagedServices'.
     */
    showAllNimbra400Alarms?: boolean
    // nimbra400Alarms can be required from R3.22.0
    nimbra400Alarms?: Nimbra400AlarmsFilter
    sslRedirectEnabled: boolean
    expFeatures: ExperimentalFeatures
    customTabTitle: string
    // Whether to show alarm popups in the Network Manager.
    alarmNotificationsEnabled: boolean
    sso: Sso
    adBucketSizeThreshold: string
    backup: BackupConf
}

export type BackupInit = {
    type: 'config' | 'billing' | 'metrics'
}

export interface S3Config {
    endpoint: string
    region?: string
    accessKeyId: string
    secretAccessKey: string
}

export type S3ConfigWithPath = S3Config & {
    objectPath: string
}

export type ExternalS3BackupConfig = S3ConfigWithPath & {
    takeExternalS3Backups: boolean
}

export type ExternalS3BackupConfigSerialized = S3ConfigWithPath & {
    takeExternalS3Backups: string
}

export interface BackupConf {
    externalS3?: ExternalS3BackupConfig
}

export interface TlsCertRead {
    fingerprint: string
    createdAt: Date
    expiresAt: Date
}

export interface TlsCertWrite {
    key: string
    cert: string
}

export interface StreamStatus<TEnum> {
    title: string
    state: TEnum
}

export interface InputStatus extends StreamStatus<InputOperStatus> {}

export interface OutputStatus extends StreamStatus<OutputOperStatus> {}

export enum InputOperStatus {
    notConfigured = 'notConfigured',
    metricsMissing = 'metricsMissing',
    allOk = 'allOk',
    inputError = 'inputError',
    tr101290Priority1Error = 'tr101290Priority1Error',
    transportError = 'transportError',
    alarm = 'alarm',
    reducedRedundancy = 'reducedRedundancy',
}

export enum OutputOperStatus {
    notConfigured = 'notConfigured',
    metricsMissing = 'metricsMissing',
    tr101290Priority1Error = 'tr101290Priority1Error',
    reducedRedundancy = 'reducedRedundancy',
    allOk = 'allOk',
    // The output is successfully sending data to the configured destination but has not (yet) received an ACK from the receiver
    // Applicable for Rist Simple Profile, but not for e.g. SRT or RTMP since they won't actually send any data until a connection has been established.
    notAcknowledged = 'notAcknowledged',
    inputError = 'inputError',
    outputError = 'outputError', // transport error or appliance alarm
    alarm = 'alarm',
}

export interface BatchDeleteResult {
    deleted: string[]
    notDeleted: string[]
    // This is a hack to make the audit log populate the entity_name column for
    // batch operations with a comma-separated list of names
    names?: string[]
}

export interface SuccessResult {
    success: true
}

export interface CommandReceipt {
    command: {
        id: string
        name: string
    }
}

export type UpgradeCommandReceipt =
    | {
          command: {
              id: string
              name: string
              appliance: string
              updateUrl: string
          }
      }
    | {
          errorMessage: string
      }

export interface BaseAlarm {
    id: string
    objectId: string
    inputName: string
    instanceId: string
    type: string
    severity: AlarmSeverityLevels
    message: string
    raiseTime: string
    acknowledged: boolean
}

/**
 * @OAS_DESCRIPTION Parameters on which a list of graph nodes can be filtered.
 */
export interface InputGraphFilter extends SearchableFilter {
    input?: string
    output?: string
    inputTr101290Window?: MetricWindow
}

export enum TunnelType {
    // Used by external appliances (edgeConnect, VA, etc) connecting to core nodes.
    // Uses "public" ip mapping.
    external = 1,

    // Used for communication between core appliances (video and thumb) within the same region.
    // Uses "internal" ip mapping.
    internal = 2,

    // Used for communication between different regions.
    // Uses "inter region" ip mapping.
    interRegion = 3,
}

export interface Tunnel {
    id: number
    type: TunnelType
    client: string
    clientName: string
    server: string
    serverName: string
    clientLocalIp: string
    clientLocalPort: number
    clientPhysicalPort: { id: string; name: string }
    clientRemoteIp: string
    clientRemotePort: number
    serverLocalIp: string
    serverLocalPort: number
    serverPhysicalPort: { id: string; name: string }
    // serverPublicIp can potentially be null for internal region thumb instances
    // where no instance metadata is available and no public ip mapping or internal ip mapping
    // is set. We might want to disallow such registrations in the future or disable the appliance
    // until a configuration is provided.
    serverPublicIp: string | null
    inputs: { id: string; name: string }[]
    network?: Network
}

export enum UsageDetailType {
    'input' = 'input',
    'output' = 'output',
}

export interface ScaleOperation {
    count: number
    // Time in seconds to wait for status to reflect the update
    waitSeconds?: number
}

export interface ScaleStatus {
    replicas?: number // undefined on deploy
    currentReplicas: number
}

export interface ImageTagOperation {
    tag: string
}

export interface GetImageTagResult {
    container: string
    tag?: string
}

export interface K8SDeployment {
    replicas?: number
    pods: {
        name?: string
        phase?: string
        containers: Array<{
            name: string
            image: string
        }>
    }[]
}

export enum ContainerTerminatedReason {
    completed = 'Completed',
}

export interface ContainerState {
    running?: {
        startedAt?: Date
    }
    terminated?: {
        reason?: ContainerTerminatedReason | string
    }
}

export interface ContainerStatus {
    containerID?: string
    image: string
    imageID: string
    lastState?: object
    name: string
    ready: boolean
    restartCount: number
    state?: ContainerState
}

export interface PodStatus {
    containerStatuses?: Array<ContainerStatus>
}

export interface PvcRequests {
    storage: string
}

export interface PvcResources {
    requests: PvcRequests
}

export interface PvcSpec {
    accessModes: string[]
    resources: PvcResources
    storageClassName: string
    volumeMode: string
    volumeName: string
}

export interface PvcCapacity {
    storage: string
}

export interface PvcStatus {
    accessModes: string[]
    capacity: PvcCapacity
    phase: string
}

export interface Pvc {
    apiVersion: string
    kind: string
    metadata: {
        name: string
        uid: string
        namespace: string
        labels: {
            [key: string]: string
        }
    }
    spec: PvcSpec
    status: PvcStatus
}

export interface PodPersistentVolumeClaim {
    claimName: string
}

export interface Volume {
    name: string
    persistentVolumeClaim?: PodPersistentVolumeClaim
}

export interface Ingress {
    metadata?: {
        uid?: string
        name?: string
        resourceVersion?: string
        namespace?: string
        annotations?: { [key: string]: string }
        labels?: { [key: string]: string }
    }
    spec?: {
        rules?: Array<{
            host?: string // magnus.nimbra.dev
            http?: Array<{
                path?: string // /api
                pathType?: string // Prefix
                backend?: {
                    service?: {
                        name?: string //edge-api
                        port?: { number?: number } // 8080
                    }
                }
            }>
        }>
        tls?: Array<{
            secretName?: string // edge-tls-certificate
            hosts?: string[]
        }>
    }
}

export interface Pod {
    metadata?: {
        generateName?: string
        labels?: {
            // key: 'app.kubernetes.io/name'
            [key: string]: string
        }
        name?: string
    }
    spec?: {
        volumes?: Volume[]
    }
    status?: PodStatus
}

export interface KubernetesNode {
    name: string
    status: string
    externalIP: string
    internalIP: string
    hostname: string
    roles: KubernetesRoles[]
    kubeletVersion?: string
    labels: {
        [key: string]: string
    }
    region: {
        id: string
        name: string
        external: ExternalRegionMode
    }
}

type Gigabyte = number
type CpuUnit = number
export interface KubernetesResourceUsage {
    cpu: {
        capacity: CpuUnit
        usage: CpuUnit
    }
    memory: {
        capacity: Gigabyte
        usage: Gigabyte
    }
    name: string | undefined
}

export interface KubernetesNodeResourceUsage extends KubernetesResourceUsage {
    region: Region['id']
}

export enum ListKubernetesNodeSortableField {
    name = 'name',
    region = 'region',
    status = 'status',
}

export interface KubernetesNodeFilter extends SearchableFilter {
    name?: string
}

export interface UpdateKubernetesNodePayload {
    roles: KubernetesRoles[]
}

export interface CoreSecret {
    secret: string
}

export interface InternalApplianceStatus {
    id: string
    name: string
    status: ApplianceStatus
}

export enum ApplianceRealm {
    core = 'core',
    edge = 'edge',
}

export interface Message<T> {
    type: string
    data: T
}

/**
 * @OAS_DESCRIPTION Data required when creating a new region.
 */
export interface RegionInit {
    name: string
    external?: Exclude<ExternalRegionMode, ExternalRegionMode.core>
}

/**
 * @OAS_DESCRIPTION Region mode
 * * 0 - The core region for Nimbra Edge, there can only be one
 * * 1 - External region, managed by kubernetes. Video/thumb nodes are managed by the kubernetes cluster
 * * 2 - External region, manually managed
 */
export enum ExternalRegionMode {
    core = 0,
    externalK8s = 1,
    external = 2,
}

/**
 * @OAS_DESCRIPTION A region represents an Edge cluster.
 * Appliances have a primary region and an optional secondary region.
 */
export interface Region extends Entity {
    name: string
    default_region: boolean | null
    external: ExternalRegionMode
}

/**
 * @OAS_DESCRIPTION Parameters on which a list of regions can be filtered.
 */
export interface RegionFilter extends SearchableFilter {
    name?: string
    id?: string
    ids?: string[]
    onlyVideoRegions?: boolean
}

export interface RpcSession {
    id: string
    appliance: string
    initializingHost: string
    connectedHost: string
    createdAt: Date
    createdBy: string
    connectedAt: Date
    disconnectedAt: Date
    updatedAt: Date
}

/**
 * @OAS_DESCRIPTION Parameters on which a list of rpc sessions can be filtered.
 */
export interface RpcSessionFilter {
    id?: string
    appliance?: string
}

export interface ThumbHost {
    host: string
    isPrimary: boolean
}

export interface ThumbRoute {
    location: string
    hosts: ThumbHost[]
}

// TODO: Ensure that the oas generator handles mapped type with nullable values
export type NullableApplianceProxySettings = {
    enabled?: ApplianceProxySettings['enabled'] | null
    forwardedCookies?: ApplianceProxySettings['forwardedCookies'] | null
    forwardedHeaders?: ApplianceProxySettings['forwardedHeaders'] | null
}

// setting proxy to null restores the default proxy settings (deletes the overrides)
export type UpdateAppliancePayloadApplianceSettings = Omit<ApplianceSettings, 'proxy'> & {
    proxy?: NullableApplianceProxySettings | null
}

export interface UpdateAppliancePayload {
    ports?: Array<SharedPort>
    region?: RegionReference
    secondaryRegion?: RegionReference | null
    geoLocation?: GeoLocation | null
    logLevel?: LogLevel
    ristserverLogLevel?: RistserverLogLevel
    collectHostMetrics?: boolean
    unhealthyAlarm?: 'on' | 'off' | null
    cordon?: boolean

    /**
     * @OAS_NAME UpdateAppliancePayloadApplianceSettings
     */
    settings?: UpdateAppliancePayloadApplianceSettings

    acceptDisruption?: {
        outputs: string[]
    }

    tags?: MetadataTag['name'][]
}

export interface OutputRecipientList extends Entity {
    name: string
    group: string
    description?: string

    // Only populated when "enriched" in handler listOutputsAndEnrichedOutputRecipientLists()
    _hasOutputsInUse?: boolean
}

export interface OutputRecipientListInit extends Omit<OutputRecipientList, 'id'> {
    addOutputs: string[]
}

export interface OutputRecipientListUpdate extends Omit<OutputRecipientListInit, 'group'> {
    removeOutputs: string[]
}

export interface OutputRecipientInit {
    output: string
    outputRecipientList: string
}

export interface OutputRecipient extends OutputRecipientInit {
    createdAt: Date
}

export type OutputOrRecipientList = Output | OutputRecipientList

export interface SendToOutputRecipientListParams {
    outputRecipientLists: string[]
}

export interface GroupRecipientList extends Entity {
    name: string
    group: string
    description?: string
}

export interface GroupRecipientListInit extends Omit<GroupRecipientList, 'id'> {
    addGroups: string[]
}

export interface GroupRecipientListUpdate extends Omit<GroupRecipientListInit, 'group'> {
    removeGroups: string[]
}

export interface GroupRecipientInit {
    group: string
    groupRecipientList: string
}

export interface GroupRecipient extends GroupRecipientInit, Entity {
    createdAt: Date
}

export enum EntityType {
    alarm = 'alarm',
    appliance = 'appliance',
    alarmLog = 'alarm-log',
    auditLog = 'audit-log',
    distribution = 'distribution',
    input = 'input',
    group = 'group',
    groupAndRecipientList = 'groupAndRecipientList',
    groupRecipientList = 'groupRecipientList',
    output = 'output',
    outputAndRecipientList = 'outputAndRecipientList',
    outputRecipientList = 'outputRecipientList',
    port = 'port',
    region = 'region',
    user = 'user',
    ipMapping = 'ip-mapping',
}

// IDEA: In the future all the objects may use the same property to determine object type
export type GroupOrGroupRecipientList =
    | (Group & { object: EntityType.group })
    | (GroupRecipientList & { object: EntityType.groupRecipientList })

export interface EchoRequest {
    message: string
}

export interface GeoCoreNode extends LimitedAppliance {
    geoLocation: NonNullable<BaseAppliance['geoLocation']>
}

/**
 * @OAS_DESCRIPTION Parameters on which a list of geo appliances can be filtered.
 */
export interface GeoApplianceFilter extends SearchableFilter {}

export type GeoAppliance =
    | GeoCoreNode
    | (Appliance & {
          geoLocation: NonNullable<BaseAppliance['geoLocation']>
      })

/// Allowed names of images that may exist in database.
export enum ImageName {
    product = 'product',
    serviceProvider = 'serviceProvider',
    favicon = 'favicon',
}

export type UpdateImageRequest = {
    name: string

    /// image param. May be either:
    /// - the base64 image data directly: 'iVBORw0KGgo...',
    /// - or metadata + base64 image data: 'data:image/png;base64,iVBORw0KGgo...'
    image: string
}

export type ImageDetails = {
    id: string
    name: ImageName
    type: string
}

export interface SanityStatus {
    sane: boolean
}

export interface SleepResult {
    didThrow: boolean
    error: any
}

export enum ListPortSortableField {
    portName = 'portName',
    address = 'address',
    publicAddress = 'publicAddress',
    applianceName = 'applianceName',
    ownerGroupName = 'ownerGroupName',
}

export enum ListAlarmSortableField {
    time = 'time',
    applianceName = 'applianceName',
    alarmCause = 'alarmCause',
    text = 'text',
}

export enum ListUserSortableField {
    userName = 'userName',
    groupName = 'groupName',
    role = 'role',
}

export enum ListApiTokenSortableField {
    name = 'name',
    userName = 'userName',
    groupName = 'groupName',
    role = 'role',
    expiresAt = 'expiresAt',
}

export enum ListInputSortableField {
    inputName = 'inputName',
    applianceName = 'applianceName',
    protocol = 'protocol',
    creationDate = 'creationDate',
    lastModified = 'lastModified',
    accessType = 'accessType',
    ownerGroupName = 'ownerGroupName',
    healthStatus = 'healthStatus',
}

export enum ListOutputSortableField {
    id = 'id',
    outputName = 'outputName',
    applianceName = 'applianceName',
    protocol = 'protocol',
    creationDate = 'creationDate',
    lastModified = 'lastModified',
    inputName = 'inputName',
    ownerGroupName = 'ownerGroupName',
    healthStatus = 'healthStatus',
}

export type OutputRecipientListSortableField = 'name' | 'description'

export enum ListGroupSortableField {
    groupName = 'groupName',
    numberOfUsers = 'numberOfUsers',
    numberOfAppliances = 'numberOfAppliances',
    numberOfPorts = 'numberOfPorts',
}

export type GroupRecipientListSortableField = 'name' | 'description'

export type RegionSortableField = 'id' | 'name' | 'default_region' | 'external'

export enum ApplianceSummarySortableField {
    applianceName = 'applianceName',
    regionName = 'regionName',
    ownerGroupName = 'ownerGroupName',
}
export enum ListApplianceSortableField {
    applianceName = 'applianceName',
    applianceType = 'applianceType',
    regionName = 'regionName',
    secondaryRegionName = 'secondaryRegionName',
    ownerGroupName = 'ownerGroupName',
    lastSeen = 'lastSeen',
    numActiveAlarms = 'numActiveAlarms',
}

export enum ListIpMappingSortableField {
    regionName = 'regionName',
    applianceName = 'applianceName',
    interfaceName = 'interfaceName',
    privateAddress = 'privateAddress',
    publicAddress = 'publicAddress',
    internalAddress = 'internalAddress',
    interRegionAddress = 'interRegionAddress',
}

export interface BillingReport {
    systemName: string
    startDate: Date
    endDate: Date
    periods: BillingPeriod[]
    summary: Billing
}

export interface BillingPeriod extends Billing {
    startDate: Date
    endDate: Date

    maxConcurrencyDate: Date
    details: BillingDetail[]
}

export type BillingDetail = IngressBillingDetail | EgressBillingDetail
export interface BillingDetailBase {
    inputId: string
    inputName: string
    credits: number

    inputServiceCount?: number
}
export interface IngressBillingDetail extends BillingDetailBase {
    inputRedundancy: boolean
    inputMerge: boolean // 2022-7 hitless merge
    inputPortCount: number
    ingressCredits: number
}
export interface EgressBillingDetail extends BillingDetailBase {
    outputId: string
    outputName: string

    outputRedundancy: boolean
    outputPortCount: number
    egressCredits: number
}

export interface Billing {
    ingressCredits: number
    egressCredits: number
    totalCredits: number
}

export interface UsageReport {
    systemName: string
    startDate: Date
    endDate: Date
    ingressTrafficGB: number
    egressTrafficGB: number
    details: UsageDetail[]
    total: number
}

export interface UsageDetail {
    startDate: Date
    endDate: Date | null
    type: UsageDetailType
    inputId: string
    inputName: string
    inputGroup: string
    inputRegion: string
    inputApplianceName: string
    inputSecondaryApplianceName?: string
    inputSecondaryApplianceRegion?: string
    inputIsSmpte_2022_7_Enabled: boolean
    /** @OAS_NAME UsageDetailInputProtocol */
    inputProtocol: PortMode | `${PortMode},${PortMode}`
    inputRedundancyEnabled: boolean
    inputServiceCount?: number
    ingressTrafficGB?: number
    egressTrafficGB?: number
    outputId?: string
    outputName?: string
    outputGroup?: string
    outputRegion?: string
    outputApplianceName?: string
    outputIsSmpte_2022_7_Enabled?: boolean
    outputProtocol?: PortMode
    outputRedundancyEnabled?: boolean
}

export interface GraphEdge<TData = any> {
    source: string
    target: string
    logicalPortId?: string
    streamId?: number
    data?: TData
}

export type GraphNodeEntityType = Extract<EntityType, EntityType.appliance | EntityType.input | EntityType.output>
export interface EntityReference {
    id: string
    type: GraphNodeEntityType
}

export interface NodeEntityReference {
    id: string
    type: GraphNodeType
}

export interface GraphNode<TData = GraphNodeData> {
    name: string
    data: TData
}

export interface GraphNodeData extends NodeEntityReference {
    region: string
    secondaryRegion?: string
    meta?: any
}

export type LimitedAppliance = Pick<BaseAppliance, 'id' | 'name' | 'type' | 'region' | 'secondaryRegion'>

export interface DownstreamAppliance extends LimitedAppliance {
    primary?: boolean
}

export interface ServiceOverview {
    graph: InputGraph
    input: Input
    parent?: Input | null
    /**
     * @OAS_NAME ServiceOverviewObjects
     */
    objects: {
        /**
         * @OAS_NAME ServiceOverviewObjectAppliances
         */
        appliances: Array<Appliance | LimitedAppliance>
        outputs: ListResult<Output>
        derivedInputs: Input[]
        tunnels: Tunnel[]
    }
}

export interface InputGraph<TNode = GraphNode, TEdge = GraphEdge> {
    nodes: {
        [name: string]: TNode
    }
    edges: Array<TEdge>
}

export enum GraphNodeType {
    derivedInput = 'derivedInput', // A derived input based on current input
    parentInput = 'parentInput', // The input that is the parent of the service displayed

    // The input we are currently looking at.
    // If it is a derived input, it will have a parentInput in the graph.
    // If it has derived inputs, they will be in the graph as 'derivedInput'.
    input = 'input',
    inputPort = 'inputPort',

    deriveFrom = 'deriveFrom',
    deriveFromParent = 'deriveFromParent',

    connection = 'connection',

    // The edge-appliance where the input resides
    inputEdgeAppliance = 'inputEdgeAppliance',

    // The core-appliance where the input resides
    inputRegionCore = 'inputRegionCore',

    thumbAppliance = 'thumbAppliance',

    // An output
    output = 'output',
    outputPort = 'outputPort',

    // The core-appliance where the output resides
    outputRegionCore = 'outputRegionCore',

    // The edge-appliance, in the same region as the input, where the output resides
    inputRegionOutputEdgeAppliance = 'inputRegionOutputEdgeAppliance',

    // The edge-appliance, in a different region than the input, where the output resides
    outputRegionOutputEdgeAppliance = 'outputRegionOutputEdgeAppliance',
}

export interface IpMapping {
    /**
     * @DATA_FORMAT IPV4
     */
    privateIp: string
    /**
     * @DATA_FORMAT IPV4_OR_EMPTY_STRING
     */
    publicIp?: string
    /**
     * @DATA_FORMAT IPV4_OR_EMPTY_STRING
     */
    internalIp?: string
    /**
     * @DATA_FORMAT IPV4_OR_EMPTY_STRING
     */
    interRegionIp?: string
}

export enum IpMappingType {
    // A user configured IP mapping
    mapped = 'mapped',

    // A suggested (incomplete) IP mapping suggested by the system
    suggested = 'suggested',
}

export interface IpMappingRead extends IpMapping {
    /**
     * @DATA_FORMAT IPV4
     */
    privateIp: string
    /**
     * @DATA_FORMAT IPV4_OR_EMPTY_STRING
     */
    publicIp?: string
    /**
     * @DATA_FORMAT IPV4_OR_EMPTY_STRING
     */
    internalIp?: string
    /**
     * @DATA_FORMAT IPV4_OR_EMPTY_STRING
     */
    interRegionIp?: string
    region: {
        id: string
        name: string
    }
    nics: LimitedPhysicalPort[]
    type: IpMappingType
}

export interface IpMappingWrite extends IpMapping {
    /**
     * @DATA_FORMAT UUID
     */
    region: string
    /**
     * @DATA_FORMAT IPV4
     */
    privateIp: string
    /**
     * @DATA_FORMAT IPV4_OR_EMPTY_STRING
     */
    publicIp?: string
    /**
     * @DATA_FORMAT IPV4_OR_EMPTY_STRING
     */
    internalIp?: string
    /**
     * @DATA_FORMAT IPV4_OR_EMPTY_STRING
     */
    interRegionIp?: string
}

export interface InputRecipientsUpdate {
    outputRecipientLists?: string[]
    addOutputIds?: string[]
    removeOutputIds?: string[]
}

export interface MetadataTag {
    name: string
    group: Group['id']
}

export interface Network {
    id: string
    name: string
    enabled: boolean
}

export interface TagFilter extends SearchableFilter {
    groupId?: string
}
export interface NetworkFilter extends SearchableFilter {
    ids?: string[]
    port?: string
}

export enum NetworkSortableField {
    id = 'id',
    name = 'name',
}
export interface Service {
    // Metadata
    id: string
    purpose: string
    product: K8sService
    description?: string
    filterUrl?: string
    allowEnableDisable?: boolean
    allowsVolumeAdministration?: boolean
    allowsRetentionPeriodAdministration?: boolean
    currentVolumes?: { name: string; currentVolumeCapacity: string }[]

    // Settings
    enabled: boolean
    hidden: boolean
    collectLogs: boolean
    desiredVolumeCapacity?: string
    retentionPeriods?: {
        name: string
        current: number // ms
        desired?: number // ms
    }[]
    storagePools?: {
        name: string
        servers: number
        size: string
    }[]
}

export enum ListServiceSortableField {
    purpose = 'purpose',
    product = 'product',
}

/**
 * @OAS_DESCRIPTION Parameters on which a list of services can be filtered.
 */
export interface ServiceFilter extends SearchableFilter {
    id?: string
    product?: string
    includeHidden?: boolean
}

export type ServiceUpdate = Omit<Service, 'retentionPeriods'> & {
    desiredRetentionPeriod?: NonNullable<Service['retentionPeriods']>[number]['desired']
}

export type StatusIssue = {
    name?: string
    reason?: string
    message: string
    timestamp?: Date
}

export type StatusPodIssue = StatusIssue & {
    type?: 'Pending' | 'Failed' | 'Waiting' | 'Terminated'
    path?: string
}

export type StatusResult = {
    status: 'OK' | 'WARN' | 'ERROR'
    description: string
    issues?: (StatusIssue | StatusPodIssue)[]
}

export interface GrafanaDashboard {
    id: number
    uid: string
    title: string
    uri: string
    url: string
    slug: string
    type: string
    tags: string[]
    isStarred: boolean
    sortMeta: number
}

export interface GetUsageReportFilter {
    startDate: Date
    endDate: Date
    groupId?: string
    limit?: number
    skip?: number
    type?: 'ingress' | 'egress'
    format?: 'csv'
}

export interface RerouteInputPayload {
    /**
     * @DATA_FORMAT UUID
     */
    avoidApplianceId?: string
}

export interface LogBatchMeta {
    timestamp: Date
    container_name?: string // Only optional for backwards compatiblitiy with appliances <=R3.18.0
    edge_component_name?: string // Only optional for backwards compatiblitiy with appliances <=R3.18.0
    edge_service_name?: string // Only optional for backwards compatiblitiy with appliances <=R3.18.0
    /**
     * @deprecated Deprecated since R3.19.0 and not used. Use the fields container_name, edge_component_name and edge_service name instead.
     */
    kubernetes?: {
        /**
         * @deprecated use .edge_component_name instead
         */
        host: string
        /**
         * @deprecated use .container_name instead
         */
        container_name: string
        /**
         * @deprecated
         */
        labels: {
            /**
             * @deprecated use .edge_service_name instead
             */
            ['app_kubernetes_io/name']: string
        }
    }
}
export interface LogBatch {
    meta: LogBatchMeta
    messages: LogMessage[]
}

export type LogMessage = {
    log: string
    logName: string
    level: string
    objects: any[]
    time: Date
    correlationId?: string
    url?: string
}

export enum ACCESS_SCOPES {
    api = 1, // Access to everything user has access to
    read_appliance_summary = 2,
    post_cloud_appliance_audit_log = 3,
}
export type ACCESS_SCOPE = keyof typeof ACCESS_SCOPES
export const ACCESS_SCOPE_NAMES_LIST = Object.keys(ACCESS_SCOPES).filter((key) => isNaN(Number(key))) as ACCESS_SCOPE[]

export const ACCESS_SCOPE_FRIENDLY_NAMES: {
    [key in ACCESS_SCOPE]: string
} = {
    api: 'Full read/write access',
    read_appliance_summary: 'Read appliance summary',
    post_cloud_appliance_audit_log: 'Post cloud appliance audit log',
}

export const ACCESS_SCOPE_DESCRIPTIONS: {
    [key in ACCESS_SCOPE]: string
} = {
    api: 'Access to everything user has access to',
    read_appliance_summary: 'Read appliance summary (id, name, hostname, type, tags, isActive)',
    post_cloud_appliance_audit_log: 'Allow external appliance automation tools to record events in the audit log',
}

export interface Scte104Injection {
    applianceId: string
    eventId?: number
    preRollMs?: number
    durationMs?: number
}

export interface Scte104InjectionResult {
    eventId: number
}

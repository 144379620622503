import { SyntheticEvent, useEffect, useState } from 'react'
import { useMatch, useParams, useResolvedPath } from 'react-router'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormLabel from '@mui/material/FormLabel'
import Paper from '@mui/material/Paper'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'

import { useBillingApi } from '../../api/billing/billing-context'
import { ValidatedInput } from '../common/InputWithValidation'
import { CustomerBillingType, NewCustomer } from 'common-billing-server/types'
import { Path } from '../../routes/path'
import { notEmpty } from '../../validators'
import { commonTextFieldProps, minHeight } from '../../theme/common-styles'
import { useFailedOperationBanner } from '../common/hooks/hook-failed-operation'
import { ButtonWithLoadingIndicator } from '../common/ButtonWithLoadingIndicator'
import { useStatefulNavigate } from '../common/hooks/hook-stateful-navigate'
import { ONE_MINUTE_IN_MS } from '../../format-date'

export const CreateEditCustomerPage = () => {
    const billingApi = useBillingApi()
    const { navigateBack } = useStatefulNavigate()
    const urlParams = useParams()
    const customerId: string | undefined = urlParams.customerId
    const resolvedPath = useResolvedPath(Path.editCustomer)
    const isEditingCustomer = !!useMatch({ path: resolvedPath.pathname, end: true }) && !!customerId

    const [name, setName] = useState('')
    const [jeevesCustomerNumber, setJeevesCustomerNumber] = useState('')
    const [comment, setComment] = useState('')
    const [billingType, setBillingType] = useState(CustomerBillingType.usageBased)
    const [billingStartsAt, setBillingStartsAt] = useState<Date | null>(new Date())

    const [isLoading, setIsLoading] = useState(false)
    const [open, setOpen] = useState(false)
    const [hasSubmittedForm, setHasSubmittedForm] = useState(false)
    const [validationErrors, setValidationErrors] = useState({
        name: undefined as string | undefined,
        comment: undefined as string | undefined,
        jeevesCustomerNumber: undefined as string | undefined,
    })
    const updateValidationError = (key: keyof typeof validationErrors, value: string | undefined) => {
        setValidationErrors((prevState) => ({ ...prevState, [key]: value }))
    }
    const formContainsInvalidInput = Object.values(validationErrors).some((error) => error)

    const { addFailedOperation, removeFailedOperation, errorBanner } = useFailedOperationBanner()

    useEffect(() => {
        const operationId = 'fetch-customer'

        async function fetchCustomer(id: string) {
            removeFailedOperation(operationId)
            setIsLoading(true)
            try {
                const response = await billingApi.listCustomers({ filter: { customerId: id } })
                const customer = response.items[0]
                if (!customer) {
                    throw new Error(`No customer found with id '${id}'`)
                }
                const { name, jeevesCustomerNumber, comment, billingType, billingStartsAt } = customer
                setName(name)
                setJeevesCustomerNumber(jeevesCustomerNumber)
                setComment(comment)
                setBillingType(billingType)

                // 'billingStartsAt' is in UTC time.
                // The date picker shows time in the local timezone, but we pretend that it is showing UTC time,
                // hence we need to modify the time so that it shows the UTC-time.
                billingStartsAt.setTime(
                    billingStartsAt.getTime() + billingStartsAt.getTimezoneOffset() * ONE_MINUTE_IN_MS
                )
                setBillingStartsAt(billingStartsAt)
            } catch (error) {
                addFailedOperation({
                    id: operationId,
                    message: 'Failed fetching customer',
                    error,
                })
            }
            setIsLoading(false)
        }

        if (isEditingCustomer) {
            void fetchCustomer(customerId)
        }
    }, [customerId, isEditingCustomer])

    const createOrUpdateCustomer = async (event: SyntheticEvent) => {
        event.preventDefault()

        const operationId = 'update-user'
        removeFailedOperation(operationId)
        setHasSubmittedForm(true)
        if (formContainsInvalidInput) {
            return
        }
        try {
            setIsLoading(true)

            // Since we interpret the local time displayed by the date picker as UTC we need to convert the selected date to UTC.
            // E.g. if the local timezone is "GMT+2" then selecting "2022-07-01 01:55" in the date picker will correspond to "2022-06-30 23:55" UTC time.
            // So we modify the selected date by removing the timezone offset, thus generating the correct UTC date for the backend
            const adjustedLocalTime = new Date(billingStartsAt!)
            adjustedLocalTime.setHours(0, 0, 0, 0)
            adjustedLocalTime.setTime(
                adjustedLocalTime.getTime() - adjustedLocalTime.getTimezoneOffset() * ONE_MINUTE_IN_MS
            )
            const customer: NewCustomer = {
                name,
                jeevesCustomerNumber,
                comment,
                billingType,
                billingStartsAt: adjustedLocalTime,
            }
            if (isEditingCustomer) {
                await billingApi.updateCustomer(customerId!, customer)
            } else {
                await billingApi.createCustomer(customer)
            }
            setIsLoading(false)
            navigateBack({ replace: true })
        } catch (error) {
            setIsLoading(false)
            addFailedOperation({
                id: operationId,
                message: `Failed ${isEditingCustomer ? 'updating' : 'creating'} customer`,
                error,
            })
        }
    }

    return (
        <>
            <Toolbar>
                <Typography variant="h6" component="div">
                    {isEditingCustomer ? 'Edit' : 'Create'} customer
                </Typography>
            </Toolbar>

            <Paper style={{ margin: '20px' }}>
                <form
                    onSubmit={(e) => void createOrUpdateCustomer(e)}
                    style={{
                        padding: '20px',
                        position: 'relative',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <ValidatedInput
                        {...commonTextFieldProps()}
                        type="text"
                        label={'Name'}
                        autoFocus={true}
                        disabled={isLoading}
                        validators={[notEmpty]}
                        value={name}
                        onChange={setName}
                        onValidationError={(error) => updateValidationError('name', error)}
                        showErrorDespiteUntouched={hasSubmittedForm}
                    />

                    <ValidatedInput
                        {...commonTextFieldProps()}
                        type="text"
                        label={'Jeeves Customer Number'}
                        disabled={isLoading}
                        validators={[notEmpty]}
                        value={jeevesCustomerNumber}
                        onChange={setJeevesCustomerNumber}
                        onValidationError={(error) => updateValidationError('jeevesCustomerNumber', error)}
                        showErrorDespiteUntouched={hasSubmittedForm}
                    />

                    <ValidatedInput
                        {...commonTextFieldProps()}
                        type="text"
                        label={'Comment'}
                        disabled={isLoading}
                        validators={[]}
                        value={comment}
                        onChange={setComment}
                        onValidationError={(error) => updateValidationError('comment', error)}
                        showErrorDespiteUntouched={hasSubmittedForm}
                    />

                    <div style={{ width: '100%', minHeight: minHeight('normal') }}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                open={open}
                                onOpen={() => setOpen(true)}
                                onClose={() => setOpen(false)}
                                label="Billing start date (UTC)"
                                value={billingStartsAt}
                                onChange={(value) => setBillingStartsAt(value)}
                                format="yyyy-MM-dd"
                                slotProps={{
                                    textField: {
                                        variant: 'standard',
                                        onClick: () => setOpen(true),
                                    },
                                }}
                            />
                        </LocalizationProvider>
                    </div>

                    <FormControl disabled={isLoading} fullWidth style={{ margin: '8px 0px 0px 0px' }}>
                        <FormLabel id="role-radio-button-group-label">Billing type</FormLabel>
                        <RadioGroup row aria-labelledby="row-radio-button-group-label" name="row-radio-button-group">
                            {Object.values(CustomerBillingType).map((type) => (
                                <FormControlLabel
                                    key={type}
                                    value={type}
                                    checked={type === billingType}
                                    onChange={(_, checked) => {
                                        if (checked) setBillingType(type)
                                    }}
                                    control={<Radio />}
                                    label={type}
                                />
                            ))}
                        </RadioGroup>
                    </FormControl>

                    <ButtonWithLoadingIndicator
                        title={isEditingCustomer ? 'Update' : 'Create'}
                        type="submit"
                        isLoading={isLoading}
                        disabled={formContainsInvalidInput || isLoading}
                        style={{ width: '25%', margin: '12px 0px 20px 0px' }}
                    />
                    {errorBanner}
                </form>
            </Paper>
        </>
    )
}

// for the rist-server
export interface AddInputStreamUnix {
    channelId: number
    streamId: number
    localPath: string
}

export enum RistServerFecMode {
    none = 0,
    '1D' = 1,
    '2D' = 2,
}

// for the rist-server
export interface AddInputStreamUdp {
    channelId: number
    streamId: number
    localIp: string
    localPort: number
    multicastAddress?: string
    sourceAddresses?: string[]
    useExtendedSequenceNumber: boolean
    parseAsTs: boolean
    failoverPriority?: number
    packetFormat?: 'rtp'
}

// for the rist-server
export interface AddInputStreamRtp {
    channelId: number
    streamId: number
    localIp: string
    localPort: number
    multicastAddress?: string
    sourceAddresses?: string[]
    useFec?: boolean
}

// for the rist-server
export interface AddInputStreamRist {
    /** Common properties **/
    channelId: number
    streamId: number
    localIp: string
    localPort: number
    failoverPriority?: number

    /// Note: When useReducedOverhead is set to True, localIp and multicastAddress shall not be set!
    useReducedOverhead: false

    /// Set to true at ingress point when integrating with external RIST implementations
    insertOriginTime: boolean

    /** Main profile properties **/
    /// undefined for simple profile, required for main profile
    tunnelId?: number
}

// for the rist-server
export interface RemoveStream {
    channelId: number
    streamId: number
}

// for the rist-server
export enum Importance {
    vital = 'vital',
    nonvital = 'nonvital',
}

// for the rist-server
export interface AddOutputStreamUdp {
    channelId: number
    streamId: number
    localIp?: string
    localPort: number
    ttl?: number
    remoteIp: string
    remotePort: number
    delayMs: number
    delayMode: DelayMode
    parseAsTs: boolean
    importance?: Importance

    packetFormat?: 'payload' | 'rtp'
    fecMode?: RistServerFecMode
    fecColumns?: number
    fecRows?: number
    notifyScte35?: boolean
    scteEventIdFilter?: number[]
    sctePidFilter?: number[]
}

// for the rist-server
export interface AddOutputStreamUnix {
    channelId: number
    streamId: number
    delayMs: number
    delayMode: DelayMode
    remotePath: string
    importance?: Importance
    notifyScte35?: boolean
    scteEventIdFilter?: number[]
    sctePidFilter?: number[]
}

// for the rist-server
export interface AddOutputStreamRtp {
    channelId: number
    streamId: number
    localIp?: string
    localPort: number
    remoteIp: string
    remotePort: number
    ttl?: number
    notifyScte35?: boolean
    scteEventIdFilter?: number[]
    sctePidFilter?: number[]
}

// for the rist-server
export interface AddOutputStreamRist {
    /** Common properties **/
    channelId: number
    streamId: number
    localIp?: string
    localPort: number
    remoteIp: string
    remotePort: number
    useOriginTiming: boolean
    /// Note: When useReducedOverhead is set to True, localIp and remoteIp shall not be set!
    useReducedOverhead: false

    /** Main profile properties **/
    /// undefined for simple profile, required for main profile
    tunnelId?: number
    notifyScte35?: boolean
    scteEventIdFilter?: number[]
    sctePidFilter?: number[]
}

// for the rist-server
export interface AddTsAnalyze {
    channelId: number
    mode: 'atsc' | 'dvb'
    analyzeTool: 'stor' | 'avtsinfo'
    analyzeInterval: number // in seconds
}

// for the rist-server
export interface RemoveTsAnalyze {
    channelId: number
}

// for the rist-server
export enum DelayMode {
    // basedOnRtpAndArrivalTime = 0,
    basedOnArrivalTime = 1,
    basedOnOriginTime = 2,
}

// for the rist-server
export interface AddChannel {
    channelId: number
    groupId?: number
    failoverPriority?: number
    description: string
    bufferDurationMs: number
    inputMultipathPolicy: RistMultipathPolicy
}

// for the rist-server
export interface SetInputMultipathParams {
    channelId: number
    multipathPolicy: RistMultipathPolicy
}

// for the rist-server
export interface SetOutputStreamMaxBitrate {
    channelId: number
    streamId: number
    bitsPerSecond: number | null
}

// for the rist-server
export enum RistMultipathPolicy {
    // Of all healthy paths, the one with the highest priority is chosen.
    // The others are sent inhibit messages to prevent them from sending RTP.
    failover = 'failover', // This is used on output appliances where all redundant outputs have redundancy mode 'failover'. Inhibit will be sent on the secondary input if the primary input is healthy.

    // No inhibit messages are sent on any input paths.
    redundancy = 'redundancy',

    // Propagate is failover with propagation of inhibit on all rist inputs
    // when all rist outputs are inhibited and no udp output is vital.
    // As soon as one rist output is resumed, propagation of inhibit stops.
    propagate = 'propagate',
}

// for the rist-server
export interface RemoveChannel {
    channelId: number
}

export interface JsonRpcResponse<T> {
    id: number
    jsonrpc: '2.0'
    method: string
    result: T
}

// for the rist-server
export interface RistPmStats {
    unavailableSeconds?: number
    severelyErroredSeconds?: number
    erroredSeconds?: number
}

// for the rist-server
export enum RistInputMultipathState {
    active = 'active',
    standby = 'standby',
}

// for the rist-server
export enum RistOutputMultipathState {
    active = 'active',
    inhibited = 'inhibited',
}

// for the backend, shared with rist-server
export interface RistInputStats extends RistPmStats {
    bytesReceived: number
    receiveBitrate?: number
    malformedRtcpPacketsReceived: number
    malformedRtcpReceivePacketrate?: number
    packetsLost: number
    lostPacketrate?: number
    longestBurstLoss?: number
    packetsReceived: number
    receivePacketrate?: number
    packetSendErrors: number
    packetsDiscarded: number
    packetsDropped: number
    interarrivalJitter?: number // Introduced in R3.18
    propagationDelayMs: number
    retransmitBytesReceived: number
    retransmitReceiveBitrate?: number
    retransmitPacketsReceived: number
    retransmitReceivePacketrate?: number
    retransmitPacketsRequested: number
    roundtripMs: number
    rtpPacketsReceived: number
    rtpBytesReceived: number
    rtpPacketsInhibited: number
    rtcpPacketsReceived: number
    rtcpReceivePacketrate?: number
    rtcpPacketsSent: number
    rtcpSendPacketrate?: number
    streamId: number
    unsupportedRtcpPacketsReceived: number
    timestampViolations: number

    multipathState?: RistInputMultipathState
}

// for the backend, shared with rist-server
export interface RistOutputStats {
    bytesSent: number
    sendBitrate?: number
    packetSendErrors: number
    malformedRtcpPacketsReceived: number
    malformedRtcpReceivePacketrate?: number
    packetsSent: number
    sendPacketrate?: number
    retransmitBytesSent: number
    retransmitSendBitrate?: number
    retransmitPacketsSent: number
    retransmitSendPacketrate?: number
    roundtripMs: number
    rtcpPacketsReceived: number
    rtcpReceivePacketrate?: number
    rtcpPacketsSent: number
    rtcpSendPacketrate?: number
    rtpBytesSent: number
    rtpPacketsInhibited: number
    rtpPacketsSent: number
    unsupportedRtcpPacketsReceived: number
    reportedPacketLossPercent: number
    rtpPacketsDroppedBecauseOfBitrateLimit: number
    streamId: number

    multipathState?: RistOutputMultipathState
}

// for the backend, shared with rist-server
export interface UdpInputStats {
    // Number of malformed *RTP* packets. Not included in `packetsReceived`.
    packetsDiscarded: number
    // Number of packets discarded because the input wasn't active.
    packetsWhileInactive: number
    bytesReceived: number
    receiveBitrate?: number
    packetsReceived: number
    receivePacketrate?: number
    packetsLost: number
    streamId: number

    status?: UdpInputStatusCode
}

// for the rist-server
export interface UnixInputStats extends UdpInputStats {}

// for the backend, shared with rist-server
export interface UdpOutputStats extends RistPmStats {
    bytesSent: number
    sendBitrate?: number
    packetsLost: number
    lostPacketrate?: number
    packetsRecovered: number
    packetsSent: number
    playoutMarginMs: number
    sendPacketrate?: number
    fecBytesSent?: number //      When packetFormat == 'rtp'
    fecSendBitrate?: number //    When packetFormat == 'rtp'
    fecPacketsSent?: number //    When packetFormat == 'rtp'
    fecSendPacketrate?: number // When packetFormat == 'rtp'
    streamId: number
    /// 'status' applies only to UDP outputs with delayMode: 2 (basedOnOriginTiming)
    status?: UdpOutputStatusCode
    delayMode: string
    packetsDropped: number
    packetsDroppedBecauseOfBitrateLimit: number
}

// for the rist-server
export interface UnixOutputStats extends UdpOutputStats {}

// for the backend, shared with rist-server
export interface RtpInputStats {
    bytesReceived: number
    receiveBitrate?: number
    packetsReceived: number
    receivePacketrate?: number

    fecBytesReceived: number
    fecReceiveBitrate?: number
    fecPacketsDiscarded: number
    fecPacketsReceived: number
    fecReceivePacketrate?: number
    packetsRecovered?: number
    packetsUnrecovered?: number
    unrecoveredPacketrate?: number

    streamId: number
    packetsDiscarded: number
    packetsDropped: number
    packetsLost: number
    lostPacketrate?: number
    timestampViolations: number
    status?: RtpInputStatusCode
    ssrc?: number
}

// for the rist-server
export interface RtpOutputStats
    extends Omit<UdpOutputStats, 'status' | 'delayMode' | 'packetsDropped' | 'packetsRecovered'> {}

export enum ChannelState {
    activated = 'activated',
    deactivated = 'deactivated',
}

// for the rist-server
export interface ChannelStats {
    channelId: number
    reorderSectionMs: number
    resetCount: number
    retransmitBudget: number
    retransmitIntervalMs: number
    retransmitRoundtripMs: number
    ageOfOldestHoleMs?: number // TODO: should we create a MergedChannelStats and MergedRistStatisticsResponse instead?
    maxReorderTimeMs?: number // TODO: should we create a MergedChannelStats and MergedRistStatisticsResponse instead?
    state?: ChannelState // TODO: should we create a MergedChannelStats and MergedRistStatisticsResponse instead?
    ristInput: RistInputStats[]
    ristOutput: RistOutputStats[]
    udpInput: UdpInputStats[]
    udpOutput: UdpOutputStats[]
    unixInput: UnixInputStats[] // unixInput is not defined in R3.13
    unixOutput: UnixOutputStats[] // unixOutput is not defined in R3.13
    rtpInput: RtpInputStats[]
    rtpOutput: RtpOutputStats[]
}

// for the rist-server
export interface ChannelDeltaStats {
    channelId: number
    ageOfOldestHoleMs: number
    maxReorderTimeMs: number
    ristInputs: RistInputDeltaStats[]
    ristOutputs: RistOutputDeltaStats[]
    rtpInputs: RtpInputDeltaStats[]
    rtpOutputs: RtpOutputDeltaStats[]
    udpInputs: UdpInputDeltaStats[]
    udpOutputs: UdpOutputDeltaStats[]
}

// for the rist-server
export interface RistInputDeltaStats {
    streamId: number
    longestBurstLoss: number
    maxPathDiffDelayMs: number | null
    maxReceptionLatencyMs: number
    maxRoundtripMs: number | null
    pm: RistPmStats
}

// for the rist-server
export interface RistOutputDeltaStats {
    streamId: number
    maxRoundtripMs: number | null
}

// for the rist-server
export interface UdpOutputDeltaStats {
    streamId: number
    shortestPlayoutMarginMs: number | null
    pm: RistPmStats
}

// for the rist-server
export interface UdpInputDeltaStats {
    streamId: number
    maxReceptionLatencyMs: number
}

// for the rist-server
export interface RtpInputDeltaStats {
    streamId: number
    pm: RistPmStats
    longestBurstLoss: number
    maxReceptionLatencyMs: number
    maxPathDiffDelayMs: number | null
    maxRoundtripMs: number | null
}

// for the rist-server
export interface RtpOutputDeltaStats {
    streamId: number
    maxRoundtripMs: number | null
}

// for the rist-server
export interface TunnelRxErrors {
    badAddress: number
    bufferFull: number
    cryptoFailure: number
    malformed: number
    notIpv4: number
    notUdp: number
    socketError: number
    failedUdpChecksum: number
    versionError: number
    dropped: number
}

// for the backend
interface TunnelRxWithRate {
    bytes: number
    bitrate?: number
    error: TunnelRxErrors
    packets: number
    packetRate?: number
    keepAlivePackets: number
    keepAlivePacketrate?: number
}

// for the rist-server
export interface TunnelTxErrors {
    configurationError: number
    cryptoFailure: number
    notConnected: number
    socketError: number
    dropped: number
    packetTooLarge: number
}

interface TunnelTxWithRate {
    bytes: number
    bitrate?: number
    error: TunnelTxErrors
    packets: number
    packetRate?: number
}

// for the rist-server
export interface TunnelStatsWithRate {
    rx: TunnelRxWithRate
    tunnelId: number
    tx: TunnelTxWithRate
}

// for the backend
export interface RistStatisticsResponseDiff {
    deadlinesMissed: number
    channels: ChannelStats[]
    tunnels: TunnelStatsWithRate[]
    threads: RistStatsThreadStatus
}

// for the backend
export interface RistStatsThreadStatus {
    hardwareConcurrency: number
    maxStreamerThreads: number
    numStreamerThreads: number
    threads: ThreadStats[]
}

interface ThreadStats {
    threadId: string
    cpuPercentage?: number // Not available on all OS (Mac). See rist-server API docs.
    osThreadId?: number // Not available on all OS (Mac). See rist-server API docs.
    runqueuePercentage?: number // Not available on all OS (Mac). See rist-server API docs.
    longestWorkMs: number
    maxDelayedTimerMs: number
}

// for the rist-server
export enum RtpInputStatusCode {
    ok = 'ok',
    oddSsrc = 'odd_ssrc',
    unknown = 'unknown',
}

// for the rist-server
export enum UdpInputStatusCode {
    active = 'active',
    standby = 'standby',
}

// for the rist-server
export enum UdpOutputStatusCode {
    playing = 'playing',
    noData = 'no_data',
    noOriginTime = 'no_origin_time',
    noCommonTimeBase = 'no_common_time_base',
    lagging = 'lagging',
}

// for the rist-server
export interface RistTunnelServer {
    tunnelId: number
    secret: string
    localIp: string
    localPort: number
    authentication: 'keepAlive'
}

// for the rist-server
export interface RistTunnelClient extends RistTunnelServer {
    remoteIp: string
    remotePort: number
}

// for the rist-server
export interface RemoveInputStream {
    channelId: number
    streamId: number
}

// for the rist-server
export interface RemoveOutputStream {
    channelId: number
    streamId: number
}

export interface AddThumbnailer {
    channelId: number
    remoteAddress: string
    remotePath: string
    remotePort: number
    thumbInterval: number
}

export interface RemoveThumbnailer {
    channelId: number
}

export interface Scte35SourceFilterable {
    scte35: {
        pids: number[] | null
        eventIds: number[] | null
    }
}

export interface SetOutputStreamAdInsertion extends Scte35SourceFilterable {
    channelId: number
    streamId: number
    enable: boolean
}

export interface SetOutputStreamAdScte35Source extends Scte35SourceFilterable {
    channelId: number
    streamId: number
}

export interface SetOutputStreamAdPlaylist {
    eventId: number
    channelId: number
    streamId: number

    // Play in order of the array
    files: {
        path: string // Absolute path
        durationMs: number
        creativeId: string
        trackingEvents: number[] // Timestamps in ms, must be ordered
    }[]
}

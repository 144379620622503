import { useMemo, useRef } from 'react'
import { useLocation } from 'react-router'
import Paper from '@mui/material/Paper'
import Tooltip from '@mui/material/Tooltip'
import Cached from '@mui/icons-material/Cached'

import { useBillingApi } from '../../api/billing/billing-context'
import { Installation, ListInstallationSortableField, Role } from 'common-billing-server/types'
import { Column, DataFetchingPaginatedTable, RefreshableDataFetchingPaginatedTable } from '../common/Table'
import { Query } from 'common/query'
import { ListResult, SortOrder } from 'common/api/v1/types'
import { useFailedOperationBanner } from '../common/hooks/hook-failed-operation'
import { ListPageToolbar } from '../common/ListPageToolbar'
import { NavigationState, useStatefulNavigate } from '../common/hooks/hook-stateful-navigate'
import { makeEditCustomerPath, makeEditLicensePath, makeInstallationDetailsPath } from '../../routes/path'
import { RoleBasedLink } from '../common/RoleBasedLink'
import { isRoleAuthorized } from 'common-billing-server/role'
import { useAuth } from '../login/Auth'
import { formatDateTime } from '../../format-date'
import { RotatingButton } from '../common/RotatingButton'
import { InstallationActions } from './InstallationActions'
import { PaginatedDataFetchingFilter } from '../common/hooks/hook-paginated-data-fetching'

export const ListInstallationsPage = () => {
    const billingApi = useBillingApi()
    const location = useLocation()
    const { user } = useAuth()

    const tableRef = useRef<RefreshableDataFetchingPaginatedTable>(null)
    const { addFailedOperation, errorBanner } = useFailedOperationBanner()

    const { navigate } = useStatefulNavigate()
    const navigateToInstallation = ({ id: installationId, customer }: Installation) =>
        navigate(makeInstallationDetailsPath({ installationId, customerId: customer.id }))

    const columns: Column<Installation, ListInstallationSortableField>[] = useMemo(
        () => [
            {
                title: 'Host',
                valueForColumn: (l) => `${l.deleted ? '(deleted) ' : ''}${l.host}`,
                sortField: ListInstallationSortableField.host,
                isClickable: true,
            },
            {
                title: 'Release',
                valueForColumn: (l) => l.releaseTag,
                sortField: ListInstallationSortableField.releaseTag,
                isClickable: true,
            },
            {
                title: 'Deployment',
                valueForColumn: (l) => l.deploymentProvider,
                sortField: ListInstallationSortableField.deploymentProvider,
                isClickable: true,
            },
            {
                title: 'Created at (UTC)',
                valueForColumn: (l) => {
                    const createdAt = formatDateTime(l.createdAt)
                    const updatedAt = formatDateTime(l.updatedAt)
                    return (
                        <Tooltip title={`Updated: ${updatedAt}`}>
                            <div>{createdAt}</div>
                        </Tooltip>
                    )
                },
                sortField: ListInstallationSortableField.createdAt,
            },
            {
                title: 'Last reported (UTC)',
                valueForColumn: (l) =>
                    l.lastReportedPeriodStartAt ? formatDateTime(l.lastReportedPeriodStartAt) : 'N/A',
            },
            {
                title: 'Customer',
                isClickable: false,
                valueForColumn: ({ customer }) => {
                    const navigationState: NavigationState = { from: location }
                    return (
                        <RoleBasedLink
                            minimumAllowedRole={Role.admin}
                            to={makeEditCustomerPath(customer.id)}
                            state={navigationState}
                        >
                            <div>{customer.name}</div>
                        </RoleBasedLink>
                    )
                },
                sortField: ListInstallationSortableField.customerName,
            },
            {
                title: 'License key',
                isClickable: false,
                valueForColumn: (installation) => {
                    const navigationState: NavigationState = { from: location }
                    return (
                        <RoleBasedLink
                            minimumAllowedRole={Role.licenseAdmin}
                            to={makeEditLicensePath(installation.license.id)}
                            state={navigationState}
                        >
                            {isRoleAuthorized({ role: user!.role, minimumAllowedRole: Role.licenseAdmin }) && (
                                <div>{installation.license.licenseKey}</div>
                            )}
                            {!isRoleAuthorized({ role: user!.role, minimumAllowedRole: Role.licenseAdmin }) && (
                                <div>-</div>
                            )}
                        </RoleBasedLink>
                    )
                },
            },
            {
                title: 'Actions',
                isClickable: false,
                valueForColumn: (l) => {
                    return (
                        <InstallationActions
                            installation={l}
                            refreshTableFn={tableRef.current?.refreshData}
                            addFailedOperation={addFailedOperation}
                        />
                    )
                },
            },
        ],
        [location]
    )

    async function fetchInstallations({
        filter,
        order,
        ...query
    }: Query<PaginatedDataFetchingFilter<{ showDeleted: boolean }>, SortOrder<ListInstallationSortableField>>): Promise<
        ListResult<Installation>
    > {
        try {
            const showDeletedInstallations = filter?.boolFilters?.showDeleted ?? false
            return await billingApi.listInstallations({
                ...query,
                filter: {
                    searchName: filter?.searchString,
                    excludeDeleted: !showDeletedInstallations,
                },
                order,
            })
        } catch (error) {
            addFailedOperation({
                id: 'list-installations',
                message: 'Failed fetching installations',
                retryFn: tableRef.current?.refreshData,
            })
            throw error
        }
    }

    return (
        <>
            <ListPageToolbar
                title={'Installations'}
                numberOfSelectedItems={0}
                selectedActions={[]}
                actions={[
                    <RotatingButton
                        key="refresh"
                        tooltip="Refresh"
                        onClick={() => tableRef.current?.refreshData()}
                        Component={<Cached />}
                    />,
                ]}
            />
            <Paper>
                <DataFetchingPaginatedTable
                    myRef={tableRef}
                    api={fetchInstallations}
                    columns={columns}
                    onRowClicked={navigateToInstallation}
                    boolFilters={[
                        { filterName: 'showDeleted', label: 'Show deleted installations', initialValue: false },
                    ]}
                    searchParameters={{
                        searchBarPlaceholder: 'Search by customer, host, release, deployment or license key...',
                    }}
                    emptyRowsMessage={'No installations found'}
                />
            </Paper>

            {errorBanner}
        </>
    )
}

import { useAuth } from './Auth'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import { Path } from '../../routes/path'
import netiLogoWhite from '../../assets/netinsight-logo-color@3x.png'
import { useNavigate } from 'react-router'

export const OauthLoginErrorPage = () => {
    const { logout } = useAuth()
    const navigate = useNavigate()

    return (
        <Container component="main" maxWidth="xs">
            <LoginPageIcon />

            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <Typography>Login failed</Typography>
                <Button
                    style={{ margin: '12px 0px' }}
                    fullWidth
                    variant="contained"
                    onClick={() => {
                        logout().catch()
                        navigate(Path.login, { replace: true })
                    }}
                >
                    OK
                </Button>
            </div>
        </Container>
    )
}

function LoginPageIcon() {
    const theme = useTheme()
    return (
        <div style={{ margin: theme.spacing(6, 0) }}>
            <img
                src={netiLogoWhite}
                style={{
                    height: '44px',
                    margin: '0 auto',
                    display: 'block',
                }}
                alt="Net Insight logo"
            />
        </div>
    )
}

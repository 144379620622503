import { forwardRef, useEffect, useImperativeHandle, useState, ReactNode, ChangeEvent, Ref, MouseEvent } from 'react'
import { useSearchParams } from 'react-router'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import Table from '@mui/material/Table'
import TableSortLabel from '@mui/material/TableSortLabel'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import TablePagination from '@mui/material/TablePagination'
import { styled } from '@mui/material/styles'

import { ListResult, SortOrder } from 'common/api/v1/types'
import { Query } from 'common/query'
import { PaginatedDataFetchingFilter, usePaginatedDataFetching } from './hooks/hook-paginated-data-fetching'
import SearchInput from './SearchInput'
import { useDebounce } from './hooks/hook-debounce'
import { LoadingIndicator } from './LoadingIndicator'

const OuterContainer = styled('div')({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
})

const TableHeaderAndTableContainer = styled('div')({
    width: '100%',
})

const TableHeaderContainer = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
})

export interface Row {
    id: string
}

export interface Column<T extends Row, SortableField> {
    sortField?: SortableField
    title: string
    valueForColumn: (element: T) => string | ReactNode
    // isClickable defaults to true
    isClickable?: boolean
}

export interface TableProps<T extends Row, SortableField> {
    isLoading: boolean
    columns: Column<T, SortableField>[]
    rows: T[]
    onRowClicked?: (element: T, index: number) => void
    onRowsSelected?: (elements: T[]) => void
    onSortRequested?: (sortOrder: { sortField: SortableField; direction: 'asc' | 'desc' }) => void
    // A message to display when there are zero rows
    emptyRowsMessage: string
}

export function DataTable<T extends Row, SortableField>(props: TableProps<T, SortableField>) {
    const { columns, rows, onRowClicked, onRowsSelected, onSortRequested, isLoading, emptyRowsMessage } = props
    const areRowsSelectable = !!onRowsSelected
    const isTableSortable = !!onSortRequested

    const maxColumns = 12
    const hasExtraCheckboxColumn = areRowsSelectable
    const columnWidth = maxColumns / (columns.length + (hasExtraCheckboxColumn ? 1 : 0))

    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc')
    const [sortBy, setSortBy] = useState<SortableField | undefined>(undefined)
    const onSortClicked = (sortField: SortableField) => {
        if (!isTableSortable) return
        const isAsc = sortBy === sortField && sortDirection === 'asc'
        const newDirection = isAsc ? 'desc' : 'asc'
        setSortDirection(newDirection)
        setSortBy(sortField)
        onSortRequested({ sortField, direction: newDirection })
    }

    const [selectedRows, setSelectedRows] = useState<string[]>([])

    useEffect(() => {
        // Rows has changed - remove selected rows that no longer exist
        // E.g. user may have selected a row and then filtered it away
        const existingSelectedRows = selectedRows.filter((selected) => rows.find((row) => row.id === selected))
        updateSelectedRows(existingSelectedRows)
    }, [rows])

    const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
        const selectAll = event.target.checked
        const newSelectedRows = selectAll ? rows.map((row) => row.id) : []
        updateSelectedRows(newSelectedRows)
    }
    const handleSelectRowClick = (rowId: string) => {
        const isSelected = selectedRows.includes(rowId)
        const newSelectedRows = isSelected ? selectedRows.filter((id) => id !== rowId) : [...selectedRows, rowId]
        updateSelectedRows(newSelectedRows)
    }

    const updateSelectedRows = (newSelectedRows: string[]) => {
        setSelectedRows(newSelectedRows)
        onRowsSelected?.(rows.filter((row) => newSelectedRows.includes(row.id)))
    }

    return (
        <Table stickyHeader>
            <TableHead>
                <TableRow>
                    {!isLoading && hasExtraCheckboxColumn && (
                        <TableCell colSpan={1} padding="checkbox">
                            <Checkbox
                                onChange={handleSelectAllClick}
                                style={{ cursor: 'default' }}
                                indeterminate={selectedRows.length > 0 && selectedRows.length < rows.length}
                                checked={rows.length > 0 && selectedRows.length === rows.length}
                            />
                        </TableCell>
                    )}
                    {!isLoading &&
                        columns.map((column) => {
                            const isColumnSortable = !!column.sortField
                            return (
                                <TableCell
                                    key={column.title}
                                    colSpan={columnWidth}
                                    sortDirection={
                                        isTableSortable && isColumnSortable && sortBy === column.sortField
                                            ? sortDirection
                                            : false
                                    }
                                >
                                    {isTableSortable && isColumnSortable && (
                                        <TableSortLabel
                                            style={{ textDecoration: 'underline' }}
                                            active={sortBy === column.sortField}
                                            direction={sortBy === column.sortField ? sortDirection : 'asc'}
                                            onClick={() => onSortClicked(column.sortField!)}
                                        >
                                            {column.title}
                                        </TableSortLabel>
                                    )}
                                    {(!isTableSortable || !isColumnSortable) && column.title}
                                </TableCell>
                            )
                        })}
                </TableRow>
            </TableHead>

            <TableBody>
                {isLoading && (
                    <TableRow>
                        <TableCell colSpan={maxColumns}>
                            <LoadingIndicator />
                        </TableCell>
                    </TableRow>
                )}
                {!isLoading && rows.length === 0 && (
                    <TableRow>
                        <TableCell colSpan={maxColumns}>{emptyRowsMessage}</TableCell>
                    </TableRow>
                )}
                {!isLoading &&
                    rows.map((row, index) => {
                        const isRowSelected = selectedRows.includes(row.id)
                        return (
                            <TableRow
                                key={row.id}
                                data-test-id="table-row"
                                hover
                                selected={isRowSelected}
                                tabIndex={-1}
                            >
                                {hasExtraCheckboxColumn && (
                                    <TableCell colSpan={1} padding="checkbox">
                                        <Checkbox
                                            onChange={() => handleSelectRowClick(row.id)}
                                            checked={isRowSelected}
                                            style={{ cursor: 'default' }}
                                        />
                                    </TableCell>
                                )}
                                {columns.map((column) => {
                                    const isClickable = (column.isClickable ?? true) && !!onRowClicked
                                    return (
                                        <TableCell
                                            key={column.title}
                                            colSpan={columnWidth}
                                            onClick={isClickable ? () => onRowClicked?.(row, index) : undefined}
                                            style={{
                                                cursor: isClickable ? 'pointer' : 'auto',
                                            }}
                                        >
                                            {column.valueForColumn(row)}
                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                        )
                    })}
            </TableBody>
        </Table>
    )
}

export enum SearchParamKey {
    orderBy = 'orderBy',
    direction = 'direction',
    searchString = 'searchString',
    page = 'page',
    rowsPerPage = 'rowsPerPage',
}

interface DataFetchingPaginatedTableProps<
    Item extends Row,
    SortBy extends string,
    BoolFilter extends { [filterName: string]: boolean }
> {
    api: (query: Query<PaginatedDataFetchingFilter<BoolFilter>, SortOrder<SortBy>>) => Promise<ListResult<Item>>
    columns: Column<Item, SortBy>[]
    onRowClicked?: (item: Item, index: number) => void
    onRowsSelected?: (items: Item[]) => void
    searchParameters?: {
        searchBarPlaceholder: string
    }
    // A message to display when there are zero rows
    emptyRowsMessage: string

    // A list of query filters with boolean values. Will be presented as checkboxes and provided in the api-call.
    // e.g. { label: 'Show deleted installations', filterName: 'excludeDeleted' }
    // e.g. { label: 'Show revoked licenses', filterName: 'excludeRevoked' }
    boolFilters?: Array<{ filterName: keyof BoolFilter; label: string; initialValue: boolean }>
}

type SearchParamUpdate = Partial<Record<SearchParamKey, string>>
function DataFetchingPaginatedTableComponent<
    Item extends Row,
    SortBy extends string,
    BoolFilter extends { [filterName: string]: boolean }
>(
    {
        api,
        columns,
        onRowClicked,
        onRowsSelected,
        boolFilters,
        searchParameters,
        emptyRowsMessage,
    }: DataFetchingPaginatedTableProps<Item, SortBy, BoolFilter>,
    ref: Ref<RefreshableDataFetchingPaginatedTable>
) {
    const [searchParams, setSearchParams] = useSearchParams()
    const updateSearchParams = (entry: SearchParamUpdate) => {
        for (const [key, value] of Object.entries(entry)) {
            searchParams.set(key, value)
        }
        setSearchParams(searchParams, { replace: true })
    }

    const rowsPerPageOptions = [25, 50, 100]
    const currentPage = parseInt(searchParams.get(SearchParamKey.page) || '') || 0
    const rowsPerPage = parseInt(searchParams.get(SearchParamKey.rowsPerPage) || '') || rowsPerPageOptions[0]
    const orderByProperty = searchParams.get(SearchParamKey.orderBy) || undefined
    const sortDirection = (searchParams.get(SearchParamKey.direction) || undefined) as 'asc' | 'desc' | undefined

    const isSearchEnabled = !!searchParameters
    const searchString = searchParams.get(SearchParamKey.searchString) || ''
    const searchFilter = useDebounce(isSearchEnabled ? searchString : '')

    const boolFiltersFromSearchParams = (boolFilters ?? []).reduce((acc, { filterName, initialValue }) => {
        const strValue = searchParams.get(filterName.toString()) ?? initialValue.toString()
        acc[filterName.toString()] = strValue === 'true'
        return acc
    }, {} as { [key: string]: boolean })

    useEffect(() => {
        function populateSearchParamsWithInitialBoolFilters() {
            if (!boolFilters || boolFilters.length === 0) {
                return
            }
            const entries = boolFilters.reduce((acc, { filterName, initialValue }) => {
                acc[filterName.toString()] = boolFiltersFromSearchParams[filterName] ?? initialValue
                return acc
            }, {} as { [key: string]: boolean })
            updateSearchParams(entries)
        }
        populateSearchParamsWithInitialBoolFilters()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const { items, total, isFetching, refreshData } = usePaginatedDataFetching({
        api,
        currentPage,
        rowsPerPage,
        sortOrder: orderByProperty
            ? { field: orderByProperty as SortBy, descending: sortDirection === 'desc' }
            : undefined,
        searchString: searchFilter,
        boolFilters: boolFiltersFromSearchParams as BoolFilter,
    })

    useImperativeHandle(ref, () => ({ refreshData }))

    return (
        <OuterContainer>
            <TableHeaderAndTableContainer>
                <TableHeaderContainer>
                    {isSearchEnabled && (
                        <SearchInput
                            initialValue={searchString}
                            placeholder={searchParameters.searchBarPlaceholder}
                            onChange={(searchString) =>
                                updateSearchParams({
                                    [SearchParamKey.searchString]: searchString,
                                    [SearchParamKey.page]: '0',
                                })
                            }
                        />
                    )}
                    {!isSearchEnabled && <div />}
                    {boolFilters &&
                        boolFilters.map(({ label, filterName }) => (
                            <FormGroup key={filterName.toString()}>
                                <FormControlLabel
                                    label={label}
                                    labelPlacement="start"
                                    control={
                                        <Checkbox
                                            style={{ cursor: 'default' }}
                                            checked={boolFiltersFromSearchParams[filterName]}
                                            onChange={(changeEvent) =>
                                                updateSearchParams({
                                                    [filterName]: changeEvent.target.checked,
                                                    [SearchParamKey.page]: '0',
                                                })
                                            }
                                        />
                                    }
                                    slotProps={{ typography: { variant: 'body2' } }}
                                />
                            </FormGroup>
                        ))}
                    <TablePagination
                        component="div"
                        count={total}
                        page={currentPage}
                        rowsPerPageOptions={rowsPerPageOptions}
                        rowsPerPage={rowsPerPage}
                        onPageChange={(event: MouseEvent<HTMLButtonElement> | null, page: number) =>
                            updateSearchParams({ [SearchParamKey.page]: page.toString() })
                        }
                        onRowsPerPageChange={(event: ChangeEvent<HTMLInputElement>) => {
                            updateSearchParams({
                                [SearchParamKey.page]: '0',
                                [SearchParamKey.rowsPerPage]: event.target.value,
                            })
                        }}
                    />
                </TableHeaderContainer>

                <DataTable
                    isLoading={isFetching}
                    columns={columns}
                    rows={items}
                    emptyRowsMessage={emptyRowsMessage}
                    onRowClicked={onRowClicked}
                    onRowsSelected={onRowsSelected}
                    onSortRequested={({ sortField, direction }) =>
                        updateSearchParams({
                            [SearchParamKey.orderBy]: sortField.toString(),
                            [SearchParamKey.direction]: direction,
                        })
                    }
                />
            </TableHeaderAndTableContainer>
        </OuterContainer>
    )
}

export type RefreshableDataFetchingPaginatedTable = { refreshData: () => void }
const ReferencedComponent = forwardRef(DataFetchingPaginatedTableComponent)
export const DataFetchingPaginatedTable = <
    Item extends Row,
    SortBy extends string,
    BoolFilter extends { [filterName: string]: boolean }
>({
    myRef,
    ...rest
}: DataFetchingPaginatedTableProps<Item, SortBy, BoolFilter> & {
    myRef: Ref<RefreshableDataFetchingPaginatedTable>
}) => <ReferencedComponent {...(rest as any)} ref={myRef} />
